import { useContext } from 'react'
import API from '../../services'
import { Cycle } from '../../types/cycles'
import { AppContext } from '../../context/AppContext'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useCycles from './useCycles'

const useCyclePublisher = () => {
  const { currentSpace } = useContext(AppContext)
  const publisher = usePostCycle()

  const publishCycle = (cycle: Cycle) => {
    if (!currentSpace) return
    publisher.mutate({ cycleData: cycle, gymID: currentSpace.id })
  }

  const deleteCycle = (cycle: Cycle) => {
    if (!currentSpace) return

    publisher.mutate({
      cycleData: { ...cycle, isDeleted: true },
      gymID: currentSpace.id,
    })
  }

  return { deleteCycle, publishCycle }
}

export default useCyclePublisher

const usePostCycle = () => {
  const queryClient = useQueryClient()
  const { cyclesQueryKey } = useCycles()

  return useMutation({
    mutationFn: (data: { cycleData: Cycle; gymID: string }) =>
      API.postCycle(data.cycleData, data.gymID),

    // When mutate is called:
    onMutate: async (newCycleData) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: cyclesQueryKey })

      // Snapshot the previous value
      const previousCycles = queryClient.getQueryData(cyclesQueryKey)

      // Optimistically update to the new value
      queryClient.setQueryData<Cycle[]>(
        cyclesQueryKey,
        (old: Cycle[] | undefined) => {
          const cycleData = newCycleData.cycleData
          const optimisticlyUpdatedData = old || []

          const index = optimisticlyUpdatedData.findIndex(
            (obj) => obj.id === cycleData.id
          )

          if (cycleData.isDeleted && index !== -1) {
            // If the object is found in the array and is marked as deleted, remove it
            optimisticlyUpdatedData.splice(index, 1)
          } else if (index !== -1) {
            // If the object is found in the array, replace it
            optimisticlyUpdatedData[index] = cycleData
          } else {
            // If the object is not found in the array, append it
            optimisticlyUpdatedData.push(cycleData)
          }

          return optimisticlyUpdatedData
        }
      )

      // Return a context object with the snapshotted value
      return { previousCycles }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newCycleData, context) => {
      if (context)
        queryClient.setQueryData(cyclesQueryKey, context.previousCycles)
      console.error('err', err)
      queryClient.invalidateQueries({ queryKey: cyclesQueryKey })
    },
    // Always refetch after error or success:
    // onSettled: () => {
    //   queryClient.invalidateQueries({ queryKey: cyclesQueryKey })
    // },
  })
}
