import React from 'react'

type Props = {
  size?: number
}

const IconRoundsReps = ({ size = 32 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5V23.5C3 25.433 4.567 27 6.5 27V27C8.433 27 10 25.433 10 23.5V5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 5V23.5C13 25.433 14.567 27 16.5 27V27C18.433 27 20 25.433 20 23.5V5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 5V23.5C23 25.433 24.567 27 26.5 27V27C28.433 27 30 25.433 30 23.5V5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 23.5C5 22.6716 5.67157 22 6.5 22V22C7.32843 22 8 22.6716 8 23.5V23.5C8 24.3284 7.32843 25 6.5 25V25C5.67157 25 5 24.3284 5 23.5V23.5Z"
        fill="#16DB70"
      />
      <path
        d="M15 23.5C15 22.6716 15.6716 22 16.5 22V22C17.3284 22 18 22.6716 18 23.5V23.5C18 24.3284 17.3284 25 16.5 25V25C15.6716 25 15 24.3284 15 23.5V23.5Z"
        fill="#16DB70"
      />
      <path
        d="M25 23.5C25 22.6716 25.6716 22 26.5 22V22C27.3284 22 28 22.6716 28 23.5V23.5C28 24.3284 27.3284 25 26.5 25V25C25.6716 25 25 24.3284 25 23.5V23.5Z"
        fill="#16DB70"
      />
      <path
        d="M5 19.5C5 18.6716 5.67157 18 6.5 18V18C7.32843 18 8 18.6716 8 19.5V19.5C8 20.3284 7.32843 21 6.5 21V21C5.67157 21 5 20.3284 5 19.5V19.5Z"
        fill="#16DB70"
      />
      <path
        d="M15 19.5C15 18.6716 15.6716 18 16.5 18V18C17.3284 18 18 18.6716 18 19.5V19.5C18 20.3284 17.3284 21 16.5 21V21C15.6716 21 15 20.3284 15 19.5V19.5Z"
        fill="#16DB70"
      />
      <path
        d="M25 19.5C25 18.6716 25.6716 18 26.5 18V18C27.3284 18 28 18.6716 28 19.5V19.5C28 20.3284 27.3284 21 26.5 21V21C25.6716 21 25 20.3284 25 19.5V19.5Z"
        fill="#16DB70"
      />
      <path
        d="M5 15.5C5 14.6716 5.67157 14 6.5 14V14C7.32843 14 8 14.6716 8 15.5V15.5C8 16.3284 7.32843 17 6.5 17V17C5.67157 17 5 16.3284 5 15.5V15.5Z"
        fill="#16DB70"
      />
      <path
        d="M15 15.5C15 14.6716 15.6716 14 16.5 14V14C17.3284 14 18 14.6716 18 15.5V15.5C18 16.3284 17.3284 17 16.5 17V17C15.6716 17 15 16.3284 15 15.5V15.5Z"
        fill="#16DB70"
      />
      <path
        d="M5 11.5C5 10.6716 5.67157 10 6.5 10V10C7.32843 10 8 10.6716 8 11.5V11.5C8 12.3284 7.32843 13 6.5 13V13C5.67157 13 5 12.3284 5 11.5V11.5Z"
        fill="#16DB70"
      />
      <path
        d="M15 11.5C15 10.6716 15.6716 10 16.5 10V10C17.3284 10 18 10.6716 18 11.5V11.5C18 12.3284 17.3284 13 16.5 13V13C15.6716 13 15 12.3284 15 11.5V11.5Z"
        fill="#16DB70"
      />
      <path
        d="M5 7.5C5 6.67157 5.67157 6 6.5 6V6C7.32843 6 8 6.67157 8 7.5V7.5C8 8.32843 7.32843 9 6.5 9V9C5.67157 9 5 8.32843 5 7.5V7.5Z"
        fill="#16DB70"
      />
      <path
        d="M15 7.5C15 6.67157 15.6716 6 16.5 6V6C17.3284 6 18 6.67157 18 7.5V7.5C18 8.32843 17.3284 9 16.5 9V9C15.6716 9 15 8.32843 15 7.5V7.5Z"
        fill="#16DB70"
      />
    </svg>
  )
}

export default IconRoundsReps
