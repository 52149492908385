import React from 'react'

type Props = {}

const IconStaffNote = (props: Props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 28H8C6.34315 28 5 26.6569 5 25L5 9C5 7.34315 6.34315 6 8 6L24 6C25.6569 6 27 7.34315 27 9V15"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M10 11H22"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 15H19"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 19H17"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M24 17H25C26.6569 17 28 18.3431 28 20V22H21V20C21 18.3431 22.3431 17 24 17Z"
        fill="white"
        stroke="black"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 21C19.8954 21 19 21.8954 19 23V28C19 29.1046 19.8954 30 21 30H28C29.1046 30 30 29.1046 30 28V23C30 21.8954 29.1046 21 28 21H21ZM24.5 27C25.3284 27 26 26.3284 26 25.5C26 24.6716 25.3284 24 24.5 24C23.6716 24 23 24.6716 23 25.5C23 26.3284 23.6716 27 24.5 27Z"
        fill="black"
      />
    </svg>
  )
}

export default IconStaffNote
