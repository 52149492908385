import React, { useState, useEffect, useContext } from 'react'
import css from './Sidebar.module.css'
import Button from '../../buttons/Button/Button'
import ButtonArea from '../../buttons/ButtonArea/ButtonArea'
import WorkoutBuilder from '../../forms/WorkoutBuilder/WorkoutBuilder'
import WorkoutCard from '../../cards/WorkoutCard/WorkoutCard'
import { X as IconClose, PlusCircle } from 'react-feather'
import { DateTime } from 'luxon'

import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'

import {
  DndContext,
  DragOverlay,
  closestCenter,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'

import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import ButtonIcon from '../../buttons/ButtonIcon/ButtonIcon'
import useWorkoutBuilder from '../../../hooks/useWorkoutBuilder'
import CalendarContext from '../../../context/CalendarContext'
import { Tooltip } from 'react-tooltip'
import { AppContext } from '../../../context/AppContext'
import { UserContext } from '../../../context/UserContext'
import { DateSubheader } from '../../atoms/DateSubheader/DateSubheader'
import Sidebar from './Sidebar'
import WorkoutAddInput from '../../inputs/WorkoutAddInput/WorkoutAddInput'
import { Workout, WorkoutShortcut } from '../../../types/workouts'
import Confirm from '../../modals/Confirm'

import { v4 as uuid } from 'uuid'
import { useWorkoutPublisher } from '../../../hooks/useWorkoutPublisher'
import useMovements from '../../../hooks/useMovements'
import { Analytics } from '../../../services/analytics'
import { CalendarEvent } from '../../../types/calendarEvents'
import { IconNewWorkout } from '../../atoms/Icons/Icons'
import useCalendarEventSorter from '../../../hooks/calendar/useCalendarEventSorter'
import makeVariationOfPredefinedWorkoutEvent from '../../../utilities/workout builder/makeVariationOfWorkoutEvent'
import getNewWorkoutEventWithMovement from '../../../utilities/workout builder/getNewWorkoutEventWithMovement'
import useCalendarEvents from '../../../hooks/calendar/useCalendarEvents'
import NoteBuilder from '../../forms/NoteBuilder/NoteBuilder'
import NoteCard from '../../cards/NoteCard/NoteCard'
import EventCards from '../EventCards/EventCards'
import classnames from 'classnames/bind'
import WorkoutBuilderButtons from '../WorkoutBuilderButtons/WorkoutBuilderButtons'

const cx = classnames.bind(css)

type Props = {
  isActive: boolean
  onDismiss: () => void
  builderMode?: boolean
}

interface EditWorkoutSidebarMode {
  data?: CalendarEvent.Workout
  type: 'view' | 'edit-workout'
  index?: number
}

interface NewWorkoutSidebarMode {
  type: 'add-workout'
  tracking: Workout.Item['tracking']
  index?: number
}

interface NewNoteSidebarMode {
  type: 'add-note'
  noteType?: 'note' | 'staff-note' | 'pinned-note'
}

interface NoteSidebarMode {
  data?: CalendarEvent.Note
  type: 'view' | 'edit-note'
  index?: number
}

type SidebarMode =
  | EditWorkoutSidebarMode
  | NoteSidebarMode
  | NewWorkoutSidebarMode
  | NewNoteSidebarMode

const CalendarSidebar = ({ isActive, onDismiss, builderMode }: Props) => {
  const { activeDate, setPreventActiveDateChange } = useContext(CalendarContext)
  const { pinnedEvents, pinnedForeverEvents, unpinnedEvents } =
    useCalendarEvents({
      dateISO: activeDate,
      enabled: true,
    })
  //const currentDayEvents = (activeDate && getEventsForDate(activeDate)) || null

  const currentDayEvents = [
    ...pinnedEvents,
    ...unpinnedEvents,
    ...pinnedForeverEvents,
  ]

  const sidebarRef = React.useRef<HTMLDivElement>(null)

  const dateObj = activeDate ? DateTime.fromISO(activeDate) : null
  const dayOfWeek = activeDate ? DateTime.fromISO(activeDate).weekdayLong : null
  const fullDate = dateObj
    ? dateObj.toLocaleString(DateTime.DATE_MED)
    : 'No date'

  const [mode, setMode] = useState<SidebarMode>(
    builderMode
      ? {
          type: 'add-workout',
          tracking: 'reps-weight',
        }
      : { type: 'view' }
  )

  const [editExercise] = useState<{
    data: Workout.Item
    index: number
  } | null>(null)

  const { currentSpace, currentGymTrack } = useContext(AppContext)
  const { userID } = useContext(UserContext)
  const canEdit =
    currentSpace?.permissions.canEditWorkouts ||
    currentSpace?.ownerID === userID

  const getRevelationMessage = () => {
    if (!activeDate) return null

    const workoutVisibility = currentSpace?.workoutVisibility
    const currentDate = DateTime.now().toMillis()
    const selectedDate = DateTime.fromFormat(activeDate, 'yyyy-LL-dd')

    switch (workoutVisibility) {
      case 'evening-before': {
        const revelationDate = selectedDate.minus({ days: 1 }).set({ hour: 18 })
        return currentDate < revelationDate.toMillis() ? (
          <>
            {getHumanReadableDate(revelationDate)} <> at&nbsp;6PM</>
          </>
        ) : null
      }
      case 'day-ahead': {
        const revelationDate = selectedDate.minus({ days: 1 }).set({ hour: 0 })
        return currentDate < revelationDate.toMillis() ? (
          <>{getHumanReadableDate(revelationDate)}</>
        ) : null
      }
      case 'week-ahead': {
        const revelationDate = selectedDate.minus({ days: 7 }).set({ hour: 0 })
        return currentDate < revelationDate.toMillis() ? (
          <>{getHumanReadableDate(revelationDate)}</>
        ) : null
      }
      default:
        return null
    }
  }

  const message = getRevelationMessage()

  const { updateWorkoutWithData } = useWorkoutBuilder({
    index: currentDayEvents?.length || 0,
    workoutEventToEdit: null,
    activeDate,
    onDismiss,
  })

  const { deleteWorkoutEvent } = useWorkoutPublisher()

  const { getMovementByID } = useMovements()
  const { addPredefinedWorkout } = useWorkoutPublisher()

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } })
  )

  const handleEditWorkout = (eventToEdit: CalendarEvent.Workout) => {
    // When workout is "predefined", we actually make a variation of it
    // Workouts with scaling will only have default scaling

    const isPredefined = eventToEdit.details.isPredefined

    const eventData = isPredefined
      ? makeVariationOfPredefinedWorkoutEvent(eventToEdit)
      : eventToEdit

    setMode({ type: 'edit-workout', data: eventData, index: eventData.index })
  }

  const addWorkoutByMovementID = (movementID: string) => {
    const index = currentDayEvents?.length || 0
    const movement = getMovementByID(movementID)
    const workoutEvent = getNewWorkoutEventWithMovement({
      gymID: currentSpace?.id || 'no-id',
      trackID: currentGymTrack || 'default',
      movement,
      userID,
      eventDateISO: activeDate || '',
      index,
    })
    handleEditWorkout(workoutEvent)
  }

  const handlePublishWorkout = (data: CalendarEvent.Workout) =>
    updateWorkoutWithData({
      newEventData: { ...data },
      newWorkoutData: data.details,
      preventDismiss: true,
      publishStatus: true,
    })

  const dismissWorkoutBuilder = () => {
    if (builderMode) onDismiss()
    setMode({ type: 'view' })
    setPreventActiveDateChange(false)
  }

  useEffect(() => {
    if (builderMode) setMode({ type: 'add-workout', tracking: 'reps-weight' })
  }, [isActive, builderMode])

  useEffect(() => {
    if (!builderMode) setMode({ type: 'view' })
    // scroll to top
    if (sidebarRef.current) sidebarRef.current.scrollTop = 0

    setScrollShadowIsVisible(false)
  }, [activeDate, builderMode])

  const [scrollShadowIsVisible, setScrollShadowIsVisible] = useState(false)

  const handleBuilderScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = (e.target as HTMLElement).scrollTop
    if (!scrollShadowIsVisible && scrollTop > 10) setScrollShadowIsVisible(true)
    else if (scrollShadowIsVisible && scrollTop <= 10)
      setScrollShadowIsVisible(false)
  }

  const [confirmation, setConfirmation] = useState<{
    title: string
    text: string
    what: string
    id: string
  } | null>(null)

  const handleDeleteConfirmation = (workoutEventID: string) => {
    const workoutEvent = currentDayEvents?.find(
      (event) => event.id === workoutEventID
    )
    if (workoutEvent) deleteWorkoutEvent(workoutEvent)
    setConfirmation(null)
  }

  const showDeleteConfirmation = (data: CalendarEvent.Workout) => {
    setConfirmation({
      title: 'Delete Workout?',
      text: 'This cannot be undone.',
      what: 'delete',
      id: data.id,
    })
    Analytics._logEvent({
      name: 'workout_deleted_via_quick_button',
      params: {
        workoutID: data.id,
        workoutTitle: data.details.title,
        workoutType: data.details.workoutType,
        workoutDate: activeDate,
      },
    })
  }

  const handleWorkoutAddViaInput = (shortcut: WorkoutShortcut) => {
    if (shortcut.type === 'strength') {
      Analytics._logEvent({
        name: 'workout_create_via_quick_button',
        params: {
          movementID: shortcut.movementIDs[0],
        },
      })
      addWorkoutByMovementID(shortcut.movementIDs[0])
    } else {
      Analytics._logEvent({
        name: 'add_benchmark_workout',
        params: {
          via: 'workout_add_input',
        },
      })
      if (!activeDate) console.error('No active date')
      else
        addPredefinedWorkout({
          workoutShortcut: shortcut,
          eventDateISO: activeDate,
          index: currentDayEvents?.length || 0,
        })
    }
  }

  const handleCardActon = (
    action: string,
    workoutEvent: CalendarEvent.Item
  ) => {
    switch (workoutEvent.type) {
      case 'workout': {
        switch (action) {
          case 'edit':
            handleEditWorkout(workoutEvent)
            break
          case 'publish':
            handlePublishWorkout(workoutEvent)
            break
          case 'delete':
            showDeleteConfirmation(workoutEvent)
            break
        }
        break
      }
      case 'note': {
        switch (action) {
          case 'edit':
            handleEditNote(workoutEvent)
            break
          case 'delete':
            setConfirmation({
              title: 'Delete Note?',
              text: 'This cannot be undone.',
              what: 'delete',
              id: workoutEvent.id,
            })
            break
        }
        break
      }
    }
  }

  const handleEditNote = (note: CalendarEvent.Note) => {
    setMode({ type: 'edit-note', data: note })
  }

  const handleNewWorkoutButton = (
    action: Workout.Item['tracking'] | 'note' | 'staff-note' | 'pinned-note'
  ) => {
    switch (action) {
      case 'note':
      case 'staff-note':
      case 'pinned-note':
        return setMode({ type: 'add-note', noteType: action })
      default:
        return setMode({ type: 'add-workout', tracking: action })
    }
  }

  const getSidebarContent = () => {
    if (!activeDate && !builderMode) return <></>
    switch (mode.type) {
      case 'view': {
        if (currentDayEvents?.length)
          return (
            <div
              id={css['content']}
              onScroll={handleBuilderScroll}
              ref={sidebarRef}
            >
              {!!pinnedForeverEvents.length && (
                <EventCards
                  events={pinnedForeverEvents || []}
                  handleCardAction={handleCardActon}
                />
              )}
              {pinnedForeverEvents.length &&
              (pinnedEvents.length || unpinnedEvents.length) ? (
                <div className={cx('divider')} />
              ) : (
                ''
              )}
              {!!pinnedEvents.length && (
                <EventCards
                  events={pinnedEvents || []}
                  handleCardAction={handleCardActon}
                />
              )}
              {pinnedEvents.length && unpinnedEvents.length ? (
                <div className={cx('divider')} />
              ) : (
                ''
              )}
              <EventCards
                events={unpinnedEvents || []}
                handleCardAction={handleCardActon}
              />
              {canEdit && (
                <>
                  <div
                    className={`${css['only-buttons']}`}
                    style={{
                      marginTop: '24px',
                      borderTop: '1px solid var(--divider)',
                      paddingTop: '24px',
                    }}
                  >
                    <WorkoutAddInput onWorkoutAdd={handleWorkoutAddViaInput} />
                    <WorkoutBuilderButtons onAction={handleNewWorkoutButton} />
                  </div>
                </>
              )}
            </div>
          )
        else
          return canEdit ? (
            <div id={`${css['content']}`} className={`${css['only-buttons']}`}>
              <WorkoutAddInput onWorkoutAdd={handleWorkoutAddViaInput} />
              <WorkoutBuilderButtons onAction={handleNewWorkoutButton} />
            </div>
          ) : (
            <div className={css['placeholder']}>No workouts posted</div>
          )
      }

      case 'add-workout': {
        return (
          <WorkoutBuilder
            newWorkoutTracking={mode.tracking}
            index={
              editExercise ? editExercise.index : currentDayEvents?.length || 0
            }
            workoutEventToEdit={null}
            dateSubheader={
              <DateSubheader
                fullDate={fullDate}
                activeDate={activeDate || ''}
                hideMessage={message}
              />
            }
            onDismiss={dismissWorkoutBuilder}
            activeDate={activeDate}
            isPresetMode={builderMode}
          />
        )
      }

      case 'edit-workout': {
        return (
          <WorkoutBuilder
            workoutToEdit={mode.data?.details}
            index={
              mode.data
                ? mode.data.index
                : currentDayEvents
                ? currentDayEvents.length
                : 0
            }
            workoutEventToEdit={mode.data || null}
            dateSubheader={
              <DateSubheader
                fullDate={fullDate}
                activeDate={activeDate || ''}
                hideMessage={message}
              />
            }
            onDismiss={dismissWorkoutBuilder}
            activeDate={activeDate}
          />
        )
      }

      case 'add-note': {
        return (
          <NoteBuilder
            noteToEdit={null}
            onDismiss={dismissWorkoutBuilder}
            activeDate={activeDate || ''}
            newNoteType={mode.noteType}
          />
        )
      }
      case 'edit-note': {
        return (
          <NoteBuilder
            noteToEdit={mode.data || null}
            onDismiss={dismissWorkoutBuilder}
            activeDate={activeDate || ''}
          />
        )
      }
    }
  }

  return (
    <Sidebar isActive={isActive} onDismiss={onDismiss} blankTemplate={true}>
      <Tooltip
        id="t-drag-hint-card"
        place="left-start"
        delayShow={400}
        className="tooltip"
        noArrow={true}
        offset={4}
        style={{ margin: '-4px 0 0 0' }}
      />

      <Confirm
        isVisible={!!confirmation}
        title={confirmation ? confirmation.title : ''}
        text={confirmation ? confirmation.text : ''}
        buttonLabels={
          confirmation && confirmation.what === 'delete'
            ? { primary: 'Delete', secondary: 'Go Back' }
            : { primary: 'Proceed', secondary: 'Go Back' }
        }
        onDismiss={() => setConfirmation(null)}
        onConfirm={() => {
          if (confirmation?.what === 'delete')
            return handleDeleteConfirmation(confirmation.id)
        }}
        isDestructive={confirmation?.what === 'delete'}
      />

      {mode.type === 'view' ? (
        <header>
          <div className={`${css['subheader']} ${'light'}`}>
            <DateSubheader
              fullDate={fullDate}
              activeDate={activeDate || ''}
              hideMessage={message}
            />
          </div>

          <h2 style={{ textTransform: 'capitalize' }}>{dayOfWeek}</h2>

          <ButtonIcon
            icon={<IconClose />}
            className={css['close-icon']}
            onClick={onDismiss}
            type={'light'}
            style={{ margin: '-4px 0 0 0' }}
          />
          <div
            className={`${css['scroll-shadow']} ${
              scrollShadowIsVisible && css['visible']
            }`}
          />
        </header>
      ) : (
        ''
      )}

      {getSidebarContent()}
      {/* </div> */}
    </Sidebar>
  )
}

export default CalendarSidebar

const getHumanReadableDate = (date: DateTime) => {
  // if date is today, return 'today'
  // if tomorrow, return 'tomorrow'
  // if the date falls within the current week, return weekday name
  // otherwise return in human-readable format

  const today = DateTime.now().set({ hour: 0, minute: 0, second: 0 })
  const tomorrow = today.plus({ days: 1 })

  if (date.toFormat('yyyy-LL-dd') === today.toFormat('yyyy-LL-dd'))
    return 'today'
  else if (date.toFormat('yyyy-LL-dd') === tomorrow.toFormat('yyyy-LL-dd'))
    return 'tomorrow'
  else if (today.hasSame(date, 'week')) {
    return `this ${date.toFormat('EEEE')}` // Return the weekday name
  } else
    return `on ${date.toLocaleString({
      month: 'short',
      day: 'numeric',
    })}`
}
