import React from 'react'

type Props = {}

const IconPinnedNote = (props: Props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 6H16.5H10C7.79086 6 6 7.79086 6 10L6 24C6 26.2091 7.79086 28 10 28H18.25H23C25.2091 28 27 26.2091 27 24V21.5833V17V10"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M11 15H22"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11 11H16"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11 19H22"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11 23H22"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M23.5 7.5L20 11"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="26" cy="5" r="3" stroke="black" strokeWidth="2" />
    </svg>
  )
}

export default IconPinnedNote
