import React, { Children, useEffect, useMemo } from 'react'
import css from './Day.module.css'
import { IconCycle } from '../../../atoms/Icons/Icons'
import { v4 as uuid } from 'uuid'
import { PlusCircle as IconPlus } from 'react-feather'
import useCycles from '../../../../hooks/cycles/useCycles'

import styles from './Day.module.css'
import classNames from 'classnames/bind'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useDroppable } from '@dnd-kit/core'
import CalendarItem from '../CalendarItem/CalendarItem'
import { DateTime } from 'luxon'
import useCalendarEvents from '../../../../hooks/calendar/useCalendarEvents'

const cx = classNames.bind(styles)

interface Props {
  month: DateTime
  /** Day title to display in the header ("Jan 1" or "2") */
  number: string
  date: string
  isLoading: boolean
  isActive: boolean
  isCurrentMonth: boolean
  isToday: boolean
  sundayIsFirst: boolean
  onClick: (date: string) => void
  isScrolling?: boolean
}

const Day = React.memo(
  ({
    month,
    number,
    date,
    isActive,
    isToday,
    sundayIsFirst,
    isCurrentMonth,
    isScrolling,
    onClick,
  }: Props) => {
    const {
      pinnedEvents,
      pinnedForeverEvents,
      unpinnedEvents,
      isLoading,
      isFetched,
    } = useCalendarEvents({
      dateISO: date,
      enabled: !isScrolling,
    })

    const { checkDateForCycle } = useCycles()

    const cycleData = checkDateForCycle(date)

    const { setNodeRef, over } = useDroppable({
      id: date,
    })

    const isOver =
      over &&
      (over.id === date ||
        unpinnedEvents.map((item) => item.id).includes(over.id as string))

    const eventsArePresent =
      pinnedEvents.length || unpinnedEvents.length || pinnedForeverEvents.length

    return (
      <SortableContext
        items={unpinnedEvents?.map((item) => item.id) ?? []}
        strategy={verticalListSortingStrategy}
      >
        <div
          className={cx(
            'day',
            isActive && 'active',
            sundayIsFirst && 's',
            (isLoading || (isScrolling && !isFetched)) && 'loading',
            cycleData && 'cycle',
            cycleData && cycleData.isStart && 'cycle-start',
            cycleData && cycleData.isEnd && 'cycle-end',
            cycleData && cycleData.color && 'cycle-' + cycleData.color,
            isOver && 'drag-hover'
          )}
          style={{
            overflow: 'hidden',
          }}
          onClick={() => {
            onClick(date)
          }}
        >
          <div className={css['num-container']}>
            {cycleData?.isStart ? (
              <div className={cx('cycle-title-container')}>
                <div className={cx('cycle-title-wrapper')}>
                  <div className={cx('icon-container')}>
                    <IconCycle color="var(--secondary-text)" size={11} />
                  </div>
                  <div className={css['cycle-title']}>{cycleData.title}</div>
                </div>
                <div className={cx('cycle-title-after')}>&nbsp;</div>
              </div>
            ) : (
              <div></div>
            )}
            <div className={cx('daynum-container')}>
              <div
                className={`${css['daynum']} ${isToday && css['today']} ${
                  !isCurrentMonth && css['non-current']
                }`}
              >
                {number}
              </div>
            </div>
          </div>
          <div className={cx('content')}>
            {eventsArePresent ? (
              pinnedForeverEvents.map((event, index) => (
                <div key={event.id}>
                  <CalendarItem item={event} disableDrag={true} />
                </div>
              ))
            ) : (
              <div className={css['icon-add']}>
                <IconPlus size={24} />
              </div>
            )}
          </div>
          <div className={cx('content')}>
            {eventsArePresent ? (
              pinnedEvents.map((event, index) => (
                <div key={event.id}>
                  <CalendarItem item={event} disableDrag={true} />
                </div>
              ))
            ) : (
              <div className={css['icon-add']}>
                <IconPlus size={24} />
              </div>
            )}
          </div>
          <div className={cx('content')} ref={setNodeRef}>
            {eventsArePresent ? (
              unpinnedEvents.map((event, index) => (
                <div key={event.id}>
                  <CalendarItem item={event} />
                </div>
              ))
            ) : (
              <div className={css['icon-add']}>
                <IconPlus size={24} />
              </div>
            )}
          </div>

          <div className={css['wrap-gradient']} />
        </div>
      </SortableContext>
    )
  }
)

export default Day
