import React from 'react'
import { Tracking } from '../../types/types'

type Props = {}

const getTrackingTypeDescription = (trackingType: Tracking) => {
  switch (trackingType) {
    case 'reps-weight':
      return {
        title: 'Single Lift',
        description:
          'Used for weightlifting exercises. Athletes track repetitions and weights for each set. The score is the best weight they used.',
      }

    case 'emom':
    case 'emom-alt':
      return {
        title: 'EMOM & EMOM, Alternating',
        description:
          'Athletes can mark these workouts as "completed." Athletes won\'t have a score but can add notes for each movement in the workout.',
      }

    case 'time':
      return {
        title: 'Total Time',
        description:
          'Used for time-based workouts. Athletes track the minutes and seconds it took them to complete all sets of the workout. The score is the total time.',
      }

    case 'total-reps':
      return {
        title: 'Total Reps',
        description:
          'Used for AMRAP workouts. Athletes track how many repetitions they did in total or per round. The score is the total number of repetitions.',
      }

    case 'rounds-reps':
      return {
        title: 'Total Rounds+Reps',
        description:
          'Used for AMRAP workouts that rely on rounds. Athletes track how many rounds they completed and the reps for non-full rounds. The score is the number of rounds and reps completed.',
      }

    case 'complex':
      return {
        title: 'Complex',
        description:
          'Used for weightlifting complexes. Athletes track the total amount of repetitions and weights used for each set. By default, the mobile app pre-fills the number of repetitions per set using the sum of values in the Reps column. The score is the best weight they used.',
      }

    case 'avg-time':
      return {
        title: 'Average Time',
        description:
          'A variant of time-based workouts. Athletes track the minutes and seconds for each round of the workout. The score is the average time per round.',
      }

    case 'text':
      return {
        title: 'Text Score',
        description:
          'Used for workouts that do not fit the other formats and use a free-form value as a score.',
      }

    case 'no-score':
      return {
        title: 'Simple Workout',
        description:
          'Used for workouts that do not require a score. Athletes can mark these workouts as "completed."',
      }
  }
}

export default getTrackingTypeDescription
