import axios from 'axios'
import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { auth } from '../firebase'
import { GymData, GymSubscription } from '../types/types'
import API from '.'

export const manageSubscription = async (sub: GymSubscription | undefined) => {
  if (!sub) return

  const authToken = await auth.currentUser?.getIdToken()

  const windowReference = window.open('', '_blank')

  return axios
    .post<GymSubscription>(API.url + `create-customer-portal-session`, {
      customer_id: sub.started_by_customer_id,
      authToken,
    })
    .then((res) => {
      const url = res.data
      if (typeof url === 'string') {
        //window.open(url, '_blank')
        if (windowReference) windowReference.location = url
      }
    })
    .catch((err) => {
      console.error(err.response.data)
    })
}
