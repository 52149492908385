import { Edit, Trash2 } from 'react-feather'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import css from './CycleCard.module.css'
import { Cycle } from '../../../types/cycles'
import { Firestore } from '@google-cloud/firestore'
import { firestore } from '@google-cloud/firestore/types/protos/firestore_v1_proto_api'
import { DateTime } from 'luxon'
import { useContext, useState } from 'react'
import { IconSquare } from '../../atoms/Icons/Icons'
import { AppContext } from '../../../context/AppContext'
import { UserContext } from '../../../context/UserContext'
import Text from '../../sections/Text/Text'

type Props = {
  data: Cycle
  onAction: (action: 'edit' | 'delete') => void
}

const CycleCard = ({ data, onAction }: Props) => {
  const [expanded, setExpanded] = useState(false)

  const { currentSpace } = useContext(AppContext)
  const { userID } = useContext(UserContext)
  const canEdit =
    currentSpace?.permissions.canEditWorkouts ||
    currentSpace?.ownerID === userID

  const isActive =
    data.dateRange.startDate.seconds <= DateTime.now().toUnixInteger() &&
    data.dateRange.endDate.seconds >= DateTime.now().toUnixInteger()

  const dateFormat = isActive ? 'MMM d' : 'MMM d, yyyy'

  const startDateText = DateTime.fromSeconds(
    data.dateRange.startDate.seconds
  ).toFormat(dateFormat)
  const endDateText = DateTime.fromSeconds(
    data.dateRange.endDate.seconds
  ).toFormat(dateFormat)

  const durationInWeeksAndDaysText = getDurationText(
    DateTime.fromSeconds(data.dateRange.startDate.seconds),
    DateTime.fromSeconds(data.dateRange.endDate.seconds)
  )

  const getTodayInCycleText = getTodayInCycle(
    data.dateRange.startDate.seconds * 1000
  )

  return (
    <div className={css['cycle-card']}>
      <h3>{data.title}</h3>
      <div
        style={{
          margin: '12px 0 8px 1px',
          backgroundColor: `var(--accent-${data.calendarColor})`,
          width: '40px',
          height: '3px',
        }}
      />
      <div className={css['text']}>
        <div>
          {startDateText} &rarr; {endDateText} ({durationInWeeksAndDaysText})
        </div>
        {isActive && (
          <div>
            <span
              style={{
                color: 'black',
                fontWeight: 500,
              }}
            >
              Active:{' '}
            </span>
            {getTodayInCycleText}
          </div>
        )}
      </div>
      <div
        className={`${css['description']} ${expanded ? css['expanded'] : ''}`}
      >
        <Text>{data.description}</Text>
      </div>
      <div
        className={css['show-more']}
        onClick={() => setExpanded((prev) => !prev)}
      >
        {expanded ? 'Less' : 'More'}
      </div>
      {canEdit && (
        <div className={css['buttons']}>
          <div className={css['section']}>
            <ButtonToolbar
              icon={<Edit size={12} style={{ marginRight: '8px' }} />}
              type="light"
              onClick={() => onAction('edit')}
              style={{
                color: 'var(--accent)',
              }}
            >
              Edit
            </ButtonToolbar>
          </div>
          <div className={css['section']}>
            <ButtonToolbar
              type="light"
              onClick={() => onAction('delete')}
              className={css['da-button']}
              icon={<Trash2 size={16} color="var(--accent)" />}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default CycleCard

const getDurationText = (startDate: DateTime, endDate: DateTime) => {
  // Adjust the start and end of the cycle to align with calendar weeks (Monday as the start)
  const adjustedCycleStart = startDate.startOf('day')
  const adjustedCycleEnd = endDate.startOf('day').plus({ days: 1 })

  // Calculate the total weeks in the cycle, considering the adjusted start
  const weeksInCycle = Math.floor(
    adjustedCycleEnd.diff(adjustedCycleStart, 'days').days / 7
  )

  const daysInCycle = Math.floor(
    adjustedCycleEnd.diff(adjustedCycleStart, 'days').days % 7
  )

  // Format the output
  let result = `${weeksInCycle} week${weeksInCycle !== 1 ? 's' : ''}`
  if (daysInCycle > 0) {
    result += `, ${daysInCycle} day${daysInCycle !== 1 ? 's' : ''}`
  }

  return result
}

const getTodayInCycle = (startDate: number) => {
  const startDateTime = DateTime.fromMillis(startDate)

  // Get today's date in DateTime format
  const today = DateTime.now()

  // Calculate the difference in days
  const diffInDays = today.diff(startDateTime, 'days').days

  // Calculate weeks and the current day in the ongoing week
  const weeks = Math.floor(diffInDays / 7) + 1 // +1 because we start counting from week 1
  const days = Math.floor(diffInDays % 7) + 1 // +1 because we start counting from day 1

  // Format the output
  const result = days ? `Week ${weeks}, Day ${days}` : `Week ${weeks}`

  return result
}
