import {
  collection,
  CollectionReference,
  query,
  where,
} from 'firebase/firestore'
import { Result } from '../../types/results'
import { useContext, useEffect, useMemo } from 'react'
import { firestore } from '../../firebase'
import useCustomFirestoreQueryData from '../query/useCustomFirestoreQueryData'

type Props = {
  eventID: string
}

const useResultsForEvent = ({ eventID }: Props) => {
  const queryKey = useMemo(() => ['results', eventID], [eventID])

  const collectionRef = query(
    collection(firestore, 'results') as CollectionReference<Result.Full>,
    where('eventItemID', '==', eventID),
    where('isDeleted', '==', false)
  )

  const {
    data: results,
    isLoading,
    error,
    refetch,
  } = useCustomFirestoreQueryData<Result.Full>({
    queryKey,
    query: collectionRef,
    queryOptions: {
      enabled: eventID !== 'no-id',
    },
  })

  return {
    results: results || [],
    error,
    retryErrorQuery: refetch,
    isLoading,
  }
}

export default useResultsForEvent
