import { doc } from 'firebase/firestore'
import { firestore } from '../firebase'
import { Workout, WorkoutShortcut } from '../types/workouts'
import useCustomFirestoreDocumentData from './query/useCustomFirestoreDocumentData'

export const useWorkoutShortcuts = () => {
  const shortcutsRef = doc(firestore, 'workout_shortcuts', 'shortcuts')

  const shortcutsRaw = useCustomFirestoreDocumentData({
    queryKey: ['workout_shortcuts'],
    docRef: shortcutsRef,
  })

  const shortcuts: WorkoutShortcut[] = shortcutsRaw.data?.shortcuts || []

  return { shortcuts, shortcutsAreLoading: shortcutsRaw.isLoading }
}
