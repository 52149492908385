import React from 'react'

type Props = {
  size?: number
}

const IconAvgTime = ({ size = 32 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8V16L10.3431 21.6569C11.7909 23.1046 13.7909 24 16 24Z"
        fill="#16DB70"
      />
      <rect x="15" y="8" width="2" height="2" rx="1" fill="black" />
      <rect x="15" y="22" width="2" height="2" rx="1" fill="black" />
      <rect
        x="16"
        y="14.6309"
        width="2.15385"
        height="9"
        rx="1.07692"
        transform="rotate(45 16 14.6309)"
        fill="black"
      />
      <circle
        cx="16"
        cy="16"
        r="2"
        fill="#16DB70"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M23.1716 14.422L27.0261 17.0646L29.6687 13.2101"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.0838 11.4465C8.7231 8.26541 12.0402 6.09003 15.8653 6.09003C21.3178 6.09003 25.738 10.5102 25.738 15.9627H27.738C27.738 9.40561 22.4224 4.09003 15.8653 4.09003C11.1822 4.09003 7.13234 6.80146 5.20001 10.7401L7.0838 11.4465Z"
        fill="black"
      />
      <path
        d="M9.06897 18.0145L5.21449 15.3718L2.57184 19.2263"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1567 20.9899C23.5174 24.1711 20.2004 26.3464 16.3752 26.3464C10.9227 26.3464 6.50254 21.9263 6.50254 16.4737L4.50254 16.4737C4.50254 23.0308 9.81813 28.3464 16.3752 28.3464C21.0584 28.3464 25.1082 25.635 27.0405 21.6964L25.1567 20.9899Z"
        fill="black"
      />
    </svg>
  )
}

export default IconAvgTime
