import { CSSProperties } from 'react'
import css from './ToggleInput.module.css'
import classNames from 'classnames/bind'

const cx = classNames.bind(css)

type Props = {
  isActive: boolean | undefined
  label?: string
  style?: CSSProperties
  onToggle: () => void
  isDisabled?: boolean
  tiny?: boolean
  alternativeAccentColor?: string
}

const ToggleInput = ({
  isActive,
  label,
  style,
  tiny,
  onToggle,
  isDisabled,
  alternativeAccentColor,
}: Props) => {
  return (
    <div
      onClick={onToggle}
      className={cx('container', { disabled: isDisabled, tiny })}
      style={style}
    >
      <div
        className={cx('toggle', { active: isActive })}
        style={{
          backgroundColor: isActive
            ? alternativeAccentColor || undefined
            : undefined,
        }}
      >
        <div className={cx('pipka')} />
      </div>
      {label && <div className={cx('label')}>{label}</div>}
    </div>
  )
}

export default ToggleInput
