import React from 'react'

// Updated regex to exclude trailing punctuation
const urlRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s)]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s)]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s)]{2,}|www\.[a-zA-Z0-9]+\.[^\s)]{2,})/gi

const convertUrlsToLinks = (
  text: string,
  background: 'light' | 'dark',
  characterLimit?: number
) => {
  const linkLengthLimit = 36

  // Split text into parts (URLs and non-URLs)
  const parts = text.split(urlRegex)
  const processedParts: Array<{
    text: string
    isLink: boolean
    url?: string
    displayLength: number
  }> = []

  // First pass: Process all parts and calculate their display lengths
  parts.forEach((part, i) => {
    if (urlRegex.test(part)) {
      let url = part
      let displayText = part
      let suffix = ''

      // Check for and remove trailing punctuation
      const punctuationRegex = /[.,;:!?]$/
      if (punctuationRegex.test(displayText)) {
        suffix = displayText.slice(-1)
        displayText = displayText.slice(0, -1)
        url = url.slice(0, -1)
      }

      // Check if the URL is enclosed in parentheses
      if (displayText.startsWith('(') && displayText.endsWith(')')) {
        displayText = displayText.slice(1, -1)
        url = url.slice(1, -1)
        suffix = ')' + suffix
      }

      // Remove protocol and 'www.' from display text
      displayText = displayText.replace(/^(https?:\/\/)?(www\.)?/, '')

      // Ensure the URL starts with a protocol
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url
      }

      // Truncate if necessary
      if (displayText.length > linkLengthLimit) {
        displayText = displayText.substring(0, linkLengthLimit - 3) + '...'
      }

      processedParts.push({
        text: displayText + suffix,
        isLink: true,
        url,
        displayLength: displayText.length + suffix.length,
      })
    } else {
      processedParts.push({
        text: part,
        isLink: false,
        displayLength: part.length,
      })
    }
  })

  // If no character limit is set, render all parts
  if (!characterLimit) {
    return processedParts.map((part, i) => {
      if (part.isLink) {
        return (
          <React.Fragment key={i}>
            <a href={part.url} target="_blank" rel="noreferrer">
              {part.text}
            </a>
          </React.Fragment>
        )
      }
      return part.text
    })
  }

  // Second pass: Truncate text while preserving links
  let currentLength = 0
  const truncatedParts: typeof processedParts = []
  let addedEllipsis = false

  for (const part of processedParts) {
    if (currentLength >= characterLimit) {
      break
    }

    if (part.isLink) {
      // Always include full links
      truncatedParts.push(part)
      continue
    }

    const remainingChars = characterLimit - currentLength
    if (part.displayLength > remainingChars) {
      // Truncate this part and add ellipsis
      truncatedParts.push({
        ...part,
        text: part.text.slice(0, remainingChars - 3) + '...',
        displayLength: remainingChars,
      })
      addedEllipsis = true
      break
    }

    truncatedParts.push(part)
    currentLength += part.displayLength
  }

  // Render truncated parts
  return truncatedParts.map((part, i) => {
    if (part.isLink) {
      return (
        <React.Fragment key={i}>
          <a href={part.url} target="_blank" rel="noreferrer">
            {part.text}
          </a>
        </React.Fragment>
      )
    }
    return part.text
  })
}
type Props = {
  children: string
  background?: 'light' | 'dark'
  characterLimit?: number
}

const Text = ({ children, characterLimit, background = 'light' }: Props) => {
  return (
    <span
      style={{
        whiteSpace: 'pre-wrap',
      }}
    >
      {convertUrlsToLinks(children, background, characterLimit)}
    </span>
  )
}

export default Text
