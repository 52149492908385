import React, {
  useEffect,
  useState,
  useRef,
  ReactNode,
  UIEventHandler,
  useContext,
  useMemo,
  useCallback,
} from 'react'
import css from './WorkoutBuilder.module.css'
import { v4 as uuid } from 'uuid'
import Button, { ButtonType } from '../../buttons/Button/Button'
import SupersetInput from '../../inputs/SupersetInput/SupersetInput'
import TitleInput from '../../inputs/TitleInput/TitleInput'
import { HelpCircle, X as IconClose, Plus } from 'react-feather'
import RepsInput from '../../inputs/RepsInput/RepsInput'
import RoundsInput from '../../inputs/RoundsInput/RoundsInput'
import SegmentedInput from '../../inputs/SegmentedInput/SegmentedInput'
import { IconWorkoutType } from '../../atoms/Icons/Icons'
import ButtonIcon from '../../buttons/ButtonIcon/ButtonIcon'
import useWorkoutBuilder from '../../../hooks/useWorkoutBuilder'
import { Controller, FormProvider } from 'react-hook-form'
import TimeCapInput from '../../inputs/TimeCapInput/TimeCapInput'
import Confirm from '../../modals/Confirm'
import CalendarContext from '../../../context/CalendarContext'
import DoubleTimeInput from '../../inputs/DoubleTimeInput/DoubleTimeInput'
import { Workout } from '../../../types/workouts'
import { UserContext } from '../../../context/UserContext'
import { ScalingOptionInput } from '../../inputs/ScalingOptionInput/ScalingOptionInput'

import { config } from '../../../config'
import { CalendarEvent } from '../../../types/calendarEvents'
import TrackingTypeInput from '../../inputs/TrackingTypeInput/TrackingTypeInput'
import NumberInput from '../../inputs/NumberInput/NumberInput'
import convertSecondsToTimestamp from '../../../utilities/convertSecondsToTimestamp'
import pluralize from 'pluralize'
import { Tooltip } from 'react-tooltip'
import { Tracking } from '../../../types/types'
import getTrackingTypeDescription from '../../../utilities/workout builder/getTrackingTypeDescription'

type Props = {
  index: number
  workoutEventToEdit: CalendarEvent.Workout | null
  workoutToEdit?: Workout.Item | null
  newWorkoutTracking?: Workout.Item['tracking']
  dateSubheader?: ReactNode
  onDismiss: () => void
  activeDate: string | null
  isPresetMode?: boolean
}

const WorkoutBuilder = ({
  index,
  workoutToEdit,
  workoutEventToEdit,
  dateSubheader,
  activeDate,
  newWorkoutTracking = 'reps-weight',
  isPresetMode,
  onDismiss,
}: Props) => {
  const { userID } = useContext(UserContext)

  const {
    activeScalingOption,
    formMethods,
    isValid,
    isDirty,
    isSuperset,
    values,
    publishWorkout,
    saveWorkout,
    unpublishWorkout,
    handleDelete,
    setItems,
    handleScalingOptionChange,
    addScalingOption,
    setItemsAreLoading,
  } = useWorkoutBuilder({
    index,
    workoutEventToEdit,
    workoutToEdit: workoutEventToEdit?.details,
    newWorkoutTracking,
    activeDate,
    onDismiss,
    isPresetMode,
  })

  const [scrollShadowIsVisible, setScrollShadowIsVisible] = useState(false)

  const handleBuilderScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = (e.target as HTMLElement).scrollTop
    if (!scrollShadowIsVisible && scrollTop > 10) setScrollShadowIsVisible(true)
    else if (scrollShadowIsVisible && scrollTop <= 10)
      setScrollShadowIsVisible(false)
  }

  const [confirmation, setConfirmation] = useState<{
    title: string
    text: string
    what: string
  } | null>(null)

  const {
    pendingActiveDate,
    preventActiveDateChange,
    proceedToPendingDate,
    setPreventActiveDateChange,
  } = useContext(CalendarContext)

  useEffect(() => {
    if (pendingActiveDate && preventActiveDateChange) {
      setConfirmation({
        title: 'Proceed without saving?',
        text: 'All the changes will be lost, like tears in the rain.',
        what: 'switch-date',
      })
    }
  }, [pendingActiveDate])

  const handleDeleteConfirmation = () => {
    const workoutID = workoutEventToEdit?.details?.id
    if (workoutID) handleDelete(workoutID)
    setConfirmation(null)
  }

  const handleCloseConfirmation = () => {
    onDismiss()
    setConfirmation(null)
    setPreventActiveDateChange(false)
  }

  useEffect(() => {
    // For new workouts, we immediately focus on first movement name for convenience
    if (!workoutEventToEdit) {
      const smartNoteNotSupported = ['no-score', 'text'].includes(
        newWorkoutTracking
      )
      const firstMovementIndex = smartNoteNotSupported ? 0 : 1
      formMethods.setFocus(`superset.${firstMovementIndex}.movement.name`)
    }
  }, [])

  const scalingModeExists =
    !setItems[0].set ||
    !!setItems.find(
      (item) =>
        item.set?.scaling.ageGroup === activeScalingOption.ageGroup &&
        item.set.scaling.effort === activeScalingOption.effort &&
        item.set.scaling.genderGroup === activeScalingOption.genderGroup
    )

  const isAddNewWorkoutEnabled =
    config.access.playbook_add_new_workout?.includes(userID)

  const getButtonActions = useCallback((): BuilderButton[] | undefined => {
    if (!isPresetMode) {
      // Creating a new workout
      if (!workoutEventToEdit) {
        return [
          {
            label: 'Publish Workout',
            onClick: publishWorkout,
            disabled: !isValid,
            type: 'primary',
          },
          { label: 'Save Draft', onClick: saveWorkout, type: 'secondary' },
        ]
      }

      // Editing an existing workout
      if (workoutEventToEdit) {
        if (!workoutEventToEdit.isPublished) {
          return [
            {
              label: 'Publish Workout',
              onClick: publishWorkout,
              disabled: !isValid,
              type: 'primary',
            },
            { label: 'Save Draft', onClick: publishWorkout, type: 'secondary' },
            {
              label: 'Delete',
              onClick: () =>
                setConfirmation({
                  title: 'Delete Workout?',
                  text: 'This cannot be undone.',
                  what: 'delete',
                }),
              type: 'tertiary-destructive',
            },
          ]
        } else {
          return [
            {
              label: 'Save Workout',
              onClick: publishWorkout,
              type: 'primary',
              disabled: !isValid,
            },
            {
              label: 'Unpublish Workout',
              onClick: unpublishWorkout,
              type: 'secondary',
            },
            {
              label: 'Delete',
              onClick: () =>
                setConfirmation({
                  title: 'Delete Workout?',
                  text: 'This cannot be undone.',
                  what: 'delete',
                }),
              type: 'tertiary-destructive',
            },
          ]
        }
      }
    }
    // Creating a new preset workout
    else if (!workoutToEdit) {
      return [
        { label: 'Save Workout', onClick: publishWorkout, type: 'primary' },
        {
          label: 'Delete',
          onClick: () =>
            setConfirmation({
              title: 'Delete Workout?',
              text: 'This cannot be undone.',
              what: 'delete',
            }),
          type: 'tertiary-destructive',
        },
      ]
    }
  }, [workoutEventToEdit, workoutToEdit, isValid])

  const getWorkoutOptions = () => {
    switch (values.tracking) {
      case 'reps-weight':
        return <RepsInput label={'Sets & Reps'} isRequired />
      case 'complex':
        return (
          <RoundsInput
            label="Sets"
            valuePreviewLabel="set"
            min={1}
            isRequired
          />
        )
      case 'time':
        return (
          <RepsInput
            label="Rounds or Reps"
            hintText="I.e: 4 (rounds) or 21-15-9 (reps)"
            roundsMode
            isRequired
          />
        )
      case 'total-reps':
      case 'avg-time':
      case 'weight-per-round':
        return <RoundsInput label="Rounds" min={1} />

      case 'emom':
      case 'emom-alt': {
        const totalSeconds = values.timeCapRound * values.rounds
        const timeText =
          totalSeconds % 60 === 0
            ? totalSeconds / 60
            : convertSecondsToTimestamp(totalSeconds)

        return <RoundsInput label="Rounds" subText={`${timeText} min cap`} />
      }
    }
  }

  const getWorkoutTimeOptions = () => {
    switch (values.tracking) {
      case 'total-reps':
        return values.rounds > 1 ? (
          <DoubleTimeInput name="roundRestTime" />
        ) : (
          <TimeCapInput name="timeCap" valuePreviewLabel="min" />
        )
      case 'emom':
      case 'emom-alt':
        return (
          <TimeCapInput
            name="timeCapRound"
            label="Interval Time"
            min={60}
            isRequired
            useSeconds={true}
            hintText="I.e: 2, :30 or 2:30"
          />
        )
      default:
        return (
          <TimeCapInput
            name="timeCap"
            min={1}
            isRequired={['rounds', 'rounds-reps'].includes(values.tracking)}
          />
        )
    }
  }

  const {
    title: trackingTypeInfoTitle,
    description: trackingTypeInfoDescription,
  } = getTrackingTypeDescription(values.tracking) || {
    title: '',
    description: '',
  }

  return (
    <FormProvider {...formMethods}>
      <Confirm
        isVisible={!!confirmation}
        title={confirmation ? confirmation.title : ''}
        text={confirmation ? confirmation.text : ''}
        buttonLabels={
          confirmation && confirmation.what === 'delete'
            ? { primary: 'Delete', secondary: 'Go Back' }
            : { primary: 'Proceed', secondary: 'Go Back' }
        }
        onDismiss={() => setConfirmation(null)}
        onConfirm={() => {
          if (confirmation)
            switch (confirmation.what) {
              case 'delete':
                return handleDeleteConfirmation()
              case 'close':
                return handleCloseConfirmation()
              case 'switch-date': {
                setPreventActiveDateChange(false)
                proceedToPendingDate()
                handleCloseConfirmation()
              }
            }
        }}
        isDestructive={confirmation?.what === 'delete'}
      />
      <header className={css['header']}>
        <div className={`${css['subheader']} ${'light'}`}>
          {!isPresetMode
            ? dateSubheader
            : workoutEventToEdit
            ? 'Edit Workout'
            : 'New Workout'}
        </div>
        <TitleInput
          editWorkoutMode={!!workoutEventToEdit}
          defaultTitle={isPresetMode ? 'Title' : ''}
          isBenchmarkEditMode={!!isPresetMode}
          fieldName="title"
          editTitlePlaceholder="Edit Workout"
          newTitlePlaceholder="New Workout"
        />
        <div className={css['close-icon']}>
          <ButtonIcon
            icon={<IconClose />}
            className={css['close-icon']}
            onClick={() =>
              isDirty
                ? setConfirmation({
                    title: 'Proceed without saving?',
                    text: 'All the changes will be lost, like tears in the rain.',
                    what: 'close',
                  })
                : onDismiss()
            }
            type={'light'}
            style={{ margin: '-4px 0 0 0' }}
          />
        </div>
        {isAddNewWorkoutEnabled && isPresetMode && (
          <ScalingOptionInput
            onChange={handleScalingOptionChange}
            setItems={setItems}
            value={activeScalingOption}
            style={{ margin: '16px 0 0 0' }}
            isLoading={setItemsAreLoading}
          />
        )}
        <div
          className={`${css['scroll-shadow']} ${
            scrollShadowIsVisible && css['visible']
          }`}
        />
      </header>
      {scalingModeExists ? (
        <div id={css['workout-builder']} onScroll={handleBuilderScroll}>
          <div className={css['top-items']}>
            {isPresetMode ? (
              <select {...formMethods.register('workoutType')}>
                <option>strength</option>
                <option>conditioning</option>
                <option>skill</option>
                <option>girls</option>
                <option>heroes</option>
                <option>special</option>
                <option>endurance</option>
                <option>cf-open</option>
                <option>gymnastics</option>
              </select>
            ) : (
              <Controller
                name="workoutType"
                control={formMethods.control}
                render={({ field }) => (
                  <SegmentedInput
                    value={field.value}
                    onChange={(newValue) => field.onChange(newValue)}
                    style={{ margin: '4px 0 ' }}
                    segments={[
                      {
                        label: 'Strength',
                        value: 'strength',
                        icon: (
                          <IconWorkoutType
                            type={'strength'}
                            size={12}
                            outline={false}
                          />
                        ),
                      },
                      {
                        label: 'Conditioning',
                        value: 'conditioning',
                        icon: (
                          <IconWorkoutType
                            type={'conditioning'}
                            size={12}
                            outline={false}
                          />
                        ),
                      },
                      {
                        label: 'Skill',
                        value: 'skill',
                        icon: (
                          <IconWorkoutType
                            type={'skill'}
                            size={12}
                            outline={false}
                          />
                        ),
                      },
                    ]}
                  />
                )}
              />
            )}

            <h3>Instructions</h3>

            <SupersetInput />

            <div className={css['options']}>
              <h3>Options</h3>
              <div className={`${css['left']} ${css['half']}`}>
                <div>
                  <Tooltip
                    id="t-tracking-type"
                    place="left-start"
                    delayShow={400}
                    className="tooltip big"
                    noArrow={true}
                    offset={4}
                    style={{ margin: '17px 0 0 -72px' }}
                    render={() => (
                      <div>
                        <h1>Score / Format</h1>
                        <p>
                          The score and format setting determines how athletes
                          track the workout, their score for the gym scoreboard,
                          and the smart note.
                        </p>

                        <h2>{trackingTypeInfoTitle}</h2>
                        <p>{trackingTypeInfoDescription}</p>
                      </div>
                    )}
                  />
                  <label htmlFor="tracking">
                    Score / Format
                    <i data-tooltip-id="t-tracking-type">
                      <HelpCircle
                        size={14}
                        color={'var(--icon)'}
                        style={{
                          position: 'relative',
                          top: '2.5px',
                          left: '4px',
                        }}
                      />
                    </i>
                  </label>
                  <Controller
                    name="tracking"
                    control={formMethods.control}
                    render={({ field }) => (
                      <TrackingTypeInput
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                </div>
                {getWorkoutTimeOptions()}
              </div>

              <div className={`${css['right']}  ${css['half']}`}>
                {getWorkoutOptions()}
              </div>
            </div>
          </div>

          <div className={css['bottom-items']}>
            {!isValid && (
              <p
                className="caption"
                style={{ textAlign: 'center', marginBottom: '4px' }}
              >
                Please add at least one movement or a note
              </p>
            )}
            <div className={css['bottom-buttons']}>
              <BuilderActions
                buttons={getButtonActions()}
                onAction={() => {}}
              />
            </div>
          </div>
        </div>
      ) : (
        <div
          className={css['no-scaling-placeholder']}
          onClick={addScalingOption}
        >
          <div>
            <Plus size={24} />
            Add Scaling Option
          </div>
        </div>
      )}
    </FormProvider>
  )
}

export default WorkoutBuilder

type BuilderAction = 'save' | 'publish' | 'unpublish' | 'delete'
type BuilderButton = {
  label: string
  onClick: () => void
  type: ButtonType
  disabled?: boolean
}

const BuilderActions = ({
  buttons,
  onAction,
}: {
  buttons: BuilderButton[] | undefined
  onAction: (action: BuilderAction) => void
}) => {
  if (buttons)
    return (
      <>
        {buttons.map(({ label, onClick, type, disabled }) => (
          <Button key={label} onClick={onClick} type={type} disabled={disabled}>
            {label}
          </Button>
        ))}
      </>
    )
  else return <></>
}
