import React, { useState, useEffect } from 'react'
import css from './TitleInput.module.css'
import TextareaAutosize from 'react-textarea-autosize'
import { useFormContext } from 'react-hook-form'
import { useWorkoutShortcuts } from '../../../hooks/useWorkoutShortcuts'

type Props = {
  editWorkoutMode: boolean
  defaultTitle?: string
  isBenchmarkEditMode: boolean
  isRequired?: boolean
  placeholder?: string
  fieldName: string
  editTitlePlaceholder: string
  newTitlePlaceholder: string
}

const TitleInput = ({
  fieldName,
  isRequired,
  editWorkoutMode,
  defaultTitle,
  isBenchmarkEditMode,
  placeholder,
  editTitlePlaceholder,
  newTitlePlaceholder,
}: Props) => {
  const { register, watch, setValue, setFocus, formState, clearErrors } =
    useFormContext()
  const title = watch(fieldName)
  const [mode, setMode] = useState<'edit' | 'no-title'>(
    editWorkoutMode && title ? 'edit' : 'no-title'
  )

  const clearTitle = () => {
    setMode('no-title')
    setValue(fieldName, '')
    clearErrors(fieldName)
  }

  const activateInput = () => {
    setMode('edit')
    setTimeout(() => setFocus(fieldName), 50)
    //setFocus('title')
  }

  const handleBlur = () => {
    setTimeout(() => setMode(title ? 'edit' : 'no-title'), 100)
  }

  const _defaultTitle = defaultTitle ? defaultTitle : 'New Workout'

  const { shortcuts: workoutShortcuts } = useWorkoutShortcuts()

  const workoutTitleIsAvailable = (title: string) => {
    const reservedTitles = workoutShortcuts.map((w) =>
      w.title.toLocaleLowerCase()
    )
    return !reservedTitles.includes(title.toLocaleLowerCase())
  }

  const isError = !!formState.errors[fieldName]
  const errorMessage = formState.errors[fieldName]?.message || ''

  return (
    <>
      <div
        className={`${css['title']} ${title && css['adj']} ${
          isError && css['error']
        }`}
        onClick={activateInput}
      >
        {mode === 'no-title' && !isRequired ? (
          <div>
            <h2>
              {editWorkoutMode ? editTitlePlaceholder : newTitlePlaceholder}
            </h2>
          </div>
        ) : (
          <div className={css['container']}>
            <input
              type="text"
              {...register(fieldName, {
                onBlur: handleBlur,
                required: isRequired ? 'The title is required' : false,
                validate: !isBenchmarkEditMode
                  ? (value) => workoutTitleIsAvailable(value)
                  : undefined,
              })}
              placeholder={
                placeholder
                  ? placeholder
                  : editWorkoutMode
                  ? 'Edit Workout'
                  : _defaultTitle
              }
            />
          </div>
        )}
      </div>
      {((!isRequired && !isError) || isError) && (
        <div className={css['subheader-and-link']}>
          {!isRequired ? (
            title ? (
              <span className={css['title-link']} onClick={clearTitle}>
                clear
              </span>
            ) : mode === 'edit' && !isRequired ? (
              <span
                className={css['title-link']}
                onClick={(e) =>
                  e.stopPropagation()
                } /** handleBlur will fire automatically */
              >
                cancel
              </span>
            ) : (
              <span className={css['title-link']} onClick={activateInput}>
                {'set title'}
              </span>
            )
          ) : (
            !isError && <span className={css['error']}></span>
          )}
          {isError && (
            <span className={css['error']}>
              {!!errorMessage ? (
                <>{errorMessage}</>
              ) : (
                <>This title is reserved for a&nbsp;benchmark workout</>
              )}
            </span>
          )}
        </div>
      )}
    </>
  )
}

export default TitleInput
