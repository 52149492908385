import React from 'react'

type Props = {
  size?: number
}

const IconNoScore = ({ size = 32 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="11" stroke="black" strokeWidth="2" />
      <circle cx="16" cy="16" r="7" stroke="#16DB70" strokeWidth="2" />
      <path
        d="M13 15.5L15.5 18L28.5 5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default IconNoScore
