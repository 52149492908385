import { useContext, useEffect, useRef, useState } from 'react'
import { Cycle } from '../../types/cycles'
import { useForm } from 'react-hook-form'
import { DateTime } from 'luxon'
import { UserContext } from '../../context/UserContext'
import { AppContext } from '../../context/AppContext'
import { v4 as uuid } from 'uuid'
import useCyclePublisher from './useCyclePublisher'
import { CalendarEvent } from '../../types/calendarEvents'
import { Workout } from '../../types/workouts'
import { getNewNoteEvent } from '../../utilities/workout builder/getNewNoteEvent'
import { useWorkoutPublisher } from '../useWorkoutPublisher'
import { useQueryClient } from '@tanstack/react-query'
import { Calendar } from '../calendar/useCalendar'
import useCalendarEvents from '../calendar/useCalendarEvents'

interface Props {
  activeDate: string
  noteToEdit: CalendarEvent.Note | null
  onSuccess: () => void
  newNoteType: 'note' | 'staff-note' | 'pinned-note'
}

const useNoteBuilder = ({
  activeDate,
  noteToEdit,
  newNoteType,
  onSuccess,
}: Props) => {
  const timestamp = {
    seconds: DateTime.now().toUnixInteger(),
  }

  const { userID } = useContext(UserContext)
  const { currentSpace, currentGymTrack } = useContext(AppContext)
  const gymID = currentSpace?.id ?? 'no-id'
  //const { publishCycle } = useNoteBuilder()

  const { pinnedEvents, pinnedForeverEvents, unpinnedEvents } =
    useCalendarEvents({
      dateISO: activeDate,
      enabled: true,
    })

  const { current: defaultValues } = useRef(
    noteToEdit
      ? { ...noteToEdit, updatedBy: userID, updatedDate: timestamp }
      : {
          ...getNewNoteEvent({
            uid: userID,
            eventDateISO: activeDate,
            index: 0,
            newNoteType,
            gymID,
            trackID: currentGymTrack,
          }),
          createdBy: userID,
          updatedBy: userID,
        }
  )

  const formMethods = useForm<CalendarEvent.Note>({
    defaultValues: defaultValues,
    mode: 'onChange',
  })

  const { isDirty, isValid } = formMethods.formState

  const { submitCalendarEvents } = useWorkoutPublisher()

  const onSubmit = (data: CalendarEvent.Note) => {
    if (!currentSpace) return

    if (!data.details.category.trim()) {
      formMethods.setError('details.category', {
        type: 'manual',
        message: 'Please add category label',
      })
      formMethods.setFocus('details.category')
      return
    }

    const processedData = processFormValues(data)

    submitCalendarEvents([processedData])

    onSuccess()
  }

  const queryClient = useQueryClient()

  const advancedNotesEnabled =
    !!currentSpace?.subscription.enabled_features.advanced_notes

  const processFormValues = (data: CalendarEvent.Note) => {
    const { eventDateISO } = data
    const { pinDates, isPinned } = data.pinOptions || {
      pinDates: null,
      isPinned: false,
    }

    const isPinnedForever = !pinDates && isPinned
    const eventDateISOAdjusted = isPinnedForever ? '9999-12-31' : activeDate

    const oldDate = noteToEdit?.eventDateISO
    const newDate = eventDateISOAdjusted

    const index = noteToEdit
      ? noteToEdit.index
      : isPinnedForever
      ? pinnedForeverEvents.length
      : isPinned
      ? pinnedEvents.length
      : unpinnedEvents.length

    if (noteToEdit && oldDate !== newDate) {
      // Removing the edited event from cached events for old date
      const oldQueryKey = ['events', gymID, currentGymTrack, oldDate]
      queryClient.setQueryData<CalendarEvent.Item[]>(oldQueryKey, (oldData) => {
        const newData = oldData ? [...oldData] : []
        return newData.filter((event) => event.id !== noteToEdit.id)
      })
    }

    const updatedData: CalendarEvent.Note = {
      ...data,
      eventDateISO: eventDateISOAdjusted,
      pinOptions: data.pinOptions,
      index,
      details: {
        ...data.details,
        content: data.details.content.trim(),
        title: data.details.title.trim(),
        preview: data.details.preview.trim(),
        category: data.details.category.trim(),
        allowSocials: advancedNotesEnabled ? data.details.allowSocials : false,
        superset: data.details.superset.map((item) => ({
          ...item,
          note: data.details.content.trim(),
        })),
      },
    }

    return updatedData
  }
  return {
    publishNote: formMethods.handleSubmit(onSubmit),
    formMethods,
    isDirty,
    isValid,
  }
}

export default useNoteBuilder
