import { QueryKey, useQueries, useQueryClient } from '@tanstack/react-query'
import { CalendarEvent } from '../../types/calendarEvents'
import { DateTime } from 'luxon'
import { useContext } from 'react'
import { AppContext } from '../../context/AppContext'
import { query } from 'firebase/firestore'

/**
 * @returns all currently loaded events without fetching
 */

const useCachedCalendarEvents = () => {
  const queryClient = useQueryClient()

  const { currentSpace, currentGymTrack: trackID } = useContext(AppContext)
  const gymID = currentSpace?.id ?? 'no-id'

  const getCachedEventsForDate = (ISODate: string) => {
    const queryKey = ['events', gymID, trackID, ISODate]
    const queryData = queryClient.getQueriesData({ queryKey })

    const cachedCalendarEvents = queryData
      .map(([, data]) => data)
      .filter((data) => data)
      .flat() as CalendarEvent.Workout[]

    return cachedCalendarEvents.filter(
      (cachedEvent) => cachedEvent.eventDateISO === ISODate
    )
  }

  // const getAllCachedEvents = () => {
  //   const queryKey = ['events']
  //   const queryData = queryClient.getQueriesData({ queryKey })

  //   useQueries

  //   return queryData
  //     .map(([, data]) => data)
  //     .filter((data) => data)
  //     .flat() as CalendarEvent.Item[]
  // }

  const allCachedEvents = useAllCachedEvents()

  // console.log('allCachedEvents', allCachedEvents)

  const getCachedEventByID = (id: string) => {
    // const cachedCalendarEvents = getAllCachedEvents()

    return allCachedEvents.find((cachedEvent) => cachedEvent.id === id)
  }

  const updateCachedEvents = ({
    affectedDates,
    updatedEvents,
  }: {
    affectedDates: string[]
    updatedEvents: CalendarEvent.Item[]
  }) => {
    // Will be up to two date groups: source and destination

    affectedDates.forEach((date) => {
      const queryKey = ['events', gymID, trackID, date]
      const eventsForDate = updatedEvents.filter(
        (event) => event.eventDateISO === date
      )
      queryClient.setQueryData<CalendarEvent.Item[]>(queryKey, eventsForDate)
    })
  }

  return {
    allCachedEvents,
    updateCachedEvents,
    getCachedEventsForDate,
    getCachedEventByID,
  }
}

export default useCachedCalendarEvents

export const getEventsQueryKeyForDate = (
  dateISO: string,
  gymID: string
): QueryKey => {
  const fromDate = DateTime.fromISO(dateISO).startOf('month').toISODate()
  const toDate = DateTime.fromISO(dateISO).endOf('month').toISODate()

  const dateOverride = undefined
  const enabled = true
  const eventsQueryKey: QueryKey = [
    'events',
    { gymID, fromDate, toDate, dateOverride, enabled },
  ]

  return eventsQueryKey
}

const useAllCachedEvents = () => {
  const queryClient = useQueryClient()

  // Get all queries that start with 'events'
  const queries = queryClient.getQueryCache().findAll({
    queryKey: ['events'],
  })

  // Create query options for each cached query
  const queryOptions = queries.map((query) => ({
    queryKey: query.queryKey,
    queryFn: () => query.state.data,
    // Prevent refetching since we just want cached data
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: !!query.state.data,
  }))

  // Use useQueries to subscribe to cache updates
  const results = useQueries({
    queries: queryOptions,
  })

  return results
    .map((result) => result.data)
    .filter((data): data is CalendarEvent.Item[] => !!data)
    .flat()
}
