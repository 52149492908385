import axios from 'axios'
import { auth } from '../firebase'
import { Result } from '../types/results'
import { Comment } from '../types/social'
import { Gender, MeasureUnits } from '../types/types'
import { CalendarEvent } from '../types/calendarEvents'
import { Cycle } from '../types/cycles'
import { Workout } from '../types/workouts'

const getAuthToken = async (): Promise<string | null> => {
  //let authToken = null

  try {
    const authToken = await auth.currentUser?.getIdToken()
    return authToken || null
  } catch (err: any) {
    console.error(err)
    return null
  } finally {
    //return authToken || null
  }
}

class API {
  static url = process.env.REACT_APP_API_URL

  static getUsersData = async (dataForUserIDs: string[]) => {
    const authToken = await getAuthToken()

    if (!authToken) return undefined
    try {
      const response = await axios.post(API.url + `getUsersData`, {
        dataForUserIDs,
        authToken,
      })
      return response.data
    } catch (err: any) {
      console.error('err', err)
    }
  }

  // Result interactions
  static postResult = async (result: Result.Request) => postResult(result)
  static deleteResult = async (result: Result.Full) => deleteResult(result)
  static deleteManualEntry = async (resultID: string) =>
    deleteManualEntry(resultID)
  static postImportedData = async (importedData: Result.DataImportRequest) =>
    postImportedData(importedData)

  // Social
  static postComment = async (comment: Comment.Request.Data) =>
    postComment(comment)

  // User
  static patchUserAttribute = async (attr: EditableUserAttributes) =>
    patchUserAttribute(attr)

  // Coach
  static postEvent = async (
    eventsData: CalendarEvent.Item[],
    gymID: string
  ) => {
    return postEvent(eventsData, gymID)
  }

  // static postWorkout = async (workoutData: RewodLegacyEvent.Item, gymID: string) => {
  //   return postWorkout(workoutData, gymID)
  // }

  static publishAllWorkouts = async (eventIDs: string[], gymID: string) => {
    return publishAllWorkouts(eventIDs, gymID)
  }

  static publishAllEvents = async (eventIDs: string[], gymID: string) => {
    return publishAllEvents(eventIDs, gymID)
  }

  static deleteWorkout = async (
    eventData: CalendarEvent.Workout,
    gymID: string,
    eventItemID: string
  ) => deleteWorkout(eventData, gymID, eventItemID)

  static postBenchmarkWorkout = async (
    workoutData: Workout.Item[],
    gymID: string
  ) => {
    const authToken = await auth.currentUser?.getIdToken()

    return axios.post(API.url + `postBenchmarkWorkout`, {
      workoutData,
      gymID,
      authToken,
    })
  }

  static postCycle = async (cycleData: Cycle, gymID: string) =>
    postCycle(cycleData, gymID)
}

//
// ******* RESULT TRACKING
//
const postResult = async (result: Result.Request) => {
  const authToken = await auth.currentUser?.getIdToken()

  return axios.post(API.url + `postResult`, {
    result,
    authToken,
  })
}

const deleteResult = async (result: Result.Full) => {
  const authToken = await auth.currentUser?.getIdToken()

  return axios.post(API.url + `deleteResult`, {
    result,
    authToken,
  })
}

const deleteManualEntry = async (resultID: string) => {
  const authToken = await auth.currentUser?.getIdToken()

  console.log('deleting...', resultID)

  return axios.post(API.url + `deleteManualEntry`, {
    resultID,
    authToken,
  })
}

const postImportedData = async (importedData: Result.DataImportRequest) => {
  const authToken = await auth.currentUser?.getIdToken()

  return axios.post(API.url + `postImportedData`, {
    importedData,
    authToken,
  })
}

//
// ******* SOCIAL
//
const postComment = async (comment: Comment.Request.Data) => {
  const authToken = await auth.currentUser?.getIdToken()

  try {
    axios.post(API.url + `postComment`, {
      ...comment,
      authToken,
    })
  } catch (err: any) {
    console.error(err.message)
  }
}

//
// ******* USER
//
export type EditableUserAttributes = {
  email?: string
  name?: string
  password?: string
  newpassword?: string
  badgeCount?: number
  gender?: Gender
  measureUnits?: MeasureUnits
  defaultGym?: {
    gymID: string
    trackID: string
  }
  pushToken?: string
  photoURL?: string
  onboardingTasks?: { unitsTipSeen?: boolean }
}
const patchUserAttribute = async (attr: EditableUserAttributes) => {
  const authToken = await auth.currentUser?.getIdToken()

  try {
    axios.post(API.url + `patchUserAttribute`, {
      attr: attr,
      authToken,
    })
  } catch (err: any) {
    console.error(err.message)
  }
}

//
// ******* GYM
//
const postEvent = async (eventsData: CalendarEvent.Item[], gymID: string) => {
  const authToken = await auth.currentUser?.getIdToken()

  // try {
  return axios.post(API.url + `postCalendarEvent`, {
    eventsData,
    gymID,
    authToken,
  })
  // } catch (err: any) {
  //console.error(err.message)
  //}
}

const publishAllEvents = async (eventIDs: string[], gymID: string) => {
  const authToken = await auth.currentUser?.getIdToken()

  return axios.post(API.url + `publishAllEvents`, {
    eventIDs,
    gymID,
    authToken,
  })
}

const publishAllWorkouts = async (eventIDs: string[], gymID: string) => {
  const authToken = await auth.currentUser?.getIdToken()

  // try {
  return axios.post(API.url + `publishAllWorkouts`, {
    eventIDs,
    gymID,
    authToken,
  })
  // } catch (err: any) {
  //console.error(err.message)
  //}
}

const deleteWorkout = async (
  eventData: CalendarEvent.Workout,
  gymID: string,
  eventItemID: string
) => {
  const authToken = await auth.currentUser?.getIdToken()

  try {
    axios.post(API.url + `deleteWorkout`, {
      eventData,
      gymID,
      eventItemID,
      authToken,
    })
  } catch (err: any) {
    console.error(err.message)
  }
}

const postCycle = async (cycleData: Cycle, gymID: string) => {
  const authToken = await auth.currentUser?.getIdToken()

  try {
    axios.post(API.url + `postCycle`, {
      cycleData,
      gymID,
      authToken,
    })
  } catch (err: any) {
    console.error(err.message)
  }
}

export default API
