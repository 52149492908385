import React, { CSSProperties, useEffect } from 'react'

import { DatePicker as AntdDatePicker, ConfigProvider } from 'antd'
import en_GB from 'antd/lib/locale/en_GB'
import { Controller, UseFormReturn } from 'react-hook-form'
import dayjs, { Dayjs } from 'dayjs'
import { ArrowRight, Calendar } from 'react-feather'
import updateLocale from 'dayjs/plugin/updateLocale'
import { FirestoreTimestamp } from '../../../types/types'
import { DateTime } from 'luxon'
type Props = {
  formMethods: UseFormReturn<any>
  label?: string
  name: string
  style?: CSSProperties
  mode?: 'firestore-timestamp' | 'iso'
  required?: boolean
}

type ISODateRange = {
  startDateISO: string | null
  endDateISO: string | null
}

type FirestoreTimestampRange = {
  startDate: FirestoreTimestamp
  endDate: FirestoreTimestamp
}
type PossibleDateType = FirestoreTimestampRange | ISODateRange | null

type DatePickerValue =
  | [start: Dayjs | null | undefined, end: Dayjs | null | undefined]
  | null

const DateRangeInput = ({
  label,
  name,
  formMethods,
  style,
  mode = 'firestore-timestamp',
  required = false,
}: Props) => {
  const { RangePicker } = AntdDatePicker

  const transformIncomingValue = (date: PossibleDateType): DatePickerValue => {
    if (!date) return null
    switch (mode) {
      case 'firestore-timestamp': {
        const startDayJS =
          dayjs.unix((date as FirestoreTimestampRange).startDate.seconds) ||
          null
        const endDayJS =
          dayjs.unix((date as FirestoreTimestampRange).endDate.seconds) || null

        const result: DatePickerValue = [startDayJS, endDayJS]

        return result
      }

      case 'iso': {
        const startDayJS = dayjs((date as ISODateRange).startDateISO) || null
        const endDayJS = dayjs((date as ISODateRange).endDateISO) || null

        const result: DatePickerValue = [startDayJS, endDayJS]

        return result
      }
    }
  }

  const transformOutgoingValue = (
    date: DatePickerValue | null
  ): PossibleDateType => {
    if (!date) return null
    switch (mode) {
      case 'firestore-timestamp': {
        const startDate = date?.[0]?.unix() || null
        const endDate = date?.[1]?.unix() || null

        const result: FirestoreTimestampRange = {
          startDate: {
            seconds: startDate || 0,
            nanoseconds: 0,
          },
          endDate: {
            seconds: endDate || 0,
            nanoseconds: 0,
          },
        }

        return result
      }

      case 'iso': {
        const startDate = date?.[0]?.format('YYYY-MM-DD') || null
        const endDate = date?.[1]?.format('YYYY-MM-DD') || null

        const result: ISODateRange = {
          startDateISO: startDate,
          endDateISO: endDate,
        }

        return result
      }
    }
  }

  useEffect(() => {
    dayjs.extend(updateLocale)
    dayjs.updateLocale('en', {
      weekStart: 1,
    })
  }, [])
  return (
    <div style={style}>
      {!!label && (
        <label>
          {label}
          {!required && <span> (optional)</span>}
        </label>
      )}
      <Controller
        control={formMethods.control}
        name={name}
        render={({ field: { ref, value, onChange, ...fieldProps } }) => (
          <ConfigProvider locale={en_GB}>
            <RangePicker
              format="MMM D, YYYY"
              ref={ref}
              value={transformIncomingValue(value)}
              className="range-picker"
              id={{
                start: 'startInput',
                end: 'endInput',
              }}
              showNow={true}
              allowClear={true}
              showWeek={true}
              separator={<ArrowRight size={16} color={'var(--icon)'} />}
              suffixIcon={<Calendar size={16} color={'var(--icon)'} />}
              onChange={(dates) => {
                // This component uses DayJS
                const value = transformOutgoingValue(dates)
                onChange(value)
              }}
            />
          </ConfigProvider>
        )}
      />
    </div>
  )
}

export default DateRangeInput
