import { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { firestore } from '../firebase'
import {
  collection,
  doc,
  getDoc,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from 'firebase/firestore'

import { Workout } from '../types/workouts'
import useCustomFirestoreInfiniteQueryData from './query/useCustomFirestoreInfiniteQueryData'
import { CollectionReference } from 'firebase/firestore'

type Props = {
  activeType: Workout.Item['workoutType'] | 'everything'
}

export const useWorkouts = ({ activeType }: Props) => {
  const { currentSpace } = useContext(AppContext)

  const gymID = currentSpace ? currentSpace.id : 'no-id'

  //sort by title

  const workoutsRef =
    activeType !== 'everything'
      ? query(
          collection(firestore, 'workouts') as CollectionReference<
            Workout.Item,
            Workout.Item
          >,
          where('workoutType', '==', activeType),
          orderBy('title'),
          limit(20)
        )
      : query(
          collection(firestore, 'workouts') as CollectionReference<
            Workout.Item,
            Workout.Item
          >,
          orderBy('title'),
          limit(20)
        )

  const workoutsRaw = useCustomFirestoreInfiniteQueryData<Workout.Item>({
    queryKey: ['workouts', { activeType }],
    query: workoutsRef,
    limit: 20,
  })

  // w.isFe

  const workouts: Workout.Item[] = workoutsRaw.data?.pages
    ? workoutsRaw.data.pages
        .map((page) => page.data)
        .flat()
        .filter((workout: Workout.Item) => {
          if (workout.set && workout.id !== workout.set.parentWorkoutID)
            return false

          return true
        })
    : []

  return {
    workouts: workouts,
    workoutsAreLoading: workoutsRaw.isLoading,
    //fetchNextPage: workoutsRaw.fetchNextPage,
    fetchNextPage: () => {
      if (!workoutsRaw.isFetchingNextPage && workoutsRaw.hasNextPage)
        workoutsRaw.fetchNextPage()
    },
    showNextPageLoader: workoutsRaw.isFetchingNextPage,
    refetch: workoutsRaw.refetch,
  }
}
