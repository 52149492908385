import { Tracking } from '../../../types/types'

import React from 'react'
import { Workout } from '../../../types/workouts'
import { Dropdown as MuiDropdown } from '@mui/base/Dropdown'
import { Menu } from '@mui/base/Menu'
import { MenuButton as BaseMenuButton } from '@mui/base/MenuButton'
import { MenuItem as BaseMenuItem, menuItemClasses } from '@mui/base/MenuItem'
import { styled } from '@mui/system'
import { capitalize } from 'lodash'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import { useMenuButton } from '@mui/base/useMenuButton'
import {
  ArrowLeft,
  ArrowRight,
  ChevronDown,
  ChevronUp,
  ChevronsDown,
  Layers,
} from 'react-feather'
import { useDropdown } from '@mui/base'
import { ControllerFieldState } from 'react-hook-form'

type TrackingTypeDescription = {
  label: string
  id: string | 'divider-1' | 'divider-2' | 'divider-3'
  details?: string
}

type Props = {
  name: string
  value: string
  onChange: (target: any, type?: any) => void
  style?: React.CSSProperties
  fieldState: ControllerFieldState
}

const NoteTypeSelect = ({ value, style, onChange, fieldState }: Props) => {
  const items: TrackingTypeDescription[] = [
    { id: 'note', label: 'Note' },
    { id: 'staff-note', label: 'Staff Note' },
    { id: 'announcement', label: 'Announcement' },
    { id: 'notice', label: 'Notice' },
    { id: 'tip', label: 'Tip' },
    { id: 'information', label: 'Information' },
    { id: 'rules', label: 'Rules' },
    { id: 'custom', label: 'Custom' },
  ]

  const activeItem = items.find((item) => item.label === value) || {
    id: 'custom',
    label: '',
  }

  const error = fieldState.error?.message

  const dropdownButtonRef = React.useRef<HTMLButtonElement>(null)

  return (
    <div style={style}>
      <MuiDropdown>
        <MenuButton ref={dropdownButtonRef}>
          <span>{activeItem.label || 'Custom'}</span>
          <ChevronDown color="var(--accent)" size={20} />
        </MenuButton>

        <Menu slots={{ listbox: Listbox }} style={{ zIndex: 2001 }}>
          {items.map((item) =>
            item.id.includes('divider') ? (
              <Divider key={item.id} />
            ) : (
              <MenuItem
                onClick={() => onChange(item.id === 'custom' ? '' : item.label)}
                key={item.id}
                style={{
                  fontWeight: item.id === activeItem.id ? 'bold' : 'normal',
                }}
              >
                {item.label}
                {item.details && <span>{item.details}</span>}
              </MenuItem>
            )
          )}
        </Menu>
      </MuiDropdown>
      {activeItem.id === 'staff-only' && (
        <span style={{ color: 'var(--secondary-text)', font: 'var(--h6)' }}>
          Won't be visible to athletes
        </span>
      )}
      {activeItem.id === 'custom' && (
        <>
          <label>Custom Category</label>
          <input
            type="text"
            value={value}
            onChange={onChange}
            className={error ? 'error' : ''}
          />
          {error && (
            <span style={{ color: 'var(--accent-red)', font: 'var(--h6)' }}>
              {error}
            </span>
          )}
        </>
      )}
    </div>
  )
}

export default NoteTypeSelect

function MenuSection({ children, label }: MenuSectionProps) {
  return (
    <MenuSectionRoot role="group">
      <MenuSectionLabel>{label}</MenuSectionLabel>
      <ul>{children}</ul>
    </MenuSectionRoot>
  )
}

const Listbox = styled('ul')(
  ({ theme }) => `
      background:red;
      box-sizing: border-box;
      padding: 6px;
      margin: 8px 0;
      right: 0;
      min-width: 200px;
      border-radius: 8px;
      right: 0;
      position: absolute;
      overflow: auto;
      outline: 0px;
      background:  #fff;
      border: 1px solid var(--divider-solid);
      color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      box-shadow: 0px 4px 6px ${
        theme.palette.mode === 'dark'
          ? 'rgba(0,0,0, 0.50)'
          : 'rgba(0,0,0, 0.05)'
      };
      `
)

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E6',
  700: '#0059B3',
  800: '#004C99',
  900: '#003A75',
}

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
}

const Divider = styled('div')(
  ({ theme }) => `
          height: 1px;
          margin: 4px 8px;
          background-color: var(--divider);
      `
)

const MenuItem = styled(BaseMenuItem)(
  ({ theme }) => `
      list-style: none;
      padding: 4px 8px;
      border-radius: 4px;
      cursor: default;
      user-select: none;
      display: flex;
      flex-direction: column;
      

      & > span{
        font: var(--small-text);
        color:var(--secondary-text);
        margin-top:-4px;
      }
    
      &:last-of-type {
        border-bottom: none;
      }
    
      &.${menuItemClasses.focusVisible} {
        outline: 3px solid var(--accent);
        background-color:var(--accent-highlight);
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      }
    
      &.${menuItemClasses.disabled} {
        color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
      }
    
      &:hover:not(.${menuItemClasses.disabled}) {
        background-color: var(--accent-highlight);
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
      }


      
      `
)

const MenuButton = styled(BaseMenuButton)(
  ({ theme }) => `

        font: var(--base);
        background-color: var(--bg-gray);
        height: 48px;
        padding: 0 8px;
        box-sizing: border-box;
        font-size: 16px;
        width: 100%;
        border: 1px solid var(--divider-solid);
        border-radius: 4px;
        outline: none;

      display:flex;
      justify-content:space-between;
      align-items:center;

        &:hover {
            border-color: var(--accent-hover) !important;
        }

        &:focus {
            border-bottom: 3px solid var(--accent);
            padding-top: 2px;
            outline-width: 0;
        }

        &:active {
            border-bottom: 3px solid var(--accent);
            padding-top: 2px;
            outline-width: 0;
        }
    `
)

interface MenuSectionProps {
  children: React.ReactNode
  label: string
}

const MenuSectionRoot = styled('li')`
  list-style: none;

  & > ul {
    padding-left: 0;
  }
`

const MenuSectionLabel = styled('span')`
  display: block;
  padding: 8px 0 5px 8px;
  font: var(--h6);
  color: var(--secondary-text);
  margin-bottom: 4px;
`
