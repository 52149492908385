import { CSSProperties } from 'react'
import styles from './TextInput.module.css'
import { useFormContext, UseFormRegisterReturn } from 'react-hook-form'
import classNames from 'classnames/bind'

const cx = classNames.bind(styles)

type Props = {
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * @param formMethods will override the value and onChange props
   */
  formMethods?: UseFormRegisterReturn<string>
  label?: string
  hintText?: string
  subText?: string
  errorText?: string
  style?: CSSProperties
  placeholder?: string
  centered?: boolean
  isRequired?: boolean
  valuePreviewLabel?: string
}

const TextInput = ({
  label,
  hintText,
  subText,
  errorText,
  style,
  formMethods,
  placeholder,
  centered,
  isRequired,
  value,
  onChange,
  valuePreviewLabel,
}: Props) => {
  const formContext = useFormContext()
  const currentValue = formMethods
    ? formContext.getValues(formMethods.name)
    : value

  return (
    <div style={style} className={cx('text-input')}>
      {label && (
        <label>
          {label}
          {!isRequired && <span> (optional)</span>}
        </label>
      )}
      {
        /**
         * This shows up when input is out of focus. I.e. for a minute valueLabel it will show as "5 min"
         * When user focuses on the input, the input will show just the number, i.e. "5"
         */
        !!valuePreviewLabel && !!currentValue && (
          <div className={cx('value-preview')}>
            {currentValue} {valuePreviewLabel}
          </div>
        )
      }
      <input
        type="text"
        className={cx('input', {
          error: errorText,
          centered,
        })}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...formMethods}
      />
      {errorText && <div className={cx('error-text')}>{errorText}</div>}
      {hintText && !subText && !errorText && (
        <div className={cx('hint-text')}>{hintText}</div>
      )}
      {subText && !errorText && <div className={cx('sub-text')}>{subText}</div>}
    </div>
  )
}

export default TextInput
