import { useContext } from 'react'
import { UserContext } from '../context/UserContext'
import { Result } from '../types/results'
import pluralize from 'pluralize'
import { translateRoundsRestTime } from '../helpers_ts'
import { Workout } from '../types/workouts'
import { Tracking } from '../types/types'
import convertSecondsToTimestamp from '../utilities/convertSecondsToTimestamp'

const useHelper = () => {
  const {
    user: { measureUnits },
    movements,
  } = useContext(UserContext)

  /**
   *  Converts a weight in KGs into user's units of measurement.
   *  If null, returns as zero
   */
  const w = (weightInKG: number | null) => {
    if (!weightInKG) return 0
    if (measureUnits === 'kg') return weightInKG
    else return Math.floor(weightInKG * 2.20462)
  }

  /**
   * Used in workout cards. Translates repetition and time cap values
   * from workout data into a standartized instruction. For example:
   * "In 5 minutes, do as many reps as possible of:"
   */

  type TranslateRepetitionInstruction = {
    expertModeOverride?: boolean
    // comes from Workout.Item
    tracking: Tracking
    timeCap: number | null
    timeCapRound: number | null
    roundRestTime: {
      work: { min: number; sec: number }
      rest: { min: number; sec: number }
      entryValue: string
    }
    rounds: number | null
    repetitionPattern: {
      reps: string[]
      dashReps: boolean
      entryValue: string
    } | null
    isSuperset: boolean
  }

  const translateRepetitionInstruction = ({
    expertModeOverride,
    tracking,
    timeCap,
    timeCapRound,
    roundRestTime,
    rounds,
    repetitionPattern,
    isSuperset,
  }: TranslateRepetitionInstruction): string => {
    /**
     * Do 5 times for as many rounds & reps as possible of
     * As many reps as possible of
     * Do 5 rounds for as many reps as possible of
     * In 10 minutes, do 5 rounds for as many reps as possible of
     * In 10 minutes, do as many reps as possible of
     */

    const expertMode =
      expertModeOverride !== undefined ? expertModeOverride : true

    const repetitionPatternText = translateRepetitionPattern(
      repetitionPattern,
      false
    )
    const repetitionPatternTextRoundsMode = translateRepetitionPattern(
      repetitionPattern,
      true
    )

    const emomTimeCap = (timeCapRound || 0) * (rounds || 0)

    const value = (() => {
      switch (tracking) {
        case 'reps-weight': {
          return repetitionPatternText
        }
        case 'complex': {
          return expertMode
            ? `Do ${rounds} ${pluralize('set', rounds || 0)} of the complex`
            : `Do ${rounds} ${pluralize(
                'set',
                rounds || 0
              )} of the complex. Perform all movements as one unbroken set.`
        }
        case 'time': {
          if (typeof rounds === 'number' && repetitionPatternTextRoundsMode)
            return `${repetitionPatternTextRoundsMode} for time`
          else return `For time`
        }
        case 'total-reps': {
          if (expertMode) {
            if (rounds && rounds > 1) {
              return translateRoundsRestTime(roundRestTime)
                ? `${rounds} rounds of ${translateRoundsRestTime(
                    roundRestTime
                  )}, for total reps`
                : ``
            } else {
              return timeCap
                ? `${timeCap} min AMRAP (for reps)`
                : `AMRAP (for reps)`
            }
          } else if (rounds && rounds > 1) {
            return translateRoundsRestTime(roundRestTime)
              ? `AMRAP: Do ${rounds} rounds of ${translateRoundsRestTime(
                  roundRestTime
                )} for as many reps as possible`
              : ``
          } else {
            return timeCap
              ? `AMRAP: In ${timeCap} min, do as many reps\nas possible`
              : `AMRAP: Do as many reps as possible`
          }
        }
        case 'rounds-reps': {
          return expertMode
            ? timeCap
              ? `${timeCap} min AMRAP (rounds+reps)`
              : `AMRAP (rounds+reps)`
            : timeCap
            ? `AMRAP: In ${timeCap} min, do as many\nrounds & reps as possible`
            : `AMRAP: As many rounds & reps as possible`
        }
        case 'rounds': {
          return expertMode
            ? timeCap
              ? `${timeCap} min AMRAP (for rounds)`
              : `AMRAP (for rounds)`
            : timeCap
            ? `AMRAP: In ${timeCap} min, do as many rounds\nas possible`
            : `AMRAP: Do as many rounds as possible`
        }

        case 'emom': {
          if (!emomTimeCap || !timeCapRound) return ''
          return !expertMode
            ? timeCapRound === 60
              ? `For ${convertSecondsToTimestamp(
                  emomTimeCap,
                  true
                )} minutes, do ${
                  isSuperset ? 'this set' : 'this exercise'
                } at the top of each minute. Rest during the remainder of each minute.`
              : `For ${convertSecondsToTimestamp(
                  emomTimeCap,
                  true
                )} minutes, do ${
                  isSuperset ? 'this set' : 'this exercise'
                } at the top of each ${convertSecondsToTimestamp(
                  timeCapRound,
                  true
                )}-minute interval. Rest during the remainder of each interval.`
            : timeCapRound === 60
            ? `Every minute for ${convertSecondsToTimestamp(
                emomTimeCap,
                true
              )} minutes`
            : `Every ${convertSecondsToTimestamp(
                timeCapRound,
                true
              )} minutes for ${convertSecondsToTimestamp(
                emomTimeCap,
                true
              )} minutes`
        }

        case 'emom-alt': {
          if (!emomTimeCap || !timeCapRound) return ''
          return !expertMode
            ? timeCapRound === 60
              ? ` For ${convertSecondsToTimestamp(
                  emomTimeCap,
                  true
                )} minutes, do an exercise at the top of each minute.\nRest the remainder of the minute. Switch to the next exercise after each minute.`
              : `For ${convertSecondsToTimestamp(
                  emomTimeCap,
                  true
                )} minutes, do an exercise at the top of each ${convertSecondsToTimestamp(
                  timeCapRound,
                  true
                )}-minute interval.\nRest the remainder of the interval. Switch to the next exercise after each interval.`
            : timeCapRound === 60
            ? `Every minute for ${convertSecondsToTimestamp(
                emomTimeCap,
                true
              )} minutes, alternating`
            : `Every ${convertSecondsToTimestamp(
                timeCapRound,
                true
              )} minutes for ${convertSecondsToTimestamp(
                emomTimeCap,
                true
              )} minutes, alternating`
        }

        case 'avg-time':
          return `${rounds} ${pluralize('round', rounds || 0)}, each for time`

        case 'no-score':
        case 'text':
        case 'no-tracking':
          return ''

        // Legacy, deprecated
        case 'weight-used': {
          if (typeof rounds === 'number' && repetitionPatternTextRoundsMode)
            return `${repetitionPatternTextRoundsMode} for load`
          else return `For load`
        }
      }
      return ''
    })()
    return value
  }

  /**
   * Used to translate repetition pattern into human-readable text
   * @param repetitionPattern value used in workout instructions
   * @param roundsMode used when displaying certain workout types, i.e. "21-15-9 reps for time"
   * @returns human-readable text
   */
  const translateRepetitionPattern = (
    repetitionPattern: Workout.Item['repetitionPattern'],
    roundsMode: boolean
  ): string => {
    if (!repetitionPattern) return ''
    const { reps, dashReps } = repetitionPattern

    const setLabel = roundsMode ? 'round' : 'set'

    if (!reps.length) return ''

    if (roundsMode && reps.length === 1)
      // if only one round, don't show it
      return parseInt(reps[0]) === 1 ? '' : `${reps[0]} ${setLabel}s`

    if (!dashReps)
      return `${reps.length} ${pluralize(setLabel, reps.length)}, ${
        reps[0]
      } ${pluralize('rep', parseInt(reps[0]))}`
    else {
      return roundsMode
        ? `${reps.join('–')} reps`
        : `${reps.length} ${pluralize(setLabel, reps.length)}, ${reps.join(
            '–'
          )} reps`
    }
  }

  return { w, translateRepetitionInstruction, translateRepetitionPattern }
}

export default useHelper
