import React from 'react'

type Props = {
  size?: number
}

const IconReps = ({ size = 32 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 6C9.5 5.44772 9.05228 5 8.5 5C7.94772 5 7.5 5.44772 7.5 6H9.5ZM7.5 26C7.5 26.5523 7.94772 27 8.5 27C9.05228 27 9.5 26.5523 9.5 26H7.5ZM14.5 6C14.5 5.44772 14.0523 5 13.5 5C12.9477 5 12.5 5.44772 12.5 6H14.5ZM12.5 27C12.5 27.5523 12.9477 28 13.5 28C14.0523 28 14.5 27.5523 14.5 27H12.5ZM19.5 6C19.5 5.44772 19.0523 5 18.5 5C17.9477 5 17.5 5.44772 17.5 6H19.5ZM17.5 27C17.5 27.5523 17.9477 28 18.5 28C19.0523 28 19.5 27.5523 19.5 27H17.5ZM24.5 7C24.5 6.44772 24.0523 6 23.5 6C22.9477 6 22.5 6.44772 22.5 7H24.5ZM22.5 26C22.5 26.5523 22.9477 27 23.5 27C24.0523 27 24.5 26.5523 24.5 26H22.5ZM7.5 16C7.5 16.5523 7.94772 17 8.5 17C9.05228 17 9.5 16.5523 9.5 16H7.5ZM9.5 22.4133V21.4133H7.5V22.4133H9.5ZM12.5 16.5C12.5 17.0523 12.9477 17.5 13.5 17.5C14.0523 17.5 14.5 17.0523 14.5 16.5H12.5ZM14.5 21.7978V20.7978H12.5V21.7978H14.5ZM17.5 16.5C17.5 17.0523 17.9477 17.5 18.5 17.5C19.0523 17.5 19.5 17.0523 19.5 16.5H17.5ZM19.5 21.1974V20.1974H17.5V21.1974H19.5ZM22.5 16.5C22.5 17.0523 22.9477 17.5 23.5 17.5C24.0523 17.5 24.5 17.0523 24.5 16.5H22.5ZM24.5 20.582V19.582H22.5V20.582H24.5ZM7.5 6V16H9.5V6H7.5ZM7.5 22.4133V26H9.5V22.4133H7.5ZM12.5 6V16.5H14.5V6H12.5ZM12.5 21.7978V27H14.5V21.7978H12.5ZM17.5 6V16.5H19.5V6H17.5ZM17.5 21.1974V27H19.5V21.1974H17.5ZM22.5 7V16.5H24.5V7H22.5ZM22.5 20.582V26H24.5V20.582H22.5Z"
        fill="black"
      />
      <path d="M4 15L28 12V18L4 21V15Z" fill="#16DB70" />
    </svg>
  )
}

export default IconReps
