import React, { useContext, useEffect, useMemo } from 'react'
import { AppContext } from '../../context/AppContext'
import {
  collection,
  query,
  where,
  CollectionReference,
} from 'firebase/firestore'
import { firestore } from '../../firebase'
import { CalendarEvent } from '../../types/calendarEvents'
import getAdjustedDemoDate from '../../utilities/getAdjustedDemoDate'
import useCustomFirestoreQueryData from '../query/useCustomFirestoreQueryData'
import { useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import useCachedCalendarEvents from './useCachedCalendarEvents'
import useCalendar from './useCalendar'
import CalendarContext from '../../context/CalendarContext'
import { UserContext } from '../../context/UserContext'

type Props = {
  dateISO: string | null
  enabled?: boolean
  subscribe?: boolean
}

const useCalendarEvents = ({
  dateISO,
  enabled = true,
  subscribe = false,
}: Props) => {
  const { user } = useContext(UserContext)
  const { currentSpace, currentGymTrack } = useContext(AppContext)
  const gymID = currentSpace?.id ?? 'no-id'
  const trackID = currentGymTrack ?? 'default'

  const isDemo = process.env.REACT_APP_DEMO_GYM_ID === gymID
  const date = isDemo ? getAdjustedDemoDate(dateISO) : dateISO || 'no-date'

  const pinForeverDate = '9999-12-31'

  const ref = query<CalendarEvent.Item, CalendarEvent.Item>(
    collection(
      firestore,
      'spaces',
      gymID,
      'calendar_events'
    ) as CollectionReference<CalendarEvent.Item, CalendarEvent.Item>,
    where('eventDateISO', '==', date),
    where('isDeleted', '==', false),
    where('trackID', '==', trackID)
  )

  const queryKey = ['events', gymID, trackID, date]
  const pinnedForeverEventsQueryKey = ['events', gymID, trackID, pinForeverDate]

  const unpinnedQuery = useCustomFirestoreQueryData<CalendarEvent.Item>({
    queryKey,
    query: ref,
    subscribe: false,
    queryOptions: {
      enabled: gymID !== 'no-id' && enabled && !!dateISO,
      staleTime: 1000 * 60 * 10, // 10 minutes
    },
  })

  const unpinnedEvents =
    unpinnedQuery.data
      ?.filter((item) => !(item.pinOptions?.isPinned === true))
      .sort((a, b) => a.index - b.index) || []

  const pinnedForeverRef = query<CalendarEvent.Item, CalendarEvent.Item>(
    collection(
      firestore,
      'spaces',
      gymID,
      'calendar_events'
    ) as CollectionReference<CalendarEvent.Item, CalendarEvent.Item>,
    where('eventDateISO', '==', pinForeverDate),
    where('isDeleted', '==', false),
    where('trackID', '==', trackID)
  )

  const { data: pinnedForeverEvents, isLoading: pinnedForeverIsLoading } =
    useCustomFirestoreQueryData<CalendarEvent.Item>({
      queryKey: pinnedForeverEventsQueryKey,
      query: pinnedForeverRef,
      subscribe: false,
      queryOptions: {
        enabled: gymID !== 'no-id' && enabled && !!dateISO,
        staleTime: 1000 * 60 * 10, // 10 minutes
      },
    })

  const isLoading = unpinnedQuery.isLoading || pinnedForeverIsLoading
  const isFetched = unpinnedQuery.isFetched

  const { cachedEvents } = useContext(CalendarContext)
  //const cachedEvents: CalendarEvent.Item[] = []

  const pinnedEvents: CalendarEvent.Item[] =
    cachedEvents
      ?.filter((event) => {
        const isPinned = date && event.pinOptions?.isPinned

        const pinWithinRange =
          isPinned &&
          event.pinOptions?.pinDates &&
          DateTime.fromISO(event.pinOptions.pinDates.startDateISO).startOf(
            'day'
          ) <= DateTime.fromISO(date).startOf('day') &&
          DateTime.fromISO(event.pinOptions.pinDates.endDateISO) >=
            DateTime.fromISO(date).startOf('day')

        if (pinWithinRange) return true
        else return false
      })
      .sort((a, b) => a.index - b.index) || []

  return {
    isLoading,
    isFetched,
    unpinnedEvents,
    pinnedEvents: pinnedEvents,
    pinnedForeverEvents:
      pinnedForeverEvents?.sort((a, b) => a.index - b.index) || [],
  }
}

export default useCalendarEvents
