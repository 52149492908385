import React, { useState } from 'react'
import {
  Control,
  Controller,
  FieldError,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form'
import {
  DatePickerProps,
  DatePicker as MUIDatePicker,
} from '@mui/x-date-pickers'
import { DateTime } from 'luxon'
import { FirestoreTimestamp } from '../../../types/types'
import { Firestore } from '@google-cloud/firestore'
import { set } from 'lodash'

type MUIDatePickerComponent = DatePickerProps<any>

type Props = {
  name: string
  label: string
  mode?: 'luxon' | 'firestore-timestamp' | 'iso'
  required?: boolean
  // formMethods: UseFormReturn<any>
  //  isOpen: boolean
  // onBlur: () => void
  // onClick: () => void
  minDate?: DateTime
  maxDate?: DateTime
  MUIProps?: MUIDatePickerComponent
  allowTodaySelection?: boolean
}

const DatePicker = ({
  minDate,
  maxDate,
  name,
  label,
  mode = 'luxon',
  required = false,
  allowTodaySelection = true,
}: // isOpen,
// onBlur,
// onClick,
// MUIProps,
Props) => {
  const { control, getFieldState, setError, clearErrors } = useFormContext()

  const errorMessage = getFieldState(name).error?.message as string

  // Sometimes we provide data in Firestore Timestamp format,
  // sometimes in Luxon DateTime format

  const transformIncomingValue = (
    value: FirestoreTimestamp | DateTime | string | null
  ): DateTime | null => {
    if (!value || value === '9999-12-31') return null
    switch (mode) {
      case 'luxon':
        return value as DateTime
      case 'firestore-timestamp':
        return DateTime.fromSeconds((value as FirestoreTimestamp).seconds)
      case 'iso':
        return DateTime.fromISO(value as string)
    }
  }

  const transformOutgoingValue = (
    value: DateTime | null
  ): FirestoreTimestamp | DateTime | string | null => {
    if (!value) return null
    switch (mode) {
      case 'luxon':
        return value
      case 'firestore-timestamp':
        return { seconds: value.toSeconds(), nanoseconds: 0 }
      case 'iso':
        return value.toISODate()
    }
  }

  const [isOpen, setOpen] = useState(false)
  const handleInputClick = () => {
    setOpen(true)
  }

  const validateValue = (value: string) => {
    if (!value) return false
    const date = transformIncomingValue(value)
    if (date) {
      // at this point, it's a DateTime
      if (!date.isValid) return 'Invalid date'
      if (minDate && date < minDate) return 'Date is too far in the past'
      if (maxDate && date > maxDate) return 'Date is too far in the future'
    }
    return true
  }

  return (
    <>
      {!!label && <label>{label}</label>}
      <Controller
        name={name}
        control={control}
        rules={{
          required: required ? 'This field is required' : false,
          validate: (value) => {
            const result = validateValue(value)
            console.log('result->', result)
            return result
          },
        }}
        render={({ field: { ref, value, onChange, ...fieldProps } }) => (
          <MUIDatePicker
            className={!!errorMessage ? 'error' : ''}
            minDate={minDate}
            maxDate={maxDate}
            open={isOpen}
            // format="MM/dd/yyyy"
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            slotProps={{
              actionBar: {
                actions: allowTodaySelection ? ['today', 'clear'] : ['clear'],
              },
              textField: {
                className: 'date-picker',
                autoComplete: 'off',
                type: 'text',
                onClick: handleInputClick,
              },
              popper: {
                disablePortal: true,
                placement: 'auto-end',
              },
            }}
            sx={{
              width: '100%',
              marginBottom: '8px',
              backgroundColor: 'var(--bg-gray)',
              overflow: 'hidden',
            }}
            value={transformIncomingValue(value)}
            onChange={(date) => {
              onChange(transformOutgoingValue(date))
            }}
            // {...MUIProps}
            {...fieldProps}
          />
        )}
      />
      {!!errorMessage && (
        <div
          style={{
            font: 'var(--h5)',
            color: 'var(--accent-red)',
            marginTop: '-1px',
          }}
        >
          {errorMessage}
        </div>
      )}
    </>
  )
}

export default DatePicker
