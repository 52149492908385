import { v4 as uuid } from 'uuid'
import { Workout } from '../../types/workouts'
import { Timestamp } from 'firebase/firestore'
const getNewWorkout = (
  uid: string,
  type: Workout.Item['tracking']
): Workout.Item => {
  const workoutID = 'wk_' + uuid().slice(0, 8)

  const defaultValues: Workout.Item = {
    id: workoutID,
    playbookReferenceID: null,
    benchmarkID: null,
    title: '',
    workoutType: 'strength',
    set: {
      id: 'set_' + uuid().slice(0, 8),
      parentWorkoutID: workoutID,
      scaling: {
        ageGroup: 'masters',
        genderGroup: 'women',
        effort: 'rx',
      },
    },
    superset: [
      { itemType: 'smart-note', id: 'smart_note_' + uuid().slice(0, 8) },
      {
        itemType: 'exercise',
        movement: { name: '', id: uuid().slice(0, 8) },
        effort: '',
        reps: '',
        id: 'ex_' + uuid().slice(0, 8),
      },
    ],
    repetitionPattern: {
      reps: [],
      dashReps: false,
      entryValue: '',
    },
    roundRestTime: {
      work: { min: 0, sec: 0 },
      rest: { min: 0, sec: 0 },
      entryValue: '',
    },
    rounds: ['emom', 'emom-alt'].includes(type) ? 10 : 1,
    isSuperset: false,
    tracking: type,
    index: 0,
    createdBy: uid,
    createdDate: Timestamp.now(),
    updatedBy: uid,
    updatedDate: Timestamp.now(),
    timeCap: null,
    timeCapRound: 60,
    // isPublished: false,
    isPredefined: false,

    /**
     * @deprecated hideAutomaticDescription
     */
    hideAutomaticDescription: false,
  }

  if (['reps-weight', 'no-score'].includes(type)) {
    return defaultValues
  } else {
    // these workouts most likeky have multiple exercises
    const value: Workout.Item = {
      ...defaultValues,
      workoutType: ['emom-alt', 'emom', 'complex'].includes(type)
        ? 'strength'
        : 'conditioning',
      superset: [
        { itemType: 'smart-note', id: 'smartnote_' + uuid().slice(0, 8) },
        {
          itemType: 'exercise',
          movement: { name: '', id: uuid().slice(0, 8) },
          effort: '',
          reps: '',
          id: 'ex_' + uuid().slice(0, 8),
        },
        {
          itemType: 'exercise',
          movement: { name: '', id: uuid().slice(0, 8) },
          effort: '',
          reps: '',
          id: 'ex_' + uuid().slice(0, 8),
        },
        {
          itemType: 'exercise',
          movement: { name: '', id: uuid().slice(0, 8) },
          effort: '',
          reps: '',
          id: 'ex_' + uuid().slice(0, 8),
        },
      ],
    }
    return value
  }
}

export default getNewWorkout
