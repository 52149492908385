import React from 'react'

type Props = {
  size?: number
}

const IconTextScore = ({ size = 32 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="6"
        y="4"
        width="20"
        height="24"
        rx="3"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M10 10H22"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 14H22"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 18H22"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 22H18"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12 2C12 1.44772 12.4477 1 13 1H19C19.5523 1 20 1.44772 20 2V5C20 5.55228 19.5523 6 19 6H13C12.4477 6 12 5.55228 12 5V2Z"
        fill="black"
      />
    </svg>
  )
}

export default IconTextScore
