import css from './WorkoutAddInput.module.css'
import { matchSorter } from 'match-sorter'

import Downshift from 'downshift'
import { Controller, useForm } from 'react-hook-form'
import { WorkoutShortcut } from '../../../types/workouts'
import { translateWorkoutType } from '../../../helpers_ts'
import { Plus } from 'react-feather'
import { Highlight } from '../../atoms/Highlight/Highlight'
import useMovements from '../../../hooks/useMovements'
import { useWorkoutShortcuts } from '../../../hooks/useWorkoutShortcuts'
import Badge from '../../atoms/Badge/Badge'
import React from 'react'

type Props = {
  onWorkoutAdd: (workout: WorkoutShortcut) => void
}

const WorkoutAddInput = ({ onWorkoutAdd }: Props) => {
  const { shortcuts: workoutShortcuts } = useWorkoutShortcuts()
  const { movements } = useMovements()

  const processedWorkouts = workoutShortcuts.map((workout) => {
    return {
      ...workout,
      movementNames: workout.movementIDs.map((id) => {
        const movement = movements.find((movement) => movement.id === id)
        return movement ? movement.name : ''
      }),
    }
  })

  const { watch, control } = useForm()

  const fieldName = `result`
  const value = watch(fieldName) ? watch(fieldName).name : ''

  const benchmarkMatches =
    value && value.trim().length > 1
      ? matchSorter(processedWorkouts, value.trim().replaceAll('-', ' '), {
          keys: [
            (item) => item.title.replaceAll('-', ' '),
            // (item) =>
            //   item.movementNames.map((name) => name.replaceAll('-', ' ')),
          ],

          threshold: matchSorter.rankings.WORD_STARTS_WITH,
        })
      : []

  const movementMatches =
    value && value.trim().length > 1
      ? matchSorter(movements, value.trim().replaceAll('-', ' '), {
          keys: [(item) => item.name.replaceAll('-', ' ')],
          threshold: matchSorter.rankings.WORD_STARTS_WITH,
        }).map((item) => {
          const value: WorkoutShortcut = {
            id: item.id,
            movementIDs: [item.id],
            title: item.name,
            tracking: 'no-tracking',
            type: 'strength',
          }

          return value
        })
      : []

  const matches = [
    ...movementMatches.slice(0, 4),
    ...benchmarkMatches.slice(0, 4),
  ].sort((a, b) => {
    //no-tracking goes first
    if (a.tracking === 'no-tracking' && b.tracking !== 'no-tracking') return -1
    if (a.tracking !== 'no-tracking' && b.tracking === 'no-tracking') return 1
    else return 0
  })

  const handleSelection = (item: WorkoutShortcut | null) => {
    console.log('Adding', item)
    if (item) onWorkoutAdd(item)
  }

  return (
    <Controller
      control={control}
      name={fieldName + '.name'}
      defaultValue={value}
      render={(controllerMethods) => (
        <Downshift
          inputValue={controllerMethods.field.value}
          onInputValueChange={controllerMethods.field.onChange}
          itemToString={(item) => ''}
          onSelect={handleSelection}
          // onStateChange={handleStateChange}
          defaultHighlightedIndex={0}
        >
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            isOpen,
            inputValue,
            highlightedIndex,
            selectedItem,
            getRootProps,
          }) => {
            return (
              <div className={css['workout-search']}>
                <div {...getRootProps(undefined, { suppressRefError: true })}>
                  <Plus className={css['icon']} size={20} />
                  <input
                    placeholder="Add a workout by title or movement..."
                    {...getInputProps({
                      ref: controllerMethods.field.ref,
                      name: controllerMethods.field.name,
                    })}
                  />
                </div>
                {isOpen ? (
                  <div className={css['options-list']}>
                    <ul {...getMenuProps()}>
                      <>
                        {matches.map((item, index, array) => {
                          const movementListStart =
                            item.type === 'strength' && index === 0

                          const benchmarkListStart =
                            (!array[index - 1] ||
                              array[index - 1].type === 'strength') &&
                            item.type !== 'strength'

                          const firstMovementHighlighted =
                            highlightedIndex === 0

                          const firstBenchmarkHighlighted =
                            highlightedIndex ===
                            array.findIndex((item) => item.type !== 'strength')

                          const itemIsActive = highlightedIndex === index

                          const highlightedItem = highlightedIndex
                            ? array[highlightedIndex]
                            : null

                          const nextItemIsActive =
                            highlightedIndex &&
                            highlightedIndex - 1 === index &&
                            ((item.type === 'strength' &&
                              highlightedItem?.type === 'strength') ||
                              (highlightedItem?.type !== 'strength' &&
                                item.type !== 'strength'))

                          return (
                            <React.Fragment key={item.id}>
                              {movementListStart && (
                                <h5
                                  key="movements-list-header"
                                  style={
                                    firstMovementHighlighted
                                      ? {
                                          borderColor: 'transparent',
                                        }
                                      : {}
                                  }
                                >
                                  Movements
                                </h5>
                              )}
                              {benchmarkListStart && (
                                <h5
                                  key="benchmarks-list-header"
                                  style={
                                    firstBenchmarkHighlighted
                                      ? {
                                          borderColor: 'transparent',
                                        }
                                      : {}
                                  }
                                >
                                  Benchmarks
                                </h5>
                              )}
                              <li
                                {...getItemProps({
                                  // key: item.id,
                                  index: index,
                                  item,
                                  style: {
                                    backgroundColor: itemIsActive
                                      ? 'var(--accent-highlight)'
                                      : '',
                                  },
                                })}
                                className={
                                  itemIsActive
                                    ? css['active']
                                    : nextItemIsActive
                                    ? css['before-active']
                                    : ''
                                }
                              >
                                <div className={css['content']}>
                                  <div className={css['name']}>
                                    {inputValue && (
                                      <Highlight highlight={inputValue}>
                                        {item.title}
                                      </Highlight>
                                    )}
                                    {item.type !== 'strength' && ( // strength means it's a movement and not a benchmark
                                      <Badge style={{ float: 'right' }}>
                                        {translateWorkoutType(item.type)}
                                      </Badge>
                                    )}
                                  </div>
                                </div>
                              </li>
                            </React.Fragment>
                          )
                        })}
                      </>
                    </ul>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            )
          }}
        </Downshift>
      )}
    />
  )
}

export default WorkoutAddInput
