import './App.css'

import { BrowserRouter } from 'react-router-dom'
import { UserContextProvider } from './context/UserContext'
import { AppContextProvider } from './context/AppContext'
import Router from './pages/technical/Router'
import { HotkeysProvider } from 'react-hotkeys-hook'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel'

function App() {
  useEffect(() => {
    ReactPixel.init('766792405064242')
    ReactPixel.track('PageView')
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <HotkeysProvider>
        <UserContextProvider>
          <AppContextProvider>
            <BrowserRouter>
              <Router />
            </BrowserRouter>
          </AppContextProvider>
        </UserContextProvider>
      </HotkeysProvider>
    </LocalizationProvider>
  )
}

export default App
