const convertSecondsToTimestamp = (
  secondsValue: number,
  hideZeroSeconds?: boolean
) => {
  const minutes = Math.floor(secondsValue / 60)
  const seconds = secondsValue % 60

  // Adding a leading zero to seconds
  const secondsString = seconds.toString().length > 1 ? seconds : '0' + seconds

  return seconds === 0 && hideZeroSeconds
    ? minutes.toString()
    : minutes + ':' + secondsString
}

export default convertSecondsToTimestamp
