import React, {
  useEffect,
  useState,
  useRef,
  ReactNode,
  UIEventHandler,
  useContext,
} from 'react'
import css from './CycleBuilder.module.css'
import Button from '../../buttons/Button/Button'
import TitleInput from '../../inputs/TitleInput/TitleInput'
import { X as IconClose, Plus } from 'react-feather'
import SegmentedInput from '../../inputs/SegmentedInput/SegmentedInput'
import { IconSquare } from '../../atoms/Icons/Icons'
import ButtonIcon from '../../buttons/ButtonIcon/ButtonIcon'

import { Controller, FormProvider } from 'react-hook-form'
import Confirm from '../../modals/Confirm'
import CalendarContext from '../../../context/CalendarContext'
import useCycleBuilder from '../../../hooks/cycles/useCycleBuilder'
import { Cycle } from '../../../types/cycles'
import TextAreaInput from '../../inputs/TextAreaInput/TextAreaInput'
import DateRangeInput from '../../inputs/DateRangeInput/DateRangeInput'
import { getRandomCycleName } from '../../../utilities/getRandomCycleName'
import useCyclePublisher from '../../../hooks/cycles/useCyclePublisher'

type Props = {
  cycleToEdit: Cycle | null
  dateSubheader?: ReactNode
  onDismiss: () => void
  isPresetMode?: boolean
}

const CycleBuilder = ({
  cycleToEdit,
  dateSubheader,
  isPresetMode,
  onDismiss,
}: Props) => {
  const { formMethods, isDirty, isValid, publishCycle } = useCycleBuilder({
    cycleToEdit,
    onSuccess: onDismiss,
  })

  const [scrollShadowIsVisible, setScrollShadowIsVisible] = useState(false)

  const handleBuilderScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const scrollTop = (e.target as HTMLElement).scrollTop
    if (!scrollShadowIsVisible && scrollTop > 10) setScrollShadowIsVisible(true)
    else if (scrollShadowIsVisible && scrollTop <= 10)
      setScrollShadowIsVisible(false)
  }

  const [confirmation, setConfirmation] = useState<{
    title: string
    text: string
    what: string
  } | null>(null)

  const {
    pendingActiveDate,
    preventActiveDateChange,
    proceedToPendingDate,
    setPreventActiveDateChange,
  } = useContext(CalendarContext)

  useEffect(() => {
    if (pendingActiveDate && preventActiveDateChange) {
      setConfirmation({
        title: 'Proceed without saving?',
        text: 'All the changes will be lost, like tears in the rain.',
        what: 'switch-date',
      })
    }
  }, [pendingActiveDate])

  const { deleteCycle } = useCyclePublisher()

  const handleDeleteConfirmation = () => {
    if (cycleToEdit) {
      onDismiss()
      deleteCycle(cycleToEdit)
    }
    setConfirmation(null)
  }

  const handleCloseConfirmation = () => {
    onDismiss()
    setConfirmation(null)
    setPreventActiveDateChange(false)
  }

  useEffect(() => {
    if (!cycleToEdit) formMethods.setFocus('title')
  }, [])

  const [titlePlaceholder] = useState(getRandomCycleName())

  return (
    <FormProvider {...formMethods}>
      <Confirm
        isVisible={!!confirmation}
        title={confirmation ? confirmation.title : ''}
        text={confirmation ? confirmation.text : ''}
        buttonLabels={
          confirmation && confirmation.what === 'delete'
            ? { primary: 'Delete', secondary: 'Go Back' }
            : { primary: 'Proceed', secondary: 'Go Back' }
        }
        onDismiss={() => setConfirmation(null)}
        onConfirm={() => {
          if (confirmation)
            switch (confirmation.what) {
              case 'delete':
                return handleDeleteConfirmation()
              case 'close':
                return handleCloseConfirmation()
              case 'switch-date': {
                setPreventActiveDateChange(false)
                proceedToPendingDate()
                handleCloseConfirmation()
              }
            }
        }}
        isDestructive={confirmation?.what === 'delete'}
      />
      <header className={css['header']}>
        <div className={`${css['subheader']} ${'light'}`}>
          {cycleToEdit ? 'Edit Cycle' : 'New Cycle'}
        </div>
        <TitleInput
          editWorkoutMode={true}
          defaultTitle={formMethods.watch('title')}
          isBenchmarkEditMode={false}
          isRequired={true}
          placeholder={titlePlaceholder}
          fieldName="title"
          editTitlePlaceholder="Edit Cycle"
          newTitlePlaceholder="New Cycle"
        />
        <div className={css['close-icon']}>
          <ButtonIcon
            icon={<IconClose />}
            className={css['close-icon']}
            onClick={() =>
              isDirty
                ? setConfirmation({
                    title: 'Proceed without saving?',
                    text: 'All the changes will be lost, like tears in the rain.',
                    what: 'close',
                  })
                : onDismiss()
            }
            type={'light'}
            style={{ margin: '-4px 0 0 0' }}
          />
        </div>

        <div
          className={`${css['scroll-shadow']} ${
            scrollShadowIsVisible && css['visible']
          }`}
        />
      </header>

      <div id={css['workout-builder']} onScroll={handleBuilderScroll}>
        <div className={css['top-items']} style={{ flex: 1, marginTop: '2px' }}>
          <Controller
            name="calendarColor"
            control={formMethods.control}
            render={({ field }) => (
              <SegmentedInput
                value={field.value}
                onChange={(newValue) => field.onChange(newValue)}
                style={{ margin: '4px 0 ' }}
                segments={[
                  {
                    label: '',
                    value: 'green',
                    icon: (
                      <IconSquare
                        color="var(--accent-green)"
                        size={12}
                        outline={false}
                      />
                    ),
                  },
                  {
                    label: '',
                    value: 'red',
                    icon: (
                      <IconSquare
                        color="var(--accent-red)"
                        size={12}
                        outline={false}
                      />
                    ),
                  },
                  {
                    label: '',
                    value: 'blue',
                    icon: (
                      <IconSquare
                        color="var(--accent-blue)"
                        size={12}
                        outline={false}
                      />
                    ),
                  },
                  {
                    label: '',
                    value: 'yellow',
                    icon: (
                      <IconSquare
                        color="var(--accent-yellow)"
                        size={12}
                        outline={false}
                      />
                    ),
                  },
                ]}
              />
            )}
          />
          <DateRangeInput
            formMethods={formMethods}
            name="dateRange"
            label="Dates"
            style={{
              margin: '0px 0 8px',
            }}
          />

          <TextAreaInput
            label="Description"
            name="description"
            placeholder="What is this cycle about?"
            formMethods={formMethods}
            isRequired={'Please add description'}
            characterLimit={2000}
            style={{ minHeight: '200px', flex: '1' }}
          />
        </div>

        <div className={css['bottom-items']}>
          {!isValid ? (
            <p
              className="caption"
              style={{ textAlign: 'center', marginBottom: '4px' }}
            >
              All fields are required
            </p>
          ) : (
            <p
              className="caption"
              style={{ textAlign: 'center', marginBottom: '4px' }}
            >
              &nbsp;
            </p>
          )}
          <Button
            onClick={publishCycle}
            disabled={!isValid}
            className={!isValid ? 'disabled' : undefined}
          >
            {cycleToEdit ? 'Save Cycle' : 'Publish Cycle'}
          </Button>
          {cycleToEdit ? (
            <Button
              onClick={() =>
                setConfirmation({
                  title: 'Delete Cycle?',
                  text: 'This cannot be undone.',
                  what: 'delete',
                })
              }
              type="tertiary-destructive"
              className="tertiary-destructive"
              style={{ marginTop: '16px' }}
            >
              Delete
            </Button>
          ) : (
            ''
          )}
        </div>
      </div>
    </FormProvider>
  )
}

export default CycleBuilder
