import { CalendarEvent } from '../../types/calendarEvents'
import { Movement } from '../../types/movements'
import { Workout } from '../../types/workouts'
import { getNewWorkoutEvent } from './getNewWorkoutEvent'
import { v4 as uuid } from 'uuid'
import getNewWorkout from './getNewWorkout'

const getNewWorkoutEventWithMovement = ({
  gymID,
  trackID,
  movement,
  userID,
  eventDateISO,
  index,
}: {
  movement: Movement
  gymID: string
  trackID: string
  userID: string
  eventDateISO: string
  index: number
}): CalendarEvent.Workout => {
  const newWorkoutData: Workout.Item = {
    ...getNewWorkout(userID, 'reps-weight'),
    superset: [
      {
        itemType: 'exercise',
        id: 'exercise_' + uuid().substring(0, 8),
        movement: {
          id: movement.id,
          name: movement.name,
        },
        reps: '',
        effort: '',
      },
    ],
  }

  const eventDetails: CalendarEvent.Workout = {
    ...getNewWorkoutEvent({
      uid: userID,
      eventDateISO,
      index: index,
      gymID,
      trackID,
      tracking: 'reps-weight',
    }),
    details: newWorkoutData,
  }

  return eventDetails
}

export default getNewWorkoutEventWithMovement
