import React from 'react'
import { CalendarEvent } from '../../../types/calendarEvents'
import WorkoutCard from '../../cards/WorkoutCard/WorkoutCard'
import NoteCard from '../../cards/NoteCard/NoteCard'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import {
  closestCenter,
  DndContext,
  DragOverlay,
  MouseSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import css from './EventCards.module.css'
import classNames from 'classnames/bind'
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers'
import useCalendarEventSorter from '../../../hooks/calendar/useCalendarEventSorter'

const cx = classNames.bind(css)

type Props = {
  events: CalendarEvent.Item[]
  handleCardAction: (action: string, item: CalendarEvent.Item) => void
}

const EventCards = ({ events: currentEvents, handleCardAction }: Props) => {
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } })
  )

  const { draggedItem, tempSortedEvents, handleDragStart, handleDragEnd } =
    useCalendarEventSorter(
      currentEvents //.map((item, index) => ({ ...item, index })) || [] //sometimes indexes can get messed up because note was pinned and index was not updated
    )

  const events = tempSortedEvents || currentEvents
  const canSort = events.length > 1

  return (
    <div className={cx('events-container')}>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        <SortableContext
          items={currentEvents}
          strategy={verticalListSortingStrategy}
        >
          {events.map((item, index) =>
            item.type === 'workout' ? (
              <WorkoutCard
                key={item.id}
                id={item.id}
                eventData={item}
                workoutData={item.details}
                canSort={canSort}
                onAction={(action) => handleCardAction(action, item)}
              />
            ) : (
              <NoteCard
                key={item.id}
                data={item}
                canSort={canSort}
                onAction={(action) => handleCardAction(action, item)}
              />
            )
          )}
        </SortableContext>

        <DragOverlay>
          {draggedItem ? (
            draggedItem.type === 'workout' ? (
              <WorkoutCard
                key={draggedItem.id}
                id={draggedItem.id}
                workoutData={draggedItem.details}
                eventData={draggedItem}
                canSort={events.length > 1}
                onAction={() => {}}
                isDragOverlay={true}
              />
            ) : (
              <NoteCard
                key={draggedItem.id}
                data={draggedItem}
                onAction={() => {}}
              />
            )
          ) : null}
        </DragOverlay>
      </DndContext>
    </div>
  )
}

export default EventCards
