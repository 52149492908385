import React from 'react'

type Props = {
  size?: number
  style?: React.CSSProperties
}

const IconRepsWeight = ({ size = 32, style }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <g clipPath="url(#clip0_6164_6697)">
        <rect
          x="1.71136"
          y="21.3033"
          width="3"
          height="12"
          rx="1"
          transform="rotate(-45 1.71136 21.3033)"
          fill="#16DB70"
        />
        <rect
          x="4.53979"
          y="18.4749"
          width="3"
          height="12"
          rx="1"
          transform="rotate(-45 4.53979 18.4749)"
          fill="#16DB70"
        />
        <rect
          x="16.5606"
          y="6.4541"
          width="3"
          height="12"
          rx="1"
          transform="rotate(-45 16.5606 6.4541)"
          fill="#16DB70"
        />
        <rect
          x="19.389"
          y="3.62567"
          width="3"
          height="12"
          rx="1"
          transform="rotate(-45 19.389 3.62567)"
          fill="#16DB70"
        />
        <path
          d="M1.71138 28.3744C1.32085 27.9839 1.32085 27.3507 1.71138 26.9602L3.8327 24.8389L6.66113 27.6673L4.53981 29.7886C4.14928 30.1791 3.51612 30.1791 3.12559 29.7886L1.71138 28.3744Z"
          fill="black"
        />
        <path
          d="M25.0459 3.62567L27.1672 1.50435C27.5577 1.11383 28.1909 1.11383 28.5814 1.50435L29.9956 2.91856C30.3862 3.30909 30.3862 3.94225 29.9956 4.33278L27.8743 6.4541L25.0459 3.62567Z"
          fill="black"
        />
        <rect
          x="10.9037"
          y="19.182"
          width="12"
          height="2"
          transform="rotate(-45 10.9037 19.182)"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_6164_6697">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconRepsWeight
