import {
  Category,
  Equipment,
  Force,
  Level,
  Mechanic,
  Movement,
  Muscle,
} from '../types/movements'
import { firestore } from '../firebase'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { doc } from 'firebase/firestore'
import useCustomFirestoreDocumentData from './query/useCustomFirestoreDocumentData'

const useMovements = () => {
  const movementsRef = doc(firestore, 'movements', 'movements_1')

  const movementsQuery = useCustomFirestoreDocumentData({
    queryKey: ['movements', movementsRef],
    docRef: movementsRef,
    subscribe: true,
  })

  const movements = movementsQuery.data?.list.length
    ? (movementsQuery.data.list as Movement[])
    : []

  const getMovementByID = (id: string | null) => {
    return id === 'new'
      ? getNewMovement()
      : (movementsQuery.data?.list.find(
          (movement: Movement) => movement.id === id
        ) as Movement) || null
  }

  const [activeMovementID, setActiveMovementID] = useState<string | null>(null)
  const activeMovement = getMovementByID(activeMovementID)

  const form = useForm({
    defaultValues: activeMovement
      ? {
          ...activeMovement,
          name: activeMovement.name,
          primaryMuscles: createMusclePresenceObject(
            activeMovement?.primaryMuscles || []
          ),
          secondaryMuscles: createMusclePresenceObject(
            activeMovement?.secondaryMuscles || []
          ),
          // ... do this for other fields if needed ...
        }
      : undefined,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  })

  const handleActiveMovementChange = (movement: Movement) => {
    const primaryMuscles = createMusclePresenceObject(movement.primaryMuscles)
    const secondaryMuscles = createMusclePresenceObject(
      movement.secondaryMuscles
    )

    form.reset({ ...movement, primaryMuscles, secondaryMuscles })
    setActiveMovementID(movement.id)
  }

  const muscleFields = (type: 'primary' | 'secondary') => {
    return Object.keys(Muscle).reduce((fields, muscle) => {
      fields[Muscle[muscle as keyof typeof Muscle]] = form.register(
        `${type}Muscles.${Muscle[muscle as keyof typeof Muscle]}`
      )
      return fields
    }, {} as Record<keyof typeof Muscle, ReturnType<typeof form.register>>)
  }

  const fields = {
    name: form.register('name', {
      required: true,
      onChange: () => {
        if (form.formState.errors.name) form.clearErrors('name')
      },
      validate: (value) => validate('name', value),
    }),
    primaryMuscles: muscleFields('primary'),
    secondaryMuscles: muscleFields('secondary'),
    force: form.register('force'),
    level: form.register('level'),
    mechanic: form.register('mechanic'),
    equipment: form.register('equipment'),
    category: form.register('category'),
  }

  // const mutation = useMutation({
  //   mutationFn: API.postMovement,
  //   onSuccess: () => {
  //     console.log('success')
  //   },
  // })

  const onSubmit = async (data: any) => {
    // const processedData: Movement = {
    //   ...data,
    //   name: data.name.trim(),
    //   primaryMuscles: extractTrueMuscles(data.primaryMuscles),
    //   secondaryMuscles: extractTrueMuscles(data.secondaryMuscles),
    //   updatedDate: Date.now(),
    // }
    // await API.postMovement(processedData)
    // setActiveMovementID(null)
    // onSuccess('Movement saved')
  }

  const handleDelete = () => {
    // API.deleteMovement(activeMovementID)
    // // setActiveMovementID(null)
    // onSuccess('Movement deleted')
  }

  const handleCreateNew = () => {
    const newMovement = getNewMovement()
    handleActiveMovementChange(newMovement)
    setTimeout(() => form.setFocus('name'), 100)
  }

  const handleDupliacte = () => {
    handleActiveMovementChange({
      ...activeMovement,
      id: 'new',
      name: `${activeMovement.name} (1)`,
    })
    setTimeout(() => form.setFocus('name'), 100)
  }

  const validate = (key: 'name', value: any) => {
    console.log('validating', key, value)
    switch (key) {
      case 'name':
        const foundMovement = movements?.find(
          (w) => w.name.toLowerCase() === value.toLowerCase().trim()
        )
        return foundMovement
          ? foundMovement.id !== activeMovementID
            ? 'Movement name already exists'
            : true
          : true
      default:
        return true
    }
  }

  return {
    movements,
    movementsAreLoading: movementsQuery.isLoading,
    handleActiveMovementChange,
    activeMovement,
    fields,
    errors: form.formState.errors,
    handleSubmit: form.handleSubmit(onSubmit),
    handleDelete,
    handleCreateNew,
    handleDupliacte,
    getMovementByID,
  }
}

export default useMovements

const getNewMovement = (): Movement => {
  const defaultValues: Movement = {
    name: '',
    id: 'new',
    aliases: [],
    primaryMuscles: [],
    secondaryMuscles: [],
    force: Force.push, // or a default value if applicable
    level: Level.beginner, // default value, change as necessary
    mechanic: Mechanic.isolation, // or a default value if applicable
    equipment: Equipment.body, // or a default value if applicable
    category: Category.strength, // default value, change as necessary
    updatedDate: Date.now(), // or another default value
  }

  return defaultValues
}

export const createMusclePresenceObject = (
  muscles: string[]
): Record<Muscle, boolean> => {
  return Object.values(Muscle).reduce((acc, muscle) => {
    acc[muscle] = muscles.includes(muscle)
    return acc
  }, {} as Record<Muscle, boolean>)
}

export const extractTrueMuscles = (
  musclePresence: Record<Muscle, boolean>
): string[] => {
  return Object.entries(musclePresence)
    .filter(([muscle, present]) => present)
    .map(([muscle]) => muscle)
}
