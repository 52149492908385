import React from 'react'

type Props = {}

const IconNote = (props: Props) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 6L24 6C25.6569 6 27 7.34315 27 9V17V20.3431C27 21.1388 26.6839 21.9019 26.1213 22.4645L21.4645 27.1213C20.9019 27.6839 20.1388 28 19.3431 28H18H8C6.34315 28 5 26.6569 5 25V9C5 7.34315 6.34315 6 8 6Z"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M10 11H22"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 15H22"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M10 19H16"
        stroke="#16DB70"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M19 27V23C19 21.3431 20.3431 20 22 20H26"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  )
}

export default IconNote
