import { doc, DocumentData, getDoc } from 'firebase/firestore'
import { useContext, useEffect, useRef, useState } from 'react'
import { auth, firestore } from '../firebase'
import { OtherUserData, UserData } from '../types/types'
import API from '../services'
import { useQuery } from '@tanstack/react-query'
import { UserContext } from '../context/UserContext'
import { useAuth } from './query/useAuth'

export interface UserDataByUID {
  [userID: string]: OtherUserData
}

interface Props {
  userIDs: string[] | null
}

export const useUsersData = ({ userIDs }: Props) => {
  /**
   * @remarks
   * Takes an array of user IDs, and optionally date of data update
   * and returns an object with user IDs as keys and user data as value
   */

  const { userID: currentUserID, user: currentUser } = useContext(UserContext)

  /**
   * 1. Remove duplicate user IDs
   * 2. Remove undefined values
   */

  const processedUserIDs = userIDs
    ? Array.from(new Set(userIDs)).filter((userID): userID is string => {
        const isString = typeof userID === 'string'
        return isString
      })
    : []

  //const { data: authData } = useAuthUser(['user'], auth) // without the auth token we cannot load other people's data
  const { user } = useAuth()

  const query = useQuery({
    queryKey: ['usersData', processedUserIDs, user],
    queryFn: () => API.getUsersData(processedUserIDs),
    enabled: !!user && !!processedUserIDs.length,
  })

  let structuredData: UserDataByUID = {}

  if (query.data && query.data.length)
    query.data.forEach((user: OtherUserData) => {
      if (user) structuredData[user.uid] = user
    })

  const usersData = Object.keys(structuredData).length ? structuredData : null
  const usersDataIsLoading = !query.data?.length && query.isLoading

  return {
    usersData,
    usersDataIsLoading,
  }
}
