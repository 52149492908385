import {
  useReducer,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'

import { analytics, auth, firestore } from '../firebase'
import { collection, doc, query, where } from 'firebase/firestore'

import { DocumentData } from '@google-cloud/firestore'
import { UserData } from '../types/types'
import { AuthError, User } from 'firebase/auth'
import { Result } from '../types/results'
import { setUserId, setUserProperties } from 'firebase/analytics'
import { Analytics } from '../services/analytics'
import { useAuth } from '../hooks/query/useAuth'
import useCustomFirestoreDocumentData from '../hooks/query/useCustomFirestoreDocumentData'
import { queryOptions } from '@tanstack/react-query'

//import { Analytics } from '../analytics'

interface UserContext {
  user: UserData
  userID: string
  userIsLoading: boolean
  movements: Result.Reference.MappedMovements | null
}

const def: UserContext = {
  user: {
    // public
    gender: 'other',
    name: '',
    photoURL: '',
    uid: '',

    // private
    ageGroup: 'masters',
    defaultGym: {
      gymID: 'no-id',
      trackID: 'no-id',
    },
    introComplete: false,
    spaceAccess: null,
    measureUnits: 'lbs',
    introMode: null,
    authProvider: '',
    badgeCount: 0,
    email: '',
    emailVerified: false,
    pushToken: '',
    subscriptions: [''],
    onboardingTasks: {
      firstGymAdded: false,
      genderSelected: false,
      unitsSelected: false,
      unitsTipSeen: false,
    },
  },
  userID: 'no-id',
  userIsLoading: true,
  movements: null,
}

export const UserContext = createContext({
  // hasLoaded differentiates placeholder data from real data
  ...def,
})

export const UserContextProvider = ({ children }: any) => {
  //const authUser = useAuthUser(['user'], auth)

  const { user: authUser, loading: authLoading } = useAuth()

  const [userIsLoading, setUserIsLoading] = useState(true)

  const userID = authUser ? (authUser.uid ? authUser.uid : '') : ''

  const userDataRef = doc(firestore, 'users', userID || 'no-id')

  const userQuery = useCustomFirestoreDocumentData({
    queryKey: ['user', userID],
    docRef: userDataRef,

    subscribe: true,

    queryOptions: { enabled: userID !== 'no-id' },
  })

  const userData = userQuery.data

  const userMovementsQuery = doc(
    firestore,
    'users',
    userID || 'no-id',
    'results',
    'all_results'
  )

  const uMovementsRaw = useCustomFirestoreDocumentData({
    queryKey: ['results', userID],
    docRef: userMovementsQuery,
    subscribe: true,
    queryOptions: {
      enabled: userID !== 'no-id',
    },
  })

  const uMovements = uMovementsRaw.data as Result.Reference.MappedMovements

  useEffect(() => {
    if (userIsLoading) {
      if (
        (!authLoading && !authUser) ||
        (authUser && !userQuery.isLoading && !uMovementsRaw.isLoading)
      ) {
        setUserIsLoading(false)
        if (userID) {
          setUserId(analytics, userID)
          setUserProperties(analytics, {
            gender: userData && userData.gender ? userData.gender : 'not set',
            hasProfilePicture: userData && userData.photoURL ? 'yes' : 'no',
            introComplete: userData && userData.introComplete ? 'yes' : 'no',
            measureUnits:
              userData && userData.measureUnits
                ? userData.measureUnits
                : 'not set',
          })
        }
      }
    }
  }, [authLoading, authUser, userData])

  useEffect(() => {
    if (userID && userData) {
      Analytics._identifyUser(userID, userData)
    }
  }, [userID, userData])

  const value: UserContext =
    !userIsLoading && userData
      ? {
          user: {
            // public
            gender: userData?.gender || def.user.gender,
            name: userData?.name || def.user.name,
            photoURL: userData?.photoURL || def.user.photoURL,
            uid: userData?.uid || def.user.uid,
            ageGroup: userData?.ageGroup || def.user.ageGroup,
            introComplete: userData?.introComplete || def.user.introComplete,
            spaceAccess: userData?.spaceAccess || def.user.spaceAccess,
            measureUnits: userData?.measureUnits || def.user.measureUnits,
            introMode: userData?.introMode || def.user.introMode,
            authProvider: userData?.authProvider || def.user.authProvider,
            badgeCount: userData?.badgeCount || def.user.badgeCount,
            defaultGym: userData?.defaultGym || def.user.defaultGym,
            email: userData?.email || def.user.email,
            emailVerified: authUser?.emailVerified || def.user.emailVerified,
            pushToken: userData?.pushToken || def.user.pushToken,
            subscriptions: userData?.subscriptions || def.user.subscriptions,
            onboardingTasks:
              userData?.onboardingTasks || def.user.onboardingTasks,
          },
          userID: userID,
          userIsLoading: userIsLoading,
          movements: uMovements || def.movements,
        }
      : userID && !userData // this happens when user just authed
      ? { ...def, userIsLoading: true }
      : { ...def, userIsLoading }

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

export default UserContext
