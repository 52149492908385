import React, { useContext } from 'react'
import { AppContext } from '../context/AppContext'
import { Workout, WorkoutShortcut } from '../types/workouts'
import { get, snakeCase } from 'lodash'
import { v4 as uuid } from 'uuid'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from 'firebase/firestore'
import { firestore } from '../firebase'
import { UserContext } from '../context/UserContext'
import { useQueryClient, useMutation, QueryKey } from '@tanstack/react-query'
import API from '../services'
import { getNewWorkoutEvent } from '../utilities/workout builder/getNewWorkoutEvent'
import { Analytics } from '../services/analytics'
import { CalendarEvent } from '../types/calendarEvents'
import useCachedCalendarEvents from './calendar/useCachedCalendarEvents'

export const useWorkoutPublisher = () => {
  const { currentSpace, currentGymTrack } = useContext(AppContext)
  const { userID } = useContext(UserContext)

  const optimisticMutation = useOptimisticPostWorkout()
  const mutation = usePostWorkout()
  const { getCachedEventsForDate } = useCachedCalendarEvents()

  const submitCalendarEvents = (
    calendarEvents: CalendarEvent.Item[],
    suppressOptimism?: boolean
  ) => {
    if (!suppressOptimism) optimisticMutation.mutate(calendarEvents)
    else mutation.mutate(calendarEvents)
  }

  const submitWorkout = async (
    {
      /**
       * @param isEditMode - use when work with existing data, for example when adding a preset or editing a workout
       */
      workoutData,
      setItems,
      isEditMode,
      onSuccess,
      eventData,
      newEventDateISO,
      isPresetCreationMode,
      isPresetAdditionMode,
      publishStatus,
      index,
    }: {
      workoutData: Workout.Item
      setItems: Workout.Item[]
      eventData?: CalendarEvent.Workout | null
      newEventDateISO?: string | null
      isEditMode?: boolean
      isPresetCreationMode?: boolean
      isPresetAdditionMode?: boolean
      onSuccess?: () => void
      publishStatus: boolean
      index: number
    } // format: YYYY-MM-DD}
  ) => {
    // 1. Updating setItems with newWorkoutData and
    // 2. Synchronizing titles and
    // 3. Creating a new parentID based on title
    // 4. Updating parent IDs
    // 5. Submitting all workouts from it

    if (!currentSpace) return console.error('No currentSpace') // never happens

    let newParentID = ''
    const parentWorkout =
      setItems.find((item) => item.id === workoutData.set?.parentWorkoutID) ||
      workoutData

    if (!eventData && !newEventDateISO)
      return console.error('No event data or date provided')

    const submitEventData = eventData
      ? { ...structuredClone(eventData), isPublished: publishStatus }
      : newEventDateISO
      ? {
          ...getNewWorkoutEvent({
            uid: userID,
            gymID: currentSpace?.id || 'no-id',
            trackID: currentGymTrack || 'default',
            eventDateISO: newEventDateISO,
            index,
            tracking: workoutData.tracking,
          }),
          isPublished: publishStatus,
        }
      : null

    if (!submitEventData) return console.error('No event was added')

    // const processedEventData: RewodLegacyEvent.Data = {
    //   ...eventData,
    //   eventItems: eventData.eventItems.filter(
    //     (item) => parentWorkout.id !== item.id // Only parent workout will be added to the event items
    //   ),
    // }

    const processedSetItems = setItems
      // Replacing the incoming workout in setItems with the relevant data
      .map((item) => (item.id === workoutData.id ? workoutData : item))
      .map((item) => {
        //If creating a new workout, making new set of IDs based on title
        const currentID =
          isEditMode || isPresetAdditionMode // we need change the original IDs for preset that being added
            ? item.id
            : 'wk_' +
              snakeCase(item.title).slice(0, 8) +
              '_' +
              uuid().slice(0, 8)

        const playbookReferenceID = isPresetCreationMode
          ? currentID
          : item.playbookReferenceID

        // Syncincing titles & publish status
        // We take it from the incoming workoutData because
        // it's the most recent one
        const title = workoutData.title
        const isPublished = submitEventData.isPublished

        // Storing the new parentID for to reassign in next step
        if (item.id === parentWorkout.id) {
          newParentID = currentID
        }
        return {
          ...item,
          playbookReferenceID,
          id: currentID,
          title,
          isPublished,
        }
      })
      .map((item) =>
        isEditMode
          ? item
          : {
              ...item,
              ...(isPresetCreationMode && { benchmarkID: newParentID }),
              ...(item.set && {
                // Only add the 'set' property if item.set is defined
                set: {
                  ...item.set,
                  parentWorkoutID: newParentID,
                  id: item.set.id,
                },
              }),
            }
      )

    const processedWorkouts: Workout.Item[] = processedSetItems
      ? processedSetItems.map((workout) => {
          const processedSuperset: Workout.Item['superset'] = []

          const repetitioFallback = workout.repetitionPattern?.entryValue
            ? workout.repetitionPattern
            : {
                reps: [],
                dashReps: false,
                entryValue: '',
              }

          workout.superset.forEach((item) => {
            if (
              // removing empty movements and notes
              !(
                (item.itemType === 'exercise' &&
                  item.movement.name.trim() === '') ||
                (item.itemType === 'note' && item.note.trim() === '') ||
                (item.itemType === 'staff-note' && item.note.trim() === '')
              )
            )
              processedSuperset.push(item)
          })

          const processedData: Workout.Item = {
            ...workout,
            repetitionPattern: repetitioFallback,
            superset: processedSuperset,
          }

          if (workout.id === workout.set?.parentWorkoutID || !workout.set) {
            console.log('pushing workout', workout)
            submitEventData.details = processedData
          } else {
            console.log("didn't push", workout)
          }

          return processedData
        })
      : []

    if (isPresetCreationMode) {
      try {
        API.postBenchmarkWorkout(processedWorkouts, currentSpace.id)
      } catch (err) {
        console.error(err)
      }
    } else optimisticMutation.mutate([submitEventData])

    if (isEditMode && currentSpace)
      Analytics._logEvent({
        name: 'workout_edited',
        params: {
          gymID: currentSpace.id,
          eventID: submitEventData.id,
          eventItemID: parentWorkout.id,
        },
      })
    else
      Analytics._logEvent({
        name: 'workout_created',
        params: {
          gymID: currentSpace.id,
          eventID: submitEventData.id,
          eventItemID: parentWorkout.id,
        },
      })
  }

  const deleteWorkoutEvent = async (eventData: CalendarEvent.Item) => {
    if (!currentSpace) return console.error('No currentSpace') // never happens

    const dateISO = eventData.eventDateISO
    const dayEvents = getCachedEventsForDate(dateISO)

    // Updating indexes for remaining events before deletion
    const updatedDayEvents = dayEvents
      .filter((ev) => ev.id !== eventData.id)
      .map((ev, index) => ({ ...ev, index }))

    try {
      optimisticMutation.mutate([
        ...updatedDayEvents,
        { ...eventData, isDeleted: true },
      ])
    } catch (err) {
      console.error(err)
    }
  }

  const addPredefinedWorkout = async ({
    workoutShortcut,
    eventDateISO,
    index,
  }: // format: YYYY-MM-DD
  {
    workoutShortcut: WorkoutShortcut | null
    eventDateISO: string
    index: number
  }) => {
    // if (workoutShortcut === null)
    //   return console.error('Workout shortcut is null')

    if (!workoutShortcut)
      return console.error('Workout shortcut is not provided')

    const workoutRef = doc(firestore, 'workouts', workoutShortcut.id)

    const workoutData = await getDoc(workoutRef).then((doc) => {
      if (doc.exists()) return doc.data() as Workout.Item
      else return null
    })

    if (!workoutData) return console.error('Workout data not found')

    const processedItem: Workout.Item = {
      ...workoutData,
      id: workoutData.id + '_' + uuid().slice(0, 8),
    }

    const eventData: CalendarEvent.Workout = {
      ...getNewWorkoutEvent({
        gymID: currentSpace?.id || 'no-id',
        trackID: currentGymTrack || 'default',
        uid: userID,
        eventDateISO,
        index,
        tracking: processedItem.tracking,
      }),
      details: processedItem,
      isPublished: true,
    }

    submitWorkout({
      workoutData: processedItem,
      setItems: [processedItem],
      isPresetAdditionMode: true,
      eventData: eventData,
      publishStatus: true,
      index,
    })
  }

  const publishDrafts = (unpublishedEvents: CalendarEvent.Item[]) => {
    submitCalendarEvents(
      unpublishedEvents.map((ev) => ({ ...ev, isPublished: true }))
    )
  }

  return {
    submitCalendarEvents,
    addPredefinedWorkout,
    submitWorkout,
    deleteWorkoutEvent,
    publishDrafts,
  }
}

/**
 *  This mutation might be used in the following scenarios:
 *  1.  Mutating a single workout
 *  2.  Mutating multiple workouts at the same time: for example, when rearraning
 *      workouts in a single day, we will need to change indexes for all.
 *  3.  Mutating multiple workouts in different days: for example, when moving a
 *      workout from one day to another.
 *  That is why we need to work with multiple queries: workouts from different months
 *  will have different query keys
 */
const useOptimisticPostWorkout = () => {
  const queryClient = useQueryClient()
  const { currentSpace, currentGymTrack } = useContext(AppContext)
  const gymID = currentSpace?.id ?? 'no-id'
  const trackID = currentGymTrack || 'default'

  return useMutation({
    mutationFn: (newEventsData: CalendarEvent.Item[]) =>
      API.postEvent(newEventsData, gymID),

    // When mutate is called:
    onMutate: async (newEventsData) => {
      // We need to invalidate all queries that are related to the events
      // (so they don't overwrite our optimistic update)

      let previousData = []

      const queryKeys = newEventsData.map((ev) =>
        getQueryKeyForEvent(ev, gymID)
      )

      console.log('queryKeys', queryKeys)

      for (const queryKey of queryKeys) {
        console.log('updating', queryKey)

        await queryClient.cancelQueries({ queryKey })

        // Snapshot the previous value
        const previousEvents = queryClient.getQueryData(queryKey)

        // Optimistically update to the new value
        queryClient.setQueryData<CalendarEvent.Item[]>(
          queryKey,
          (old: CalendarEvent.Item[] | undefined) => {
            const optimisticlyUpdatedData = old ? [...old] : []
            newEventsData.forEach((ev) => {
              const index = optimisticlyUpdatedData.findIndex(
                (obj) => obj.id === ev.id
              )

              if (index !== -1) {
                // If the object is found in the array, replace it
                optimisticlyUpdatedData[index] = ev
              } else {
                // If the object is not found in the array, append it
                optimisticlyUpdatedData.push(ev)
              }
            })

            return optimisticlyUpdatedData.filter((ev) => !ev.isDeleted)
          }
        )

        previousData.push(previousEvents)
      }

      // Return a context object with the snapshotted value
      return { previousData }
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newEventsData, context) => {
      if (context) {
        context.previousData.forEach((prev, index) => {
          queryClient.setQueryData(
            getQueryKeyForEvent(newEventsData[index], gymID),
            prev
          )
        })
      }
      console.error('err', err)
    },
    onSettled: (data, error, newEventsData) => {
      // Never works properly
      // const queryKeys = newEventsData.map((ev) =>
      //   getQueryKeyForEvent(ev, gymID)
      // )
      // for (const queryKey of queryKeys) {
      //   queryClient.invalidateQueries({ queryKey })
      // }
    },
  })
}

const usePostWorkout = () => {
  const queryClient = useQueryClient()
  const { currentSpace } = useContext(AppContext)
  const gymID = currentSpace?.id ?? 'no-id'

  return useMutation({
    mutationFn: (newEventsData: CalendarEvent.Item[]) =>
      API.postEvent(newEventsData, gymID),

    onSettled: (data, error, newEventsData) => {
      // Never works properly
      // const queryKeys = newEventsData.map((ev) =>
      //   getQueryKeyForEvent(ev, gymID)
      // )
      // for (const queryKey of queryKeys) {
      //   queryClient.invalidateQueries({ queryKey })
      // }
    },
  })
}

export const getQueryKeyForEvent = (ev: CalendarEvent.Item, gymID: string) => {
  return ['events', gymID, ev.trackID, ev.eventDateISO] //['events', gymID, trackID, date]
}
