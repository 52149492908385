import React from 'react'

type Props = {
  size?: number
  color?: string
  style?: React.CSSProperties
}

const IconPin = ({ size = 12, color = 'black', style }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 12 12`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.43932 5.43932C6.02511 4.85354 6.02511 3.90379 5.43932 3.318C4.85354 2.73222 3.90379 2.73222 3.318 3.318C2.73222 3.90379 2.73222 4.85354 3.318 5.43932C3.90379 6.02511 4.85354 6.02511 5.43932 5.43932ZM6.14643 2.6109C7.00166 3.46613 7.10772 4.78683 6.46462 5.75751L9.68197 8.97486L10.3891 9.68197L10.3891 9.68199V10.3891H9.682L9.68197 10.3891L8.97487 9.68197L5.75752 6.46462C4.78683 7.10772 3.46613 7.00166 2.6109 6.14643C1.63458 5.17012 1.63458 3.58721 2.6109 2.6109C3.58721 1.63458 5.17012 1.63458 6.14643 2.6109Z"
        fill={color}
      />
    </svg>
  )
}

export default IconPin
