import React, { CSSProperties, useEffect } from 'react'
import classNames from 'classnames/bind'
import styles from './NumberInput.module.css'
import TextInput from '../TextInput/TextInput'
import { Plus as IconPlus, Minus as IconMinus } from 'react-feather'
import { useFormContext } from 'react-hook-form'
import pluralize, { plural } from 'pluralize'

const cx = classNames.bind(styles)

type Props = {
  label?: string
  hintText?: string
  subText?: string
  errorText?: string
  style?: CSSProperties
  name: string
  min?: number
  max?: number
  disabled?: boolean
  hideButtons?: boolean
  className?: string
  placeholder?: string
  emptyIsMin?: boolean
  preventFractions?: boolean
  isRequired?: boolean
  valuePreviewLabel?: string
}

const NumberInput = ({
  label,
  hintText,
  subText,
  errorText,
  style,
  name,
  min,
  max,
  disabled,
  hideButtons,
  className,
  placeholder,
  emptyIsMin,
  preventFractions,
  isRequired,
  valuePreviewLabel,
}: Props) => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()
  const value = watch(name)

  const stepUp = () => {
    if (!disabled && value !== max)
      if (Number.isNaN(value)) setValue(name, 1)
      else setValue(name, value + 1)
  }
  const stepDown = () => {
    if (!disabled && value !== min)
      if (Number.isNaN(value)) setValue(name, 1)
      else setValue(name, value - 1)
  }

  useEffect(() => {
    console.log('value', value)
    if (Number.isNaN(value)) setValue(name, min || 1)
    else if (max && value > max) setValue(name, max)
    else if (min !== undefined && value < min) setValue(name, min)
    //else if (Number.isNaN(value)) setValue(name, undefined)
  }, [value])

  const currentError = (errors[name]?.message as string) || null

  const error = errorText || currentError

  return (
    <>
      <div
        style={style}
        className={cx('number-input', {
          disabled,
          'hide-buttons': hideButtons,
          className,
        })}
      >
        {label && (
          <label>
            {label}
            {!isRequired && <span> (optional)</span>}
          </label>
        )}
        <div style={{ display: 'flex' }}>
          <button
            onClick={(e) => {
              stepUp()
            }}
            className={cx({ disabled: value === max || disabled })}
          >
            <IconPlus size={20} />
          </button>

          {!!valuePreviewLabel && !!value && (
            <div className={cx('value-preview')}>
              {value}{' '}
              {['min', 'sec'].includes(valuePreviewLabel)
                ? valuePreviewLabel
                : pluralize(valuePreviewLabel, value)}
            </div>
          )}

          <input
            className={cx({ error })}
            type="number"
            placeholder={placeholder}
            step={1}
            {...register(name, {
              min,
              max,
              valueAsNumber: true,
              required: isRequired ? 'Please enter the value' : false,
              disabled,
            })}
            onKeyDown={(e) =>
              preventFractions && e.key === '.' && e.preventDefault()
            }
          />

          <button
            onClick={(e) => {
              stepDown()
            }}
            className={cx({
              disabled: value === min || disabled || value === '',
            })}
          >
            <IconMinus size={20} />
          </button>
        </div>
        {error && <div className={cx('error-text')}>{error}</div>}
        {hintText && !subText && !error && (
          <div className={cx('hint-text')}>{hintText}</div>
        )}
        {subText && !error && <div className={cx('sub-text')}>{subText}</div>}
      </div>
    </>
  )
}

export default NumberInput
