import React, { CSSProperties, EventHandler, useEffect, useState } from 'react'
import css from './RepInput.module.css'
import TextInput from '../TextInput/TextInput'
import { useFormContext } from 'react-hook-form'
import pluralize from 'pluralize'
import useHelper from '../../../hooks/useHelper'
import { Workout } from '../../../types/workouts'

type Props = {
  label?: string
  hintText?: string
  errorText?: string
  style?: CSSProperties
  /** Replaces "sets" in labels with "Rounds" */
  roundsMode?: boolean
  isRequired?: boolean
}

const RepsInput = ({
  label,
  hintText,
  errorText,
  style,
  roundsMode,
  isRequired,
}: Props) => {
  const {
    register,
    setValue,
    getValues,
    getFieldState,
    formState: { errors },
  } = useFormContext()
  const { translateRepetitionPattern } = useHelper()

  const currentValue = getValues('repetitionPattern.entryValue')

  const currentError =
    (errors?.repetitionPattern as any)?.entryValue?.message || null

  const normalizeValue = (value: string) => {
    if (value === '-') return ''

    const newValue = value
      .toLowerCase()
      .replace('--', '-')
      .replace('x-', 'x')
      .replace('-x', '-')
      .replace('xx', 'x')
      .replace(' ', '')
      .replace(/[^x0-9\-]*$/, '')

    if (value.includes('x') && value.includes('-')) {
      // Split the input by '-'
      const parts = value.split('-')
      // If there are more than two parts after splitting, then it means
      // there's more than one '-' in the input
      if (parts.length > 2) {
        return currentValue
      }
    }

    if (value.includes('x')) {
      const parts = value.split('x')
      if (parts.length > 2) {
        return currentValue
      }
    }

    return newValue
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newPattern = transformValue(normalizeValue(e.target.value))
    setValue('repetitionPattern', newPattern)
  }

  const valueLabel = !roundsMode
    ? ''
    : currentValue.includes('-')
    ? 'reps'
    : pluralize('round', parseInt(currentValue))

  return (
    <TextInput
      style={style}
      label={label}
      centered={true}
      hintText={hintText || 'Format: 3x15 or 1-2-3'}
      errorText={currentError && isRequired ? currentError : false}
      formMethods={{
        ...register('repetitionPattern.entryValue', {
          onChange: handleChange,
          required: isRequired ? 'Please enter the value' : false,
        }),
      }}
      isRequired={isRequired}
      valuePreviewLabel={valueLabel}
    />
  )
}

export default RepsInput

// powered by gpt4
const transformValue = (input: string): Workout.Item['repetitionPattern'] => {
  let result: Workout.Item['repetitionPattern'] = {
    reps: [],
    dashReps: false,
    entryValue: input,
  }

  // If string ends with 'x' or '-'
  input =
    input.endsWith('x') || input.endsWith('-') ? input.slice(0, -1) : input
  input =
    input.startsWith('x') || input.startsWith('-') ? input.slice(1) : input

  // Remove consecutive '-' characters
  while (input.includes('--')) {
    input = input.replace('--', '-')
  }

  if (!input) return result

  if (input.includes('x')) {
    const [sets, reps] = input.split('x')
    result.reps = reps ? Array(parseInt(sets)).fill(reps) : [sets]
  } else if (input.includes('-')) {
    result.reps = input.split('-')

    // Add dashReps flag if reps are written with dashes and there's no "x"
    result.dashReps = true
  } else {
    result.reps = [input]
  }

  return result
}
