import React, { useEffect, useRef, useState } from 'react'
import { DateTime } from 'luxon'
import css from './GymTV.module.css'
import WorkoutCard from '../../components/cards/WorkoutCard/WorkoutCard'
import useCalendar from '../../hooks/calendar/useCalendar'
import {
  ArrowLeft,
  ArrowRight,
  Calendar,
  CornerUpLeft,
  LogOut,
  Maximize,
  Minimize,
  X,
} from 'react-feather'
import { useHotkeys } from 'react-hotkeys-hook'
import { useNavigate } from 'react-router-dom'
import { DatePicker } from '@mui/x-date-pickers/'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import { translateWorkoutType } from '../../helpers_ts'
import useSize from '../../hooks/useSize'
import { Analytics } from '../../services/analytics'
import useCycles from '../../hooks/cycles/useCycles'
import useCalendarEvents from '../../hooks/calendar/useCalendarEvents'
import { Workout } from '../../types/workouts'
import convertSecondsToTimestamp from '../../utilities/convertSecondsToTimestamp'

const GymTV = () => {
  const [showNotes, setShowNotes] = useState(true)

  const { calendar } = useCalendar()
  const today = calendar.today.date

  const { activeDate, setActiveDate } = useMidnightUpdate()

  const { unpinnedEvents, isLoading: eventsAreLoading } = useCalendarEvents({
    dateISO: activeDate.toISODate(),
    subscribe: true,
  })

  const workouts = unpinnedEvents.filter((event) => event.type === 'workout')

  const [activeIndex, setActiveIndex] = useState(0)

  const { checkDateForCycle } = useCycles()
  const currentCycle = checkDateForCycle(activeDate.toFormat('yyyy-LL-dd'))

  const currentItem = (workouts[activeIndex]?.details as Workout.Item) || null
  const {
    workoutType,
    tracking,
    timeCapRound,
    rounds,
    timeCap,
    title,
    id,
    superset = [],
  } = currentItem || {}

  const itemHasNotes = superset?.some((item) => item.itemType === 'note')

  const toggleNotes = () => {
    Analytics._logEvent({
      name: 'gym_tv_toggle_notes',
      params: {
        showNotes: !showNotes,
        date: activeDate,
        index: activeIndex,
      },
    })
    if (itemHasNotes) setShowNotes(!showNotes)
  }

  const goNext =
    workouts.length > activeIndex + 1
      ? () => setActiveIndex(activeIndex + 1)
      : () => setActiveIndex(0)

  const goPrev = workouts.length
    ? activeIndex - 1 >= 0
      ? () => setActiveIndex(activeIndex - 1)
      : () => setActiveIndex(workouts.length - 1)
    : () => {}

  const dateLabel =
    activeDate === today ? 'Today' : activeDate.toFormat('LLL d, yyyy')

  const defaultAdj = 0.0475 /** started with .036 */

  const [baseAdj, setBaseAdj] = useState(defaultAdj)
  const [zoomed, setZoomed] = useState(false)

  const { ref: contentRef, height: contentHeight } = useSize()
  const { ref: parentRef, height: parentHeight } = useSize()

  const [isFullscreen, setFullscreen] = useState(false)

  useEffect(() => {
    setBaseAdj(defaultAdj)
    setTries(0)
    setZoomed(false)
    // setHeightIsCalculating(true)
    tryToFitContent()
  }, [activeIndex, activeDate, showNotes, isFullscreen])

  useEffect(() => {
    setActiveIndex(0)
  }, [activeDate])

  const [tries, setTries] = useState(0)
  const [heightIsCalculating, setHeightIsCalculating] = useState(true)

  const tryToFitContent = () => {
    if (
      contentHeight > parentHeight &&
      tries < 10000 &&
      !zoomed &&
      !eventsAreLoading
    ) {
      setHeightIsCalculating(true)
      setBaseAdj((prev) => prev * 0.99)
      setTries((tries) => tries + 1)
    } else {
      setHeightIsCalculating(false)
    }
  }

  useEffect(() => {
    tryToFitContent()
  }, [parentRef, contentHeight, parentHeight])

  const goFullscreen = () => {
    const element = document.documentElement as any // TS throws errors otherwise
    try {
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        // Firefox
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        // Chrome, Safari and Opera
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        // IE/Edge
        element.msRequestFullscreen()
      }
      setFullscreen(true)
    } catch (err) {
      console.log('Error entering fullscreen', err)
    }
  }

  const exitFullscreen = () => {
    const _d = document as any // TS throws errors otherwise

    if (_d.exitFullscreen) {
      return _d.exitFullscreen()
    } else if (_d.mozCancelFullScreen) {
      // Firefox
      return _d.mozCancelFullScreen()
    } else if (_d.webkitExitFullscreen) {
      // Chrome, Safari and Opera
      return _d.webkitExitFullscreen()
    } else if (_d.msExitFullscreen) {
      // IE/Edge
      return _d.msExitFullscreen()
    }
  }

  const toggleFullscreen = isFullscreen ? exitFullscreen : goFullscreen

  const onFullscreenChange = () => {
    const _d = document as any
    const fullscreenElement =
      _d.fullscreenElement ||
      _d.mozFullScreenElement ||
      _d.webkitFullscreenElement ||
      _d.msFullscreenElement

    if (fullscreenElement) {
      setFullscreen(true)
    } else {
      setFullscreen(false)
    }
  }

  document.addEventListener('fullscreenchange', onFullscreenChange)
  document.addEventListener('mozfullscreenchange', onFullscreenChange) // Firefox
  document.addEventListener('webkitfullscreenchange', onFullscreenChange) // Chrome, Safari
  document.addEventListener('MSFullscreenChange', onFullscreenChange) // IE/Edge

  const navigate = useNavigate()

  const backToCalendar = () => {
    try {
      if (isFullscreen) exitFullscreen()
    } catch (err) {
      console.log('Error entering fullscreen', err)
    } finally {
      navigate('/')
    }
  }

  useHotkeys(`n`, () => toggleNotes())
  useHotkeys(`z`, () => setZoomed(!zoomed))
  useHotkeys(`ArrowLeft`, () => goPrev())
  useHotkeys(`ArrowRight`, () => goNext())
  useHotkeys(`F`, () => goFullscreen())

  const useTimeCapOverride = tracking === 'emom' || tracking === 'emom-alt'
  const timeCapOverride = convertSecondsToTimestamp(
    (timeCapRound || 0) * (rounds || 1),
    true
  )

  return (
    <div
      id={css['tv-container']}
      className={`${css[workoutType || 'conditioning']}`}
    >
      <div className={`${css['toolbar']} ${css['top']}`}>
        {currentItem && (
          <div className={css['workout-type']}>
            <div
              className={css['counter']}
              style={{
                width: `calc(100vw * ${zoomed ? defaultAdj : baseAdj} * 1.5)`,
              }}
            >
              <h5>
                {workouts.length && `${activeIndex + 1}/${workouts.length}`}
              </h5>
            </div>
            {currentCycle && (
              <h5 className={css['title']} style={{ marginRight: '48px' }}>
                {currentCycle.title}
              </h5>
            )}
            <h5>{translateWorkoutType(workoutType)}</h5>
            {title && (
              <h5 className={css['title']} style={{ marginLeft: '24px' }}>
                {title}
              </h5>
            )}
          </div>
        )}
        <h5 style={{ color: 'white' }}>
          {useTimeCapOverride
            ? timeCapOverride
              ? `${timeCapOverride} min cap`
              : timeCap
              ? `${timeCap} min cap`
              : ''
            : ''}
        </h5>
      </div>

      <div className={css['container']} ref={parentRef}>
        <div className={css['content-wrapper']}>
          <div
            className={css['card-container']}
            ref={contentRef}
            style={heightIsCalculating ? { opacity: 0 } : {}}
          >
            {eventsAreLoading ? (
              <div className={css['placeholder']}>Loading...</div>
            ) : currentItem ? (
              <WorkoutCard
                id={id}
                workoutData={currentItem}
                canSort={false}
                onAction={() => {}}
                tvMode={{
                  enableNotes: showNotes,
                  baseSize: `calc(100vw * ${zoomed ? defaultAdj : baseAdj})`,
                }}
              />
            ) : (
              <div className={css['placeholder']}>
                No Workouts Posted for {dateLabel}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`${css['toolbar']} ${css['bottom']}`}>
        <div className={`${css['elements']} ${css['left']}`}>
          <div
            className={`${css['next-prev-buttons']} ${
              !currentItem && css['disabled']
            }`}
          >
            <div className={`${css['button']} ${css['left']}`} onClick={goPrev}>
              <ArrowLeft />
            </div>
            <div
              className={`${css['button']} ${css['right']}`}
              onClick={goNext}
            >
              <ArrowRight />
            </div>
          </div>
          <div className={`${css['button']} ${css['date-button']}`}>
            <Calendar />
            <div className={css['icon-label-left']}>{dateLabel}</div>
            <DatePicker
              format="YYYY-LL-dd"
              className={css['date-picker']}
              onChange={(value, context) => {
                if (value) setActiveDate(value as DateTime)
              }}
            />
          </div>
        </div>
        <div className={`${css['elements']} ${css['right']}`}>
          {baseAdj !== defaultAdj && (
            <div className={css['button']} onClick={() => setZoomed(!zoomed)}>
              {zoomed ? 'Zoom Out' : 'Zoom In'}
              <div className={css['hotkey']}>Z</div>
            </div>
          )}
          {itemHasNotes && (
            <div className={css['button']} onClick={toggleNotes}>
              {showNotes ? 'Hide Notes' : 'Show Notes'}
              <div className={css['hotkey']}>N</div>
            </div>
          )}
          <div className={css['button']} onClick={toggleFullscreen}>
            {!isFullscreen ? <Maximize /> : <Minimize />}
            <div className={css['icon-label-left']}>
              {!isFullscreen ? 'Fullscreen' : 'Exit Fullscreen'}
            </div>
          </div>
          <div className={css['button']} onClick={backToCalendar}>
            <X />
          </div>
        </div>
      </div>
    </div>
  )
}

export default GymTV

const useMidnightUpdate = () => {
  const [activeDate, setActiveDate] = useState(DateTime.now())

  useEffect(() => {
    // Get the current time and calculate the time until midnight
    const now = DateTime.now()
    const midnight = now.plus({ days: 1, hours: 1 }).startOf('day')
    const msUntilMidnight = midnight.diff(now).toMillis()

    // Set a timeout to update the activeDate at midnight
    const timer = setTimeout(() => {
      setActiveDate(DateTime.now()) // Updates to new current date at midnight
    }, msUntilMidnight)

    // Clear the timeout if the component is unmounted or the timeout is no longer needed
    return () => clearTimeout(timer)
  }, [activeDate])

  return { activeDate, setActiveDate }
}
