import { DateTime } from 'luxon'

import { useContext, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { UserContext } from '../context/UserContext'
import { v4 as uuid } from 'uuid'
import API from '../services'
import { AppContext } from '../context/AppContext'
import { Analytics } from '../services/analytics'
import CalendarContext from '../context/CalendarContext'
import { Workout, WorkoutShortcut } from '../types/workouts'
import { firestore } from '../firebase'
import { useWorkoutSet } from './useWorkoutSet'
import { useWorkoutPublisher } from './useWorkoutPublisher'
import { getNewWorkoutEvent } from '../utilities/workout builder/getNewWorkoutEvent'
import { CalendarEvent } from '../types/calendarEvents'
import getNewWorkout from '../utilities/workout builder/getNewWorkout'

/**
 * @param activeDay format: YYYY-MM-DD
 */
type Props = {
  workoutEventToEdit: CalendarEvent.Workout | null
  workoutToEdit?: Workout.Item
  index: number
  activeDate?: string | null
  newWorkoutTracking?: Workout.Item['tracking']
  onDismiss?: () => void
  isPresetMode?: boolean
}

const useWorkoutBuilder = ({
  workoutEventToEdit,
  workoutToEdit,
  index,
  newWorkoutTracking = 'reps-weight',
  activeDate,
  isPresetMode,
  onDismiss = () => {},
}: Props) => {
  //const { register, control, watch, getValues } = useForm()
  const { userID } = useContext(UserContext)
  const { currentSpace, currentGymTrack } = useContext(AppContext)
  const { setPreventActiveDateChange } = useContext(CalendarContext)

  console.log('workoutToEdit', workoutToEdit)

  const defaultEvent =
    workoutEventToEdit ||
    getNewWorkoutEvent({
      uid: userID,
      gymID: currentSpace?.id || 'no-id',
      trackID: currentGymTrack || 'default',
      eventDateISO: activeDate || DateTime.now().toFormat('yyyy-LL-dd'),
      index,
      tracking: newWorkoutTracking,
    })

  const { current: defaultValues } = useRef(defaultEvent.details)

  const editMode = !!workoutToEdit

  const methods = useForm<Workout.Item>({
    defaultValues: defaultValues,
  })

  const { setItems, temporarilyUpdateSetItems, setItemsAreLoading } =
    useWorkoutSet(defaultValues, workoutEventToEdit?.eventDateISO || 'new')

  // Scaling information
  const defaultScalingOption: Workout.ScalingOption = {
    effort: 'rx',
    genderGroup: 'women',
    ageGroup: 'masters',
  }

  const [activeScalingOption, setActiveScalingOption] =
    useState<Workout.ScalingOption>(
      workoutToEdit?.set?.scaling || defaultScalingOption
    )

  const superset = methods.watch('superset')
  const rounds = methods.watch('rounds')
  const tracking = methods.watch('tracking')

  useEffect(() => {
    /**
     * When switching between tracking types, we need to set default values
     * for rounds and timeCapRound, in case if they were not edited by the user
     */

    const { rounds: roundsAreTouched, timeCapRound: timeCapRoundIsTouched } =
      methods.formState.touchedFields

    if (!roundsAreTouched && !workoutToEdit) {
      switch (tracking) {
        case 'emom':
        case 'emom-alt':
          methods.setValue('rounds', 10)
          break
        default:
          methods.setValue('rounds', 1)
      }
    }
  }, [tracking])

  const movementsLength = superset.filter(
    (item) => item.itemType === 'exercise'
  ).length

  const filledMovementsLength = superset.filter(
    (item) => item.itemType === 'exercise' && !!item.movement.name
  ).length
  const notesExist = superset.some(
    (item) => item.itemType === 'note' && item.note
  )

  const isSuperset = movementsLength > 1
  const isValid = !!filledMovementsLength || notesExist
  const isDirty =
    JSON.stringify(methods.formState.dirtyFields) !== '{}' && isValid // isDirty prop not working for some reason

  useEffect(() => {
    const isSuperset = filledMovementsLength > 1
    methods.setValue('isSuperset', isSuperset)

    switch (filledMovementsLength) {
      case 0:
      case 1:
        if (tracking === 'complex') {
          methods.setValue('tracking', 'reps-weight')
        }
        break
      default:
        if (tracking === 'reps-weight') {
          methods.setValue('tracking', 'complex')
        }
        break
    }
  }, [filledMovementsLength])

  useEffect(() => {
    if (isDirty) setPreventActiveDateChange(true)
  }, [isDirty])

  //const newWorkout = usePostWorkout()

  const { submitCalendarEvents, submitWorkout, deleteWorkoutEvent } =
    useWorkoutPublisher()

  const handleSubmit = async ({
    workoutData,
    isPublished,
  }: {
    workoutData: Workout.Item
    isPublished: boolean
  }) => {
    const calendarEvent: CalendarEvent.Workout = {
      ...defaultEvent,
      isPublished,
      details: workoutData,
    }
    //submitCalendarEvents([result])
    submitWorkout({
      workoutData,
      setItems,
      isEditMode: editMode,
      onSuccess: () => {},
      eventData: calendarEvent,
      newEventDateISO: calendarEvent.eventDateISO,
      isPresetCreationMode: isPresetMode,
      publishStatus: isPublished,
      index,
    })
    //if (!preventDismiss) {
    onDismiss()
    setPreventActiveDateChange(false)
    //}
  }

  const onSubmitLegacy = async (
    {
      newEventData,
      newWorkoutData,
      setItemsOverride,
      preventDismiss,
      onADate,
      publishStatus,
    }: {
      newEventData: CalendarEvent.Workout | null
      newWorkoutData: Workout.Item
      setItemsOverride?: Workout.Item[]
      preventDismiss?: boolean
      onADate?: string
      publishStatus: boolean
    } // format: YYYY-MM-DD}
  ) => {
    if (!currentSpace || (!newEventData && !activeDate && !onADate))
      return console.error('Cannot submit: need current event or active date')

    await submitWorkout({
      workoutData: newWorkoutData,
      setItems: setItemsOverride || setItems,
      isEditMode: editMode,
      onSuccess: () => {},
      eventData: newEventData,
      newEventDateISO:
        activeDate || onADate || DateTime.now().toFormat('yyyy-LL-dd'),
      isPresetCreationMode: isPresetMode,
      publishStatus,
      index,
    })

    if (!preventDismiss) {
      onDismiss()
      setPreventActiveDateChange(false)
    }
  }

  const onDelete = async (workoutID: string, preventDismiss?: boolean) => {
    if (workoutEventToEdit) {
      deleteWorkoutEvent(workoutEventToEdit)
    } else if (workoutToEdit) {
      console.warn('Deleting workout item', workoutToEdit)
    }

    if (!preventDismiss) onDismiss()
    setPreventActiveDateChange(false)

    Analytics._logEvent({
      name: 'workout_deleted',
      params: {
        gymID: currentSpace?.id ?? 'no-id',
        eventID: workoutEventToEdit?.id ?? 'no-d',
        eventItemID: workoutID,
      },
    })
  }

  const handleScalingOptionChange = (
    newScalingOption: Workout.ScalingOption
  ) => {
    if (activeScalingOption === newScalingOption) return

    setActiveScalingOption(newScalingOption)

    const currentFormValues = methods.getValues()

    // Need to verify if the current scaling option exists in setItems
    const workoutWithSelectedScaling = setItems.find(
      (item) =>
        item.set?.scaling.effort === newScalingOption.effort &&
        item.set?.scaling.genderGroup === newScalingOption.genderGroup &&
        item.set?.scaling.ageGroup === newScalingOption.ageGroup
    )

    // Scaling option exists -> gonna switch form values to it
    if (workoutWithSelectedScaling) {
      // Need to save the existing workout to set items before we reset the form
      const newSetItems = setItems.map((item) => {
        if (item.id === currentFormValues.id) return currentFormValues
        else return { ...item, title: currentFormValues.title }
      })

      temporarilyUpdateSetItems(newSetItems)

      // Switching to new scaling option
      methods.reset(workoutWithSelectedScaling)
      //setNoScalingAddedMode(false)
    } else {
      // Scaling option does not exist -> show a message that it doesn't exist
      //setNoScalingAddedMode(true)
    }
  }

  const addScalingOption = () => {
    const currentFormValues = methods.getValues()
    const parentWorkoutID = currentFormValues.set?.parentWorkoutID

    const parentWorkout = setItems
      .map((workout) => {
        console.log(workout.id)
        return workout
      })
      .find((workout) => workout.id === parentWorkoutID)

    const newSetInfo = {
      scaling: activeScalingOption,
      id: currentFormValues.set?.id || 'no-set-id',
      parentWorkoutID: parentWorkout?.id || 'no-id',
    }

    const newWorkout: Workout.Item = {
      ...currentFormValues,
      id: 'wk_' + uuid().slice(0, 8),
      set: newSetInfo,
    }

    // saving existing set item before switching to new one
    const newSetItems = setItems.map((item) => {
      if (item.id === currentFormValues.id) return currentFormValues
      else return { ...item, title: currentFormValues.title }
    })

    temporarilyUpdateSetItems([...newSetItems, newWorkout])
    methods.reset(newWorkout)
  }

  return {
    formMethods: methods,
    saveWorkout: methods.handleSubmit((workoutData) =>
      handleSubmit({ workoutData, isPublished: false })
    ),
    publishWorkout: methods.handleSubmit((workoutData) => {
      handleSubmit({ workoutData, isPublished: true })
    }),
    unpublishWorkout: methods.handleSubmit((workoutData) =>
      handleSubmit({ workoutData, isPublished: false })
    ),
    updateWorkoutWithData: onSubmitLegacy,
    addWorkout: onSubmitLegacy,
    handleDelete: onDelete,
    getNewWorkout,
    isValid,
    isDirty,
    isSuperset,
    setItems,
    activeScalingOption,
    handleScalingOptionChange,
    addScalingOption,
    setItemsAreLoading,
    values: {
      tracking,
      rounds: rounds || 0,
      timeCapRound: methods.watch('timeCapRound') || 0,
    },
  }
}

export default useWorkoutBuilder
