import React, { CSSProperties, useState } from 'react'
import css from './RoundsInput.module.css'
import { Plus as IconPlus, Minus as IconMinus } from 'react-feather'
import NumberInput from '../NumberInput/NumberInput'
import { useFormContext } from 'react-hook-form'

type Props = {
  label?: string
  errorText?: string
  style?: CSSProperties
  disabled?: boolean
  isRequired?: boolean
  subText?: string
  min?: number
  valuePreviewLabel?: string
}

const RoundsInput = ({
  label,
  style,
  disabled,
  isRequired,
  subText,
  min,
  valuePreviewLabel = 'rounds',
}: Props) => {
  return (
    <NumberInput
      label={label}
      style={style}
      min={min}
      max={999}
      disabled={disabled}
      emptyIsMin={true}
      preventFractions={true}
      name={'rounds'}
      isRequired
      valuePreviewLabel={valuePreviewLabel}
      subText={subText}
    />
  )
}

export default RoundsInput
