import React, { useEffect, useRef, useState } from 'react'
import css from './SmartNote.module.css'
import classNames from 'classnames/bind'
import { useSortable } from '@dnd-kit/sortable'
import { useFormContext } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'
import {
  X as IconMinusCircle,
  AlignJustify as IconDrag,
  AlertCircle,
  HelpCircle,
} from 'react-feather'
import { CSS } from '@dnd-kit/utilities'
import { Tooltip } from 'react-tooltip'
import useHelper from '../../../hooks/useHelper'

const cx = classNames.bind(css)

type Props = {
  id: string
  index: number
  onRemove: () => void
  isDragOverlay?: boolean
  sortingInProgress?: boolean
  packPosition: 'first' | 'middle' | 'last' | 'lonely'
  canSort: boolean
  parentFieldName: string
  forceAnimate?: boolean
  onEditClick: (currentContent: string) => void
}

const SmartNote = ({
  id,
  onRemove,
  isDragOverlay,
  sortingInProgress,
  packPosition,
  canSort,
  forceAnimate,
  onEditClick,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: id, attributes: { tabIndex: -1 } })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  const { watch } = useFormContext()
  const { translateRepetitionInstruction } = useHelper()

  const tracking = watch('tracking')
  const timeCap = watch('timeCap') || null
  const timeCapRound = watch('timeCapRound') || null
  const roundRestTime = watch('roundRestTime') || {
    work: { min: 0, sec: 0 },
    rest: { min: 0, sec: 0 },
    entryValue: '',
  }
  const rounds = watch('rounds') || null
  const repetitionPattern = watch('repetitionPattern') || null
  const isSuperset = watch('isSuperset')

  const getPlaceholderNote = () => {
    switch (tracking) {
      case 'emom':
      case 'emom-alt': {
        if (!timeCap && !timeCapRound)
          return 'Please add time cap and interval time'
        if (!timeCap) return 'Please add time cap'
        return 'Please add interval time'
      }
      case 'total-reps': {
        if (rounds > 1) return 'Please add work/rest time'
        return 'Please add time cap'
      }
      case 'reps-weight':
        return 'Please add sets and reps'
    }
  }

  const smartNoteExpert = translateRepetitionInstruction({
    expertModeOverride: true,
    tracking,
    timeCap,
    timeCapRound,
    roundRestTime,
    rounds,
    repetitionPattern,
    isSuperset,
  })

  const smartNoteBeginner = translateRepetitionInstruction({
    expertModeOverride: false,
    tracking,
    timeCap,
    timeCapRound,
    roundRestTime,
    rounds,
    repetitionPattern,
    isSuperset,
  })

  const isPlaceholderNote = !smartNoteExpert && !smartNoteBeginner
  const placeholderNote = getPlaceholderNote()

  const animationLoopDuration = 2000

  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    setAnimated(true)
    setTimeout(() => {
      setAnimated(false)
    }, animationLoopDuration)
  }, [tracking])

  const [animated, setAnimated] = useState(false)

  return (
    <div
      className={cx('everything', 'note', {
        'drag-overlay': isDragOverlay,
        hide: isDragging,
        sorting: sortingInProgress,
        animated: animated || forceAnimate,
      })}
      ref={setNodeRef}
      {...attributes}
      style={style}
    >
      <Tooltip
        id="t-smart-note"
        place="left-start"
        delayShow={400}
        className="tooltip big"
        noArrow={true}
        offset={4}
        style={{ margin: '17px 0 0 -72px' }}
        render={() => (
          <div>
            <h1>Smart Note</h1>
            <p>
              The smart note is based on workout options. For complex workouts,
              the note is more descriptive for beginner athletes.
            </p>
            {smartNoteBeginner !== smartNoteExpert ? (
              <>
                <h2>Beginner Athletes</h2>
                <div
                  style={{
                    margin: '-4px 0 4px',
                    color: 'var(--secondary-text-white)',
                  }}
                >
                  (less than 10 workouts)
                </div>
                <p style={{ fontStyle: 'italic' }}>
                  {smartNoteBeginner ? `"${smartNoteBeginner}"` : 'N/A'}
                </p>
                <h2>Skilled Athletes</h2>
                <p style={{ fontStyle: 'italic' }}>
                  {smartNoteExpert ? `"${smartNoteExpert}"` : 'N/A'}
                </p>
              </>
            ) : (
              <>
                <h2>All Athletes</h2>
                <p style={{ fontStyle: 'italic' }}>
                  {smartNoteExpert ? `"${smartNoteExpert}"` : 'N/A'}
                </p>
              </>
            )}
          </div>
        )}
      />
      <div className={cx('labels')}>
        <label>
          Smart Note
          <i data-tooltip-id="t-smart-note">
            <HelpCircle
              size={14}
              color={'var(--icon)'}
              style={{
                position: 'relative',
                top: '2.5px',
                left: '4px',
              }}
            />
          </i>
        </label>
        <span onClick={() => onEditClick(smartNoteExpert || '')}>Edit</span>
      </div>

      <div className={cx('wrapper')} style={{ position: 'relative' }}>
        {canSort && (
          <div
            data-tooltip-id="t-drag-hint"
            data-tooltip-content="Drag to reorder"
            className={cx('drag-icon')}
            {...listeners}
          >
            <IconDrag size={20} color={'#ccc'} />
          </div>
        )}
        <div className={cx('gradient-background', packPosition)}>
          <div className={cx('all-contents')}>
            <div
              className={cx('text-container', {
                'placeholder-note': isPlaceholderNote,
              })}
            >
              {!!isPlaceholderNote && (
                <AlertCircle
                  size={16}
                  className={cx('icon')}
                  color={'var(--icon)'}
                />
              )}
              {smartNoteExpert || placeholderNote}
            </div>

            <div onClick={onRemove} className={cx('icon-container')}>
              <IconMinusCircle size={16} className={cx('icon')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SmartNote

// const Note = () => {
//   const {
//     attributes,
//     listeners,
//     setNodeRef,
//     transform,
//     transition,
//     isDragging,
//   } = useSortable({ id: id, attributes: { tabIndex: -1 } })

//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition,
//   }

//   const { register } = useFormContext()

//   return (
//     <div
//       className={`
//         ${css['everything']}
//         ${isDragOverlay ? css['drag-overlay'] : ''}
//         ${isDragging ? css['hide'] : ''}
//         ${sortingInProgress ? css['sorting'] : ''}
//       `}
//       ref={setNodeRef}
//       style={style}
//       {...attributes}
//     >
//       {['first', 'lonely'].includes(packPosition) && (
//         <div className={css['labels']}>
//           <label style={{ float: 'left' }}>Note</label>
//         </div>
//       )}
//       <div
//         className={`${css['inputs-and-icon-container']} ${css[packPosition]}`}
//       >
//         {canSort && (
//           <div
//             data-tooltip-id="t-drag-hint"
//             data-tooltip-content="Drag to reorder"
//             className={css['drag-icon']}
//             {...listeners}
//           >
//             <IconDrag size={20} color={'#ccc'} />
//           </div>
//         )}
//         <div className={`${css['inputs-container']} `}>
//           <TextareaAutosize
//             minRows={1}
//             className={`${css['note-input']}`}
//             placeholder="Note..."
//             {...register(`${parentFieldName}.${index}.note`)}
//           />
//         </div>
//         <div onClick={onRemove} className={css['icon-container']}>
//           <IconMinusCircle size={16} className={css['icon']} />
//         </div>
//       </div>

//       <div className={css['overlay']} />
//     </div>
//   )
// }

// export default Note
