import {
  DocumentData,
  getDocs,
  onSnapshot,
  Query,
  QueryDocumentSnapshot,
  limit as firestoreLimit,
  startAfter as firestoreStartAfter,
  query as firestoreQuery,
} from 'firebase/firestore'
import { useEffect } from 'react'
import {
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
  UseInfiniteQueryResult,
} from '@tanstack/react-query'
import { auth } from '../../firebase'

interface IProps<TData = DocumentData> {
  queryKey: QueryKey
  query: Query<TData>
  limit: number
  subscribe?: boolean
  queryOptions?: Omit<
    UseInfiniteQueryOptions<
      { data: TData[]; lastVisible: QueryDocumentSnapshot<TData> | null },
      Error,
      {
        pages: { data: TData[] }[]
        lastVisible: QueryDocumentSnapshot<TData> | null
      },
      { data: TData[]; lastVisible: QueryDocumentSnapshot<TData> | null },
      QueryKey
    >,
    'queryKey' | 'queryFn' | 'getNextPageParam' | 'initialPageParam'
  >
}

const useCustomFirestoreInfiniteQueryData = <TData = DocumentData>({
  queryKey,
  query,
  limit,
  subscribe = false,
  queryOptions,
}: IProps<TData>): UseInfiniteQueryResult<
  {
    pages: { data: TData[] }[]
    lastVisible: QueryDocumentSnapshot<TData> | null
  },
  Error
> => {
  const isAuthed = auth.currentUser !== null

  const infiniteQuery = useInfiniteQuery<
    { data: TData[]; lastVisible: QueryDocumentSnapshot<TData> | null },
    Error,
    {
      pages: { data: TData[] }[]
      lastVisible: QueryDocumentSnapshot<TData> | null
    },
    QueryKey
  >({
    queryKey,
    queryFn: async ({ pageParam = null }) => {
      let modifiedQuery = firestoreQuery(query, firestoreLimit(limit))

      if (pageParam) {
        modifiedQuery = firestoreQuery(
          modifiedQuery,
          firestoreStartAfter(pageParam),
          firestoreLimit(limit)
        )
      }

      const snapshot = await getDocs(modifiedQuery)
      const lastVisible = snapshot.docs[snapshot.docs.length - 1] as
        | QueryDocumentSnapshot<TData>
        | undefined
      const data = snapshot.docs.map((doc) => doc.data() as TData)

      return { data, lastVisible: lastVisible || null }
    },
    getNextPageParam: (lastPage) => lastPage.lastVisible,
    initialPageParam: null,
    ...queryOptions,
  })

  const enableQuery = queryOptions ? queryOptions.enabled : true

  useEffect(() => {
    if (subscribe && isAuthed && enableQuery) {
      const unsubscribe = onSnapshot(
        firestoreQuery(query, firestoreLimit(limit)),
        () => {
          infiniteQuery.refetch()
        },
        (error) => {
          console.error('Error fetching data:', error)
        }
      )
      return () => unsubscribe()
    }
  }, [subscribe, isAuthed, query, limit, infiniteQuery])

  return infiniteQuery
}

export default useCustomFirestoreInfiniteQueryData
