import React, { useContext, useEffect, useRef, useState } from 'react'
import { CalendarEvent } from '../../types/calendarEvents'
import {
  DragOverEvent,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import useCachedCalendarEvents from './useCachedCalendarEvents'
import { v4 as uuid } from 'uuid'
import { useHotkeys } from 'react-hotkeys-hook'
import { AppContext, CalendarViewState } from '../../context/AppContext'
import { useWorkoutPublisher } from '../useWorkoutPublisher'
import { Analytics } from '../../services/analytics'

type Props = {}

const useCalendarEventDragNDropper = (props: Props) => {
  const dndSensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1, // otherwise dndkit will prevent click propagation
      },
    })
  )

  const { updateCachedEvents, getCachedEventsForDate, getCachedEventByID } =
    useCachedCalendarEvents()

  const [dragOperation, setDragOperation] = useState<{
    originalEvent: CalendarEvent.Item
    duplicateEvent: CalendarEvent.Item
    draggedEvent: CalendarEvent.Item
  } | null>(null)

  const { calendarViewState, setCalendarViewState, currentSpace } =
    useContext(AppContext)
  const originalCalendarViewState = useRef(calendarViewState)

  const [isDuplicating, setDuplicating] = useState(false)

  const copyKey = window.navigator.userAgent.includes('Mac') ? 'alt' : 'ctrl'
  const key = useHotkeys(copyKey, () => setDuplicating((prev) => !prev), {
    keyup: true,
    keydown: true,
  })

  const handleDragStart = (event: DragStartEvent) => {
    // Extract only the data parts
    const draggedItem = getCachedEventByID(event.active.id as string)

    if (draggedItem) {
      setDragOperation({
        originalEvent: draggedItem,
        duplicateEvent: { ...draggedItem, id: `ev_${uuid().substring(0, 8)}` },
        draggedEvent: draggedItem,
      })

      if (calendarViewState === CalendarViewState.Collapse) {
        originalCalendarViewState.current = calendarViewState
        setCalendarViewState(CalendarViewState.Expand)
      }
    }
  }

  const { submitCalendarEvents } = useWorkoutPublisher()

  const handleDragOver = ({ active, over }: DragOverEvent) => {
    const overID = over?.id as string | undefined
    const activeEventID = active?.id as string | undefined

    if (activeEventID === overID || !overID || !activeEventID || !dragOperation)
      return

    const targetEvent = dragOperation.draggedEvent

    const { originalEvent, duplicateEvent, draggedEvent } = dragOperation

    // const isSorting = !!over?.data.current?.sortable
    const isOverADropbbaleContainer = !overID.includes('ev_')

    const sourceDate = targetEvent.eventDateISO
    const sourceEvents = getCachedEventsForDate(sourceDate).sort(
      (a, b) => a.index - b.index
    ) // sorting order: ascending
    const sourceIndex = targetEvent.index

    const destinationDate = isOverADropbbaleContainer
      ? overID
      : getCachedEventByID(overID)?.eventDateISO ?? ''

    const destinationIndex = over?.data.current?.sortable.index || 0

    // Dragging within a single day -> sorting
    if (destinationDate === sourceDate) {
      const updatedDraggedEvent = {
        ...targetEvent,
        index: destinationIndex,
      }

      const updatedSourceEvents = addEvent({
        originalEvents: sourceEvents,
        eventToAdd: updatedDraggedEvent,
      })

      setDragOperation((prev) => {
        if (prev) {
          return {
            ...prev,
            draggedEvent: updatedDraggedEvent,
            destinationEvents: updatedSourceEvents,
          }
        }
        return prev
      })

      updateCachedEvents({
        affectedDates: [sourceDate],
        updatedEvents: updatedSourceEvents,
      })
    } else {
      // Dragging to another day
      // Need to remove the event from the old date and add it to the new date
      // and adjust indexes

      const destinationEvents = getCachedEventsForDate(destinationDate).sort(
        (a, b) => a.index - b.index
      )

      // Removing the event from the old date and adjusting indexes
      const updatedSourceEvents = removeEventsFromGroup({
        events: sourceEvents,
        eventIDstoRemove: [draggedEvent.id],
      })
      // Adding the event to the new date and adjusting indexes

      const updatedDraggedEvent = {
        ...targetEvent,
        eventDateISO: destinationDate,
        index: destinationIndex,
      }

      const updatedDestinationEvents = addEvent({
        originalEvents: destinationEvents,
        eventToAdd: updatedDraggedEvent,
      })

      setDragOperation((prev) => {
        if (prev) {
          return {
            ...prev,
            draggedEvent: updatedDraggedEvent,
            destinationEvents: updatedDestinationEvents,
          }
        }
        return prev
      })

      updateCachedEvents({
        affectedDates: [sourceDate, destinationDate],
        updatedEvents: [...updatedSourceEvents, ...updatedDestinationEvents],
      })
    }
  }

  const handleDragEnd = () => {
    if (!dragOperation) return console.warn('no drag operation')

    const { draggedEvent, originalEvent, duplicateEvent } = dragOperation

    const sourceEvents = getCachedEventsForDate(originalEvent.eventDateISO)
    const destinationEvents = getCachedEventsForDate(draggedEvent.eventDateISO)

    const sourceDate = originalEvent.eventDateISO
    const destinationDate = draggedEvent.eventDateISO
    const isSorting = destinationDate === sourceDate

    const eventsToUpdate = isSorting
      ? sourceEvents
      : [...sourceEvents, ...destinationEvents]

    // If we were duplicating, we need to swap the original with the duplicate
    // because we kept duplicate in the original date

    Analytics._logEvent({
      name: 'workout_drag_n_drop',
      params: {
        gymID: currentSpace?.id ?? 'no-id',
        sourceDate: sourceDate,
        destinationDate: destinationDate,
        isSorting,
        isDuplicating,
      },
    })

    setTimeout(() => {
      // Timeout lets the animation to play out
      if (isDuplicating) {
        const updatedEvents = eventsToUpdate.map((event) => {
          // Duplicate is kept in the source date
          if (event.id === duplicateEvent.id) {
            return originalEvent
          }
          if (event.id === originalEvent.id) {
            return {
              ...duplicateEvent,
              index: draggedEvent.index,
              eventDateISO: draggedEvent.eventDateISO,
            }
          }
          return event
        })

        updateCachedEvents({
          affectedDates: isSorting
            ? [sourceDate]
            : [sourceDate, destinationDate],
          updatedEvents: updatedEvents,
        })
        submitCalendarEvents(updatedEvents, true)
      } else {
        submitCalendarEvents(eventsToUpdate, true)
      }
      setCalendarViewState(originalCalendarViewState.current)
    }, 500)
    finalizeDragNDrop()
  }

  const handleDragCancel = () => {
    // if the drag is cancelled, we need to revert the changes

    if (!dragOperation) return

    const { originalEvent, draggedEvent, duplicateEvent } = dragOperation

    const sourceEvents = getCachedEventsForDate(originalEvent.eventDateISO)

    const sourceDate = originalEvent.eventDateISO
    const destinationDate = draggedEvent.eventDateISO
    const isSorting = destinationDate === sourceDate

    const destinationEvents = isSorting
      ? []
      : getCachedEventsForDate(draggedEvent.eventDateISO)

    // Removing moved version of the original event
    // And duplicates from source date (if any)
    const updatedSourceEventsWithoutDuplicates = removeEventsFromGroup({
      events: sourceEvents,
      eventIDstoRemove: [draggedEvent.id, duplicateEvent.id],
    })

    // Adding original event back on its index (function will adjust other indexes)
    const updatedSourceEvents = addEvent({
      originalEvents: updatedSourceEventsWithoutDuplicates,
      eventToAdd: originalEvent,
    })

    // Removing the dragged event from the destination date (if it was dragged there)
    const updatedDestinationEvents = removeEventsFromGroup({
      events: destinationEvents,
      eventIDstoRemove: [draggedEvent.id],
    })

    const updatedEvents = isSorting
      ? updatedSourceEvents
      : [...updatedSourceEvents, ...updatedDestinationEvents]

    updateCachedEvents({
      affectedDates: isSorting ? [sourceDate] : [sourceDate, destinationDate],
      updatedEvents: updatedEvents,
    })

    setCalendarViewState(originalCalendarViewState.current)

    finalizeDragNDrop()
  }

  const finalizeDragNDrop = () => {
    setDragOperation(null)
    setDuplicating(false)
  }

  useEffect(() => {
    if (dragOperation) {
      const { originalEvent, duplicateEvent } = dragOperation

      const sourceDate = originalEvent.eventDateISO
      const destinationDate = dragOperation.draggedEvent.eventDateISO
      const isSorting = destinationDate === sourceDate

      const sourceEvents = getCachedEventsForDate(sourceDate)

      if (isDuplicating) {
        // toggled ON the duplication
        // temporarily adding the duplicate into the source date
        // on dragEnd we will swap the duplicate with the original
        const updatedSourceEvents = addEvent({
          originalEvents: sourceEvents,
          eventToAdd: duplicateEvent,
        })

        updateCachedEvents({
          affectedDates: [sourceDate],
          updatedEvents: updatedSourceEvents,
        })
      } else {
        // toggled OFF the duplication
        // removing the duplicate from the source date
        const updatedSourceEvents = removeEventsFromGroup({
          events: sourceEvents,
          eventIDstoRemove: [duplicateEvent.id],
        })

        updateCachedEvents({
          affectedDates: [sourceDate],
          updatedEvents: updatedSourceEvents,
        })
      }
    }
  }, [isDuplicating])

  return {
    dndSensors,
    dragOperation,
    isDuplicating,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleDragCancel,
  }
}

export default useCalendarEventDragNDropper

const addEvent = ({
  originalEvents,
  eventToAdd,
}: {
  originalEvents: CalendarEvent.Item[]
  eventToAdd: CalendarEvent.Item
}) => {
  const destinationIndex = eventToAdd.index
  const filteredEvents = originalEvents.filter(
    (event) => event.id !== eventToAdd.id
  )
  const updatedEvents = [
    ...filteredEvents.slice(0, destinationIndex),
    eventToAdd,
    ...filteredEvents.slice(destinationIndex),
  ].map((item, index) => ({ ...item, index: index }))
  return updatedEvents
}

const removeEventsFromGroup = ({
  events,
  eventIDstoRemove,
}: {
  events: CalendarEvent.Item[]
  eventIDstoRemove: string[]
}) => {
  const updatedEvents = events
    .filter((event) => !eventIDstoRemove.includes(event.id))
    .map((item, index) => ({ ...item, index: index }))
  return updatedEvents
}
