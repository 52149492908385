import {
  DocumentData,
  getDocs,
  onSnapshot,
  Query,
  QuerySnapshot,
} from 'firebase/firestore'
import { useEffect } from 'react'
import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query'
import { auth } from '../../firebase'

interface IProps<TData = DocumentData> {
  queryKey: QueryKey
  query: Query<TData>
  subscribe?: boolean
  queryOptions?: Omit<
    UseQueryOptions<TData[], Error, TData[], QueryKey>,
    'queryKey' | 'queryFn'
  >
}

const useCustomFirestoreQueryData = <TData = DocumentData>({
  queryKey,
  query,
  subscribe = false,
  queryOptions,
}: IProps<TData>): UseQueryResult<TData[], Error> => {
  const isAuthed = auth.currentUser !== null

  const queryResult = useQuery<TData[], Error, TData[], QueryKey>({
    queryKey,
    queryFn: async () => {
      const snapshot = await getDocs(query)
      return snapshot.docs.map((doc) => doc.data()) // Return doc.data() directly here
    },
    ...queryOptions, // No need for a separate select function since we're handling it in the async function
  })

  const enableQuery = queryOptions ? queryOptions.enabled : true

  useEffect(() => {
    if (subscribe && isAuthed && enableQuery) {
      const unsubscribe = onSnapshot(
        query,
        () => {
          queryResult.refetch()
        },
        (error) => {
          console.error('Error fetching data:', error)
        }
      )
      return () => unsubscribe()
    }
  }, [subscribe, enableQuery, isAuthed])

  return queryResult
}

export default useCustomFirestoreQueryData
