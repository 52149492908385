import { Timestamp } from 'firebase/firestore'
import { CalendarEvent } from '../../types/calendarEvents'
import { v4 as uuid } from 'uuid'
import getNewWorkout from './getNewWorkout'
import { DateTime } from 'luxon'

export const getNewNoteEvent = ({
  uid,
  eventDateISO,
  index,
  newNoteType,
  gymID,
  trackID,
}: {
  uid: string
  eventDateISO: string
  index: number
  newNoteType: 'note' | 'staff-note' | 'pinned-note'
  gymID: string
  trackID: string
}) => {
  const newEvent: CalendarEvent.Note = {
    id: 'ev_note_' + uuid().substring(0, 8),
    index: index,
    createdBy: uid,
    createdDate: Timestamp.now(),
    updatedBy: uid,
    updatedDate: Timestamp.now(),
    eventDateISO,
    gymID,
    trackID,
    type: 'note',
    pinOptions:
      newNoteType === 'pinned-note'
        ? {
            isPinned: true,
            pinDates: {
              startDateISO: eventDateISO,
              endDateISO: DateTime.fromISO(eventDateISO)
                .plus({ weeks: 1 })
                .toISODate(),
            },
          }
        : {
            isPinned: false,
            pinDates: null,
          },
    details: {
      title: '',
      category: newNoteType === 'staff-note' ? 'Staff Note' : 'Note',
      allowSocials: true,
      preview: '',
      content: '',
      tracking: 'no-tracking',
      superset: [
        // legacy mode
        {
          id: 'note_' + uuid().substring(0, 8),
          itemType: 'note',
          note: '',
        },
      ],
    },
    isDeleted: false,
    isPublished: true,
  }

  return newEvent
}
