import React, { CSSProperties, useMemo } from 'react'
import { CalendarEvent } from '../../../../types/calendarEvents'
import { useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import css from './CalendarItem.module.css'
import { rewodPluralize } from '../../../../utilities/rewodPluralize'
import { abbreviateMovementName } from '../../../../helpers'
import { Workout } from '../../../../types/workouts'
import { IconWorkoutType } from '../../../atoms/Icons/Icons'

import classNames from 'classnames/bind'
import { Tooltip } from 'react-tooltip'
import IconNote from '../../../atoms/Icons/IconNote/IconNote'
import IconPin from '../../../atoms/Icons/IconPin'
import { AlignLeft } from 'react-feather'
const cx = classNames.bind(css)

interface CalendarItemProps {
  item: CalendarEvent.Item
  /**
   * @prop hovering - the one that's flying when being dragged
   */
  hovering?: boolean
  duplicating?: boolean
  disableDrag?: boolean
}

const CalendarItem = React.memo(
  ({ item, hovering, duplicating, disableDrag }: CalendarItemProps) => {
    const items = useMemo(
      () =>
        item.type === 'workout'
          ? getWorkoutItems(item.details, item.isPublished)
          : getNoteItems(item.details, item.pinOptions?.isPinned || false),
      [item]
    )

    const copyKey = window.navigator.userAgent.includes('Mac') ? '⌥' : 'ctrl'
    const copyText = !duplicating
      ? `Hold ${copyKey} to duplicate`
      : 'Duplicating...'

    const {
      active,
      isDragging,
      setNodeRef,
      attributes,
      listeners,
      transform,
      transition,
    } = useSortable({
      id: item.id,
      strategy: verticalListSortingStrategy,
      disabled: disableDrag,
    })

    const style: CSSProperties = {
      position: 'relative',
      opacity: isDragging ? 0.5 : undefined,
      // transform: CSS.Transform.toString(transform), // buggy during duplication
      transition,
    }

    return (
      <>
        <div
          className={cx('workout', {
            published: item.isPublished,
            dragging: isDragging,
            hovering: hovering,
            duplicating,
            'disable-drag': disableDrag,
          })}
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          data-tooltip-id={
            !disableDrag ? 'tooltip-drag-hint' : 'tooltip-drag-hint-disabled'
          }
        >
          {item.details.title && item.type === 'workout' && (
            <div className={cx('workout-title')}>{item.details.title}</div>
          )}
          {items}
        </div>
        <div className={cx('hoverhint', { duplicating, hovering })}>
          {copyText}
        </div>
      </>
    )
  }
)

const getWorkoutItems = (workout: Workout.Item, isPublished: boolean) => {
  const notesOnlyMode = !workout.superset.some(
    (item) => item.itemType === 'exercise'
  )
  return (
    <>
      {workout.superset.map((item) => {
        switch (item.itemType) {
          case 'exercise': {
            const movementName =
              item.movement && item.movement.name ? item.movement.name : ''

            const shortName = workout.isSuperset
              ? rewodPluralize(abbreviateMovementName(movementName))
              : abbreviateMovementName(movementName)

            return (
              <div
                key={item.id}
                className={cx('day-item', {
                  [workout.workoutType]: true,
                })}
              >
                <div className={cx('icon-and-name-wrapper')}>
                  <div className={cx('icon')}>
                    <IconWorkoutType
                      type={workout.workoutType}
                      size={10}
                      outline={!isPublished}
                    />
                  </div>
                  <div className={cx('movement-name')}>{shortName}</div>
                </div>
              </div>
            )
          }

          case 'note': {
            return (
              notesOnlyMode && (
                <div key={item.id} className={cx('day-item', 'note')}>
                  <div className={cx('icon-and-name-wrapper')}>
                    <div className={css['name']}>
                      {item.note.replace(/\n\s*\n/g, '\n').substring(0, 64)}
                      {item.note.length > 64 ? '...' : ''}
                    </div>
                  </div>
                </div>
              )
            )
          }
        }
      })}
    </>
  )
}

const getNoteItems = (
  note: CalendarEvent.Note['details'],
  isPinned: boolean
) => {
  const { category, preview, title, content } = note

  const text = (title ? title + ': ' + preview + content : preview + content)
    .replace(/\n\s*\n/g, '\n')
    .substring(0, 64)

  return (
    <div className={cx('note-item')}>
      <div className={css['icon']}>
        {isPinned ? (
          <IconPin
            color="var(--icon)"
            style={{
              margin: '1px 0 -1px -1px',
            }}
          />
        ) : (
          <AlignLeft
            color="var(--icon)"
            size={11}
            style={{
              marginTop: '1px',
            }}
          />
        )}
      </div>
      <div className={css['name']}>{text}</div>
    </div>
  )
}

export default CalendarItem
