import { Timestamp } from 'firebase/firestore'
import { CalendarEvent } from '../../types/calendarEvents'
import { v4 as uuid } from 'uuid'
import getNewWorkout from './getNewWorkout'
import { Workout } from '../../types/workouts'

export const getNewWorkoutEvent = ({
  uid,
  eventDateISO,
  gymID,
  trackID,
  index,
  tracking,
}: {
  uid: string
  eventDateISO: string
  gymID: string
  trackID: string
  index: number
  tracking: Workout.Item['tracking']
}) => {
  const newEvent: CalendarEvent.Workout = {
    id: 'ev_' + uuid().substring(0, 8),
    index: index,
    gymID,
    trackID,
    createdBy: uid,
    createdDate: Timestamp.now(),
    updatedBy: uid,
    updatedDate: Timestamp.now(),
    eventDateISO,
    type: 'workout',
    details: getNewWorkout(uid, tracking),
    isDeleted: false,
    pinOptions: null, // workouts cannot be pinned,
    isPublished: false,
  }

  return newEvent
}

/**
 *
 * @deprecated getNewLegacyEvent
 */
