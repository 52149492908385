import React, { useContext, useEffect, useRef, useState } from 'react'
import UserList from '../components/sections/UserList/UserList'
import { Radio, Users as IconUsers, UserX, X as IconClose } from 'react-feather'
import '../App.css'
import toast, { Toaster } from 'react-hot-toast'
import Page from './technical/Page'
import Sidebar from '../components/sections/Sidebar/Sidebar'
import { set } from 'react-hook-form'
import { useWorkouts } from '../hooks/useWorkouts'
import WorkoutCard from '../components/cards/WorkoutCard/WorkoutCard'
import StackGrid from 'react-stack-grid'
import WorkoutBuilder from '../components/forms/WorkoutBuilder/WorkoutBuilder'
import { DateSubheader } from '../components/atoms/DateSubheader/DateSubheader'
import { DateTime } from 'luxon'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import AddWorkoutToCalendar from '../components/modals/AddWorkoutToCalendar'
import { Workout, WorkoutShortcut } from '../types/workouts'
import { useWorkoutShortcuts } from '../hooks/useWorkoutShortcuts'
import Loading from './Loading'
import LoadingSkeleton from '../components/utility/LoadingSceleton/LoadingSkeleton'
import { BasePageState, usePageContext } from '../context/PageContext'

export interface PlaybookPageState extends BasePageState {
  //page: 'playbook'
  activeCategory: string
  action?: string
}

export interface PlaybookActionPayload {
  page: 'playbook'
  setCategory?: string
  action?: 'new-workout'
}

const Playbook = () => {
  const [sidebarActive, setSidebarActive] = useState<boolean>(false)
  const [workoutBuilderActive, setWorkoutBuilderActive] =
    useState<boolean>(false)
  const [editWorkout, setEditWorkout] = useState<Workout.Item | undefined>()

  const { state, setLoading, dispatchAction } = usePageContext()

  useEffect(() => {
    dispatchAction({ page: 'playbook', setCategory: 'girls' })
  }, [])

  const activeCategory =
    state.page === 'playbook'
      ? ((state as PlaybookPageState).activeCategory as
          | Workout.Item['workoutType']
          | 'everything')
      : 'girls'

  const action =
    state.page === 'playbook' ? (state as PlaybookPageState).action : undefined

  // const playbookCategories: (Workout.Item['workoutType'] | 'everything')[] = [
  //   'girls',
  //   'heroes',
  //   'special',
  //   'cf-open',
  //   'gymnastics',
  //   'endurance',
  //   'everything',
  // ]

  const { workouts, workoutsAreLoading, fetchNextPage, showNextPageLoader } =
    useWorkouts({
      activeType: activeCategory,
    })
  const { shortcuts: workoutShortcuts } = useWorkoutShortcuts()
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setLoading(workoutsAreLoading)
  }, [workoutsAreLoading])

  useEffect(() => {
    if (action === 'new-workout') {
      setWorkoutBuilderActive(true)
      setSidebarActive(true)
    }
  }, [action])

  const editWorkoutClick = ({
    workout,
    setItems,
  }: {
    workout: Workout.Item
    setItems: Workout.Item[] | null
  }) => {
    setEditWorkout(workout)
    setWorkoutBuilderActive(true)
    setSidebarActive(true)
  }

  const dismissBuilder = () => {
    setSidebarActive(false)
    setTimeout(() => {
      setEditWorkout(undefined)
      dispatchAction({ page: 'playbook', action: undefined })
      setWorkoutBuilderActive(false)
    }, 200)
  }

  const [addWorkoutToCalendarModalData, setAddWorkoutToCalendarModalData] =
    useState<WorkoutShortcut | null>(null)

  const handleScroll = () => {
    console.log('on scroll')

    if (!contentRef.current) return
    const scrollElement = contentRef.current

    const bottom =
      scrollElement.scrollHeight - scrollElement.scrollTop <=
      scrollElement.clientHeight + 500

    if (bottom) {
      fetchNextPage()
    }
  }

  const showLoadingSkeletons = () => {
    const skeletons = []
    for (let i = 0; i < 10; i++) {
      skeletons.push(
        <LoadingSkeleton type="single card" key={`sceleton-${i}`} />
      )
    }
    return skeletons
  }

  return (
    <>
      <Page
        // onChange={handleNavAction}
        onScroll={handleScroll}
        forwardRef={contentRef}
        isLoading={workoutsAreLoading}
        // data={{ activeCategory }}
      >
        <AddWorkoutToCalendar
          isVisible={!!addWorkoutToCalendarModalData}
          onCancel={() => setAddWorkoutToCalendarModalData(null)}
          onConfirm={() => setAddWorkoutToCalendarModalData(null)}
          workoutShortcut={addWorkoutToCalendarModalData}
        />

        <Toaster
          toastOptions={{
            success: {
              iconTheme: {
                primary: 'var(--accent-green)',
                secondary: 'white',
              },
              style: {
                borderRadius: '8px',
              },
            },
            error: {
              iconTheme: {
                primary: 'var(--accent-red)',
                secondary: 'white',
              },
              style: {
                borderRadius: '8px',
              },
            },
          }}
        />

        <div id="page-content" style={{ paddingTop: '16px' }}>
          {/* <StackGrid columnWidth={'25%'} gutterWidth={8} gutterHeight={8}> */}
          {workouts && (
            <ResponsiveMasonry
              columnsCountBreakPoints={{
                350: 1,
                750: 2,
                900: 3,
                1280: 4,
                1720: 5,
              }}
            >
              <Masonry gutter="8px">
                {workouts.map((workout) => (
                  <WorkoutCard
                    animateOnAppear={true}
                    id={workout.id}
                    key={workout.id}
                    playbookMode={true}
                    workoutData={workout}
                    canSort={false}
                    style={{ marginBottom: 0 }}
                    onAction={(action, actionItems) => {
                      switch (action) {
                        case 'edit':
                          editWorkoutClick({
                            workout,
                            setItems: actionItems?.setItems || null,
                          })
                          break
                        case 'add-to-calendar':
                          setAddWorkoutToCalendarModalData(
                            workoutShortcuts.find(
                              (w) => w.id === workout.benchmarkID
                            ) || null
                          )
                          break
                      }
                    }}
                  />
                ))}
                {showNextPageLoader && showLoadingSkeletons()}
              </Masonry>
            </ResponsiveMasonry>
          )}
          {/* </StackGrid> */}
        </div>
        <Sidebar
          isActive={sidebarActive}
          onDismiss={() => {}} // ignored when template is blank
          blankTemplate={true}
        >
          {workoutBuilderActive && (
            <WorkoutBuilder
              workoutToEdit={editWorkout}
              index={0}
              workoutEventToEdit={null}
              onDismiss={dismissBuilder}
              activeDate={DateTime.now().toFormat('yyyy-MM-dd')}
              isPresetMode={true}
              newWorkoutTracking="reps-weight"
            />
          )}
        </Sidebar>
      </Page>
    </>
  )
}

export default Playbook
