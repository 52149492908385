import React from 'react'

type Props = {
  size?: number
}

const IconTime = ({ size = 32 }: Props) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3431 22.6568C11.2734 23.5871 12.4188 24.2736 13.6777 24.6555C14.9367 25.0374 16.2704 25.1029 17.5607 24.8463C18.851 24.5896 20.0582 24.0187 21.0751 23.1841C22.0921 22.3495 22.8876 21.2769 23.391 20.0615C23.8945 18.846 24.0904 17.5251 23.9615 16.2159C23.8325 14.9066 23.3827 13.6493 22.6518 12.5554C21.9208 11.4615 20.9314 10.5648 19.7712 9.94462C18.6109 9.32445 17.3156 8.99999 16 8.99999L16 17L10.3431 22.6568Z"
        fill="#16DB70"
      />
      <rect x="22" y="16" width="2" height="2" rx="1" fill="black" />
      <rect x="15" y="23" width="2" height="2" rx="1" fill="black" />
      <rect x="8" y="16" width="2" height="2" rx="1" fill="black" />
      <rect x="15" y="9" width="2" height="2" rx="1" fill="black" />
      <circle cx="16" cy="17" r="11" stroke="black" strokeWidth="2" />
      <path
        d="M12.7692 4C12.7692 3.44772 13.2169 3 13.7692 3H18.2308C18.783 3 19.2308 3.44772 19.2308 4V6C19.2308 6.55228 18.783 7 18.2308 7H13.7692C13.2169 7 12.7692 6.55228 12.7692 6V4Z"
        fill="black"
      />
      <path
        d="M22.9079 5.52309C23.2984 5.13256 23.9316 5.13256 24.3221 5.52309L27.4769 8.67787C27.8674 9.06839 27.8674 9.70156 27.4769 10.0921L26.0627 11.5063C25.6721 11.8968 25.039 11.8968 24.6485 11.5063L21.4937 8.35151C21.1031 7.96099 21.1031 7.32782 21.4937 6.9373L22.9079 5.52309Z"
        fill="black"
      />
      <rect
        x="14.9231"
        y="16.0769"
        width="2.15385"
        height="2.15385"
        rx="1.07692"
        fill="black"
      />
      <path
        d="M15.2385 16.3924C15.6591 15.9718 16.3409 15.9718 16.7615 16.3924V16.3924C17.1821 16.8129 17.1821 17.4948 16.7615 17.9154L11.9384 22.7385C11.5178 23.1591 10.8359 23.1591 10.4154 22.7385V22.7385C9.99479 22.3179 9.99479 21.6361 10.4154 21.2155L15.2385 16.3924Z"
        fill="black"
      />
      <circle
        cx="16"
        cy="17"
        r="2"
        fill="#16DB70"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  )
}

export default IconTime
