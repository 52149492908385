import {
  DocumentData,
  DocumentReference,
  getDoc,
  onSnapshot,
} from 'firebase/firestore'
import { useEffect } from 'react'
import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query'
import { auth } from '../../firebase'

interface IProps<TData = DocumentData> {
  queryKey: QueryKey
  docRef: DocumentReference<TData>
  subscribe?: boolean
  queryOptions?: Omit<
    UseQueryOptions<TData | null, Error, TData | null, QueryKey>,
    'queryKey' | 'queryFn'
  >
}

const useCustomFirestoreDocumentData = <TData = DocumentData>({
  queryKey,
  docRef,
  subscribe = false,
  queryOptions,
}: IProps<TData>): UseQueryResult<TData | null, Error> => {
  const isAuthed = auth.currentUser?.uid

  const docQuery = useQuery<TData | null, Error, TData | null, QueryKey>({
    queryKey,
    queryFn: async () => {
      const docSnapshot = await getDoc<TData, DocumentData>(docRef)
      if (!docSnapshot.exists()) return null
      return docSnapshot.data() as TData
    },
    ...queryOptions,
  })

  const enableQuery = queryOptions ? queryOptions.enabled : true

  useEffect(() => {
    if (subscribe && isAuthed && enableQuery) {
      const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            docQuery.refetch()
          }
        },
        (error) => {
          console.error(
            `Error fetching data for path "${docRef['path']}":`,
            error
          )
        }
      )
      return () => unsubscribe()
    }
  }, [subscribe, enableQuery, isAuthed])

  return docQuery
}

export default useCustomFirestoreDocumentData
