import React, { CSSProperties, useEffect, useState } from 'react'
import css from './RoundsInput.module.css'
import { Plus as IconPlus, Minus as IconMinus } from 'react-feather'
import NumberInput from '../NumberInput/NumberInput'
import { ChangeHandler, useForm, useFormContext } from 'react-hook-form'
import TextInput from '../TextInput/TextInput'
import convertSecondsToTimestamp from '../../../utilities/convertSecondsToTimestamp'

type Props = {
  className?: string
  name: string
  label?: string
  isRequired?: boolean
  subText?: string
  hintText?: string
  min?: number
  valuePreviewLabel?: string
  useSeconds?: boolean
}

const TimeCapInput = ({
  name,
  label,
  isRequired,
  subText,
  hintText,
  useSeconds,
}: Props) => {
  const methods = useFormContext()
  const currentValue = methods.watch(name)
  const tracking = methods.watch('tracking')
  const errorText = methods.getFieldState(name)?.error?.message

  const [entryValue, setEntryValue] = useState<string | undefined>(
    currentValue ? convertSecondsToTimestamp(currentValue) : ''
  )
  const [entryValueIsTouched, setEntryValueIsTouched] = useState(false)

  useEffect(() => {
    if (!entryValueIsTouched) {
      setEntryValue(currentValue ? convertSecondsToTimestamp(currentValue) : '')
    }
  }, [currentValue])

  const processTimeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEntryValue = e.target.value
    const newEntryIsValid = testValue(newEntryValue)

    if (!newEntryIsValid) return

    setEntryValueIsTouched(true)

    if (useSeconds) {
      const timeParts = newEntryValue.split(':')
      let minutes = 0
      let seconds = 0

      switch (timeParts.length) {
        case 2: // mm:ss
          minutes = parseInt(timeParts[0]) || 0
          seconds = parseInt(timeParts[1]) || 0
          break
        case 1: // mm: or :ss
          if (newEntryValue.startsWith(':')) {
            seconds = parseInt(timeParts[0]) || 0
          } else {
            minutes = parseInt(timeParts[0]) || 0
          }
          break
      }
      return updateValues({
        newEntryValue,
        newFormValue: minutes * 60 + seconds,
      })
    } else {
      // minutes only
      const num = parseInt(newEntryValue)
      return updateValues({ newEntryValue, newFormValue: num })
    }
  }

  const updateValues = ({
    newEntryValue,
    newFormValue,
  }: {
    newEntryValue: string | undefined
    newFormValue: number
  }) => {
    setEntryValue(newEntryValue)
    methods.setValue(name, newFormValue)
  }

  const testValue = (value: string) => {
    if (isRequired && value === '') {
      return true
    }
    const regex = useSeconds ? /^[0-9]*:?[0-5]?[0-9]?$/ : /^[0-9]*$/
    return regex.test(value)
  }

  const isZeroMinutes = useSeconds ? currentValue < 60 : false

  return (
    <TextInput
      label={label || 'Time Cap'}
      placeholder={useSeconds ? 'mm:ss' : 'minutes'}
      isRequired={isRequired}
      subText={subText}
      hintText={hintText}
      value={entryValue}
      onChange={processTimeValue}
      centered
      errorText={errorText}
      valuePreviewLabel={isZeroMinutes ? 'sec' : 'min'}
    />
  )
}

export default TimeCapInput
