import IconAvgTime from '../../atoms/Icons/IconAvgTime'
import IconNoScore from '../../atoms/Icons/IconNoScore'
import IconReps from '../../atoms/Icons/IconReps'
import IconRepsWeight from '../../atoms/Icons/IconRepsWeight'
import IconRounds from '../../atoms/Icons/IconRounds'
import IconRoundsReps from '../../atoms/Icons/IconRoundsReps'
import IconTextScore from '../../atoms/Icons/IconTextScore'
import IconTime from '../../atoms/Icons/IconTime'
import css from './WorkoutBuilderButtons.module.css'
import classNames from 'classnames/bind'
import IconEMOM from '../../atoms/Icons/IconEMOM'
import IconEMOMAlt from '../../atoms/Icons/IconEMOMAlt'
import IconNote from '../../atoms/Icons/IconNote'
import { Workout } from '../../../types/workouts'
import { Tracking } from '../../../types/types'
import IconStaffNote from '../../atoms/Icons/IconStaffNote'
import IconPinnedNote from '../../atoms/Icons/IconPinnedNote'
import IconComplex from '../../atoms/Icons/IconComplex'
import { Circle } from 'react-feather'
import IconNoTracking from '../../atoms/Icons/IconNoTracking'
import { useContext } from 'react'
import { AppContext } from '../../../context/AppContext'
import { Tooltip } from 'react-tooltip'
import getTrackingTypeDescription from '../../../utilities/workout builder/getTrackingTypeDescription'

const cx = classNames.bind(css)

type Props = {
  onAction: (action: Tracking | 'note' | 'staff-note' | 'pinned-note') => void
}

const WorkoutBuilderButtons = ({ onAction }: Props) => {
  const { currentSpace } = useContext(AppContext)
  const advancedNotesEnabled =
    !!currentSpace?.subscription.enabled_features.advanced_notes

  return (
    <div className={cx('buttons')}>
      <Tooltip
        id="t-tracking-type-button"
        place="bottom"
        delayShow={600}
        className="tooltip"
        noArrow={true}
        offset={4}
      />
      <div className={cx('row')}>
        <div
          className={cx('button')}
          onClick={() => onAction('reps-weight')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content={
            getTrackingTypeDescription('reps-weight')?.description
          }
        >
          <IconRepsWeight style={{ marginLeft: '-2px' }} />
          <span>Single Lift</span>
        </div>
        <div
          className={cx('button')}
          onClick={() => onAction('emom')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content={getTrackingTypeDescription('emom')?.description}
        >
          <IconEMOM />
          <span>EMOM</span>
        </div>
        <div
          className={cx('button')}
          onClick={() => onAction('emom-alt')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content={
            getTrackingTypeDescription('emom-alt')?.description
          }
        >
          <IconEMOMAlt />
          <span>EMOM, Alt.</span>
        </div>
      </div>
      <div className={cx('row')}>
        <div
          className={cx('button')}
          onClick={() => onAction('time')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content={getTrackingTypeDescription('time')?.description}
        >
          <IconTime />
          <span>Total Time</span>
        </div>
        <div
          className={cx('button')}
          onClick={() => onAction('total-reps')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content={
            getTrackingTypeDescription('total-reps')?.description
          }
        >
          <IconReps />
          <span>Total Reps</span>
        </div>
        <div
          className={cx('button')}
          onClick={() => onAction('rounds-reps')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content={
            getTrackingTypeDescription('rounds-reps')?.description
          }
        >
          <IconRoundsReps />
          <span>Rounds+Reps</span>
        </div>
      </div>
      <div className={cx('row')}>
        <div
          className={cx('button')}
          onClick={() => onAction('complex')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content={
            getTrackingTypeDescription('complex')?.description
          }
        >
          <IconComplex />
          <span>Complex</span>
        </div>
        <div
          className={cx('button')}
          onClick={() => onAction('avg-time')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content={
            getTrackingTypeDescription('avg-time')?.description
          }
        >
          <IconAvgTime />
          <span>Avg. Time</span>
        </div>
        <div
          className={cx('button')}
          onClick={() => onAction('text')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content={getTrackingTypeDescription('text')?.description}
        >
          <IconTextScore />
          <span>Text Score</span>
        </div>
      </div>
      <div className={cx('row')}>
        <div
          className={cx('button')}
          onClick={() => onAction('no-score')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content={
            getTrackingTypeDescription('no-score')?.description
          }
        >
          <IconNoScore />
          <span>Simple Workout</span>
        </div>
      </div>
      <div className={cx('divider')} />
      <div className={cx('row')}>
        <div
          className={cx('button')}
          onClick={() => onAction('note')}
          data-tooltip-id="t-tracking-type-button"
          data-tooltip-content="Add a note to provide additional context or instructions"
        >
          <IconNote />
          <span>Note</span>
        </div>
        {advancedNotesEnabled && (
          <>
            <div
              className={cx('button')}
              onClick={() => onAction('staff-note')}
              data-tooltip-id="t-tracking-type-button"
              data-tooltip-content="Add a staff-only note visible to coaches and managers"
            >
              <IconStaffNote />
              <span>Staff Note</span>
            </div>
            <div
              className={cx('button')}
              onClick={() => onAction('pinned-note')}
              data-tooltip-id="t-tracking-type-button"
              data-tooltip-content="Add a pinned note that appears at the top of the each day"
            >
              <IconPinnedNote />
              <span>Pinned Note</span>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default WorkoutBuilderButtons
