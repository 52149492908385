import { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { CalendarEvent } from '../../types/calendarEvents'
import { useState } from 'react'
import { useWorkoutPublisher } from '../useWorkoutPublisher'
import { arrayMove } from '@dnd-kit/sortable'

const useCalendarEventSorter = (currentEvents: CalendarEvent.Item[]) => {
  const [draggedItem, setDraggedItem] = useState<CalendarEvent.Item | null>(
    null
  )
  const handleDragStart = (event: DragStartEvent) => {
    if (currentEvents.length)
      setDraggedItem(
        currentEvents.find((item) => item.id === event.active.id) || null
      )
    else return false
  }

  const { submitCalendarEvents } = useWorkoutPublisher()

  // We use temp events when user finished dragging the element and we need to
  // complete the animation. Otherwise it will be a lag
  // before actual events get optimistically updated
  const [tempSortedEvents, setTempSortedEvents] = useState<
    CalendarEvent.Item[] | null
  >(null)

  const handleDragEnd = (event: DragEndEvent) => {
    if (currentEvents.length) {
      const { active, over } = event
      setDraggedItem(null)
      if (over && active.id !== over.id) {
        const oldIndex = currentEvents.find(
          (item) => item.id === active.id
        )?.index

        const newIndex = currentEvents.find(
          (item) => item.id === over.id
        )?.index

        if (newIndex !== undefined && oldIndex !== undefined) {
          const newEvents: CalendarEvent.Item[] = arrayMove(
            currentEvents,
            oldIndex,
            newIndex
          ).map((item, index) => ({ ...item, index: index }))

          console.log('newEvents', newEvents)

          setTempSortedEvents(newEvents)
          submitCalendarEvents(newEvents)

          // even with optimistic update that happens in submitCalendarEvents
          // there are still delay in sorting. that's why we need to store
          // tempSortedEvents and clear them after a while
          setTimeout(() => {
            setTempSortedEvents(null)
          }, 500)
          //
        }
      }
    } else return false
  }

  return {
    /**
     * tempSortedEvents are used when dnd animation finishes
     */
    tempSortedEvents,
    draggedItem,
    handleDragStart,
    handleDragEnd,
  }
}

export default useCalendarEventSorter
