import { initializeApp } from 'firebase/app'
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updateProfile,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from 'firebase/auth'

import {
  query,
  getDocs,
  collection,
  where,
  addDoc,
  doc,
  setDoc,
  getDoc,
  CACHE_SIZE_UNLIMITED,
  getFirestore,
} from 'firebase/firestore'

import { getAnalytics, logEvent } from 'firebase/analytics'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

const firebase = initializeApp(firebaseConfig)
export const firestore = getFirestore(firebase)
/** Google Analytics */
export const analytics = getAnalytics(firebase)

const auth = getAuth(firebase)
//const db = getFirestore(firebase)

const googleProvider = new GoogleAuthProvider()
const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider)
    const user = res.user
    const q = query(collection(firebase, 'users'), where('uid', '==', user.uid))
    const docs = await getDocs(q)
    if (docs.docs.length === 0) {
      await addDoc(collection(firebase, 'users'), {
        uid: user.uid,
        name: user.displayName,
        authProvider: 'google',
        email: user.email,
      })
    }
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password)
    return 'success'
  } catch (err) {
    //console.error(err)
    //alert(err.message)
    return err
  }
}
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email)
    alert('Password reset link sent!')
  } catch (err) {
    console.error(err)
    alert(err.message)
  }
}

const logout = async () => {
  await signOut(auth)
}

export {
  auth,
  signInWithGoogle,
  logInWithEmailAndPassword,
  sendPasswordReset,
  logout,
}

export const movementsRef = query(collection(firestore, 'movements'))

export const getUserID = () => {
  return auth.currentUser ? auth.currentUser.uid : false
}

// export const updateUserSetting = async (key, value) => {
//   const userQuery = doc(firestore, 'users', getUserID(), 'settings', 'settings')
//   const userSnapshot = await getDoc(userQuery)
//   const data = userSnapshot.data()

//   let newData = data

//   newData[key] = value
//   try {
//     await setDoc(userQuery, newData)
//   } catch (e) {
//     console.error('Error updating user setting: ', e)
//   }
// }

export const getUserData = async (type) => {
  const userQuery = doc(firestore, 'users', getUserID())
  const userSnapshot = await getDoc(userQuery)
  const data = userSnapshot.data()

  return data[type]
}
