import { Edit, EyeOff, Trash2 } from 'react-feather'
import ButtonToolbar from '../../buttons/ButtonToolbar/ButtonToolbar'
import css from './NoteCard.module.css'
import { Cycle } from '../../../types/cycles'
import { Firestore } from '@google-cloud/firestore'
import { firestore } from '@google-cloud/firestore/types/protos/firestore_v1_proto_api'
import { DateTime } from 'luxon'
import { useContext, useState } from 'react'
import { IconSquare } from '../../atoms/Icons/Icons'
import { AppContext } from '../../../context/AppContext'
import { UserContext } from '../../../context/UserContext'
import { CalendarEvent } from '../../../types/calendarEvents'
import classNames from 'classnames/bind'
import { useSortable } from '@dnd-kit/sortable'
import { AlignJustify as IconDrag } from 'react-feather'
import IconPin from '../../atoms/Icons/IconPin'

import { CSS } from '@dnd-kit/utilities'
import Text from '../../sections/Text/Text'

const cx = classNames.bind(css)

type Props = {
  data: CalendarEvent.Note
  onAction: (action: 'edit' | 'delete') => void
  canSort?: boolean
}

const NoteCard = ({ data, canSort, onAction }: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: data?.id ?? 'no-id' })

  const { content, preview, title, category } = data.details
  const { isPinned, pinDates } = data.pinOptions || {
    isPinned: false,
    pinDates: null,
  }

  const { currentSpace } = useContext(AppContext)
  const { userID } = useContext(UserContext)
  const canEdit =
    currentSpace?.permissions.canEditWorkouts ||
    currentSpace?.ownerID === userID

  // In the app we use preview text (optional) OR truncated content text
  // on main screen
  const shortText = preview || content.substring(0, 140)
  const fullText = preview ? preview + '\n\n' + content : content

  const canExpand = fullText.length > shortText.length

  const userIsEditor =
    currentSpace?.permissions.canEditWorkouts ||
    currentSpace?.ownerID === userID

  const [expanded, setExpanded] = useState(!canExpand)

  const pinDatesDateTime = pinDates
    ? {
        startDateISO: DateTime.fromISO(pinDates.startDateISO),
        endDateISO: DateTime.fromISO(pinDates.endDateISO),
      }
    : null

  const fromText = pinDatesDateTime?.startDateISO.toFormat('LLL d') || null
  const toText =
    pinDatesDateTime?.startDateISO.month === pinDatesDateTime?.endDateISO.month
      ? `–${pinDatesDateTime?.endDateISO.toFormat('d')}`
      : ` – ${pinDatesDateTime?.endDateISO.toFormat('LLL d')}`

  const pinText = isPinned // I.e.: Nov 1–2, Nov 30 – Dec 1
    ? pinDatesDateTime
      ? `Pinned, ${fromText}${toText}`
      : 'Pinned'
    : ''

  const styleAddition = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <div
      className={cx('note-card', { hide: isDragging })}
      {...attributes}
      ref={setNodeRef}
      style={{
        ...styleAddition,
      }}
    >
      <div className={cx('header')}>
        <div>
          <h5 className={cx('category')}>{category}</h5>
        </div>
        <div
          style={{
            display: 'flex',
          }}
        >
          {!!pinText && (
            <>
              <IconPin
                size={16}
                color={'var(--icon)'}
                style={{ margin: '-1px 6px 0' }}
              />
              <h5>{pinText}</h5>
            </>
          )}

          {canSort && userIsEditor && (
            <div
              className={cx('drag-icon')}
              data-tooltip-id="t-drag-hint-card"
              data-tooltip-content="Drag to reorder"
              {...listeners}
            >
              <IconDrag size={20} />
            </div>
          )}
        </div>
      </div>
      {title && <h3 className={cx('title')}>{title}</h3>}

      <div className={cx('description', { expanded: expanded })}>
        <Text>{expanded ? fullText : shortText}</Text>
      </div>
      {canExpand && (
        <div
          className={cx('show-more')}
          onClick={() => setExpanded((prev) => !prev)}
        >
          {expanded ? 'Less' : 'More'}
        </div>
      )}
      {canEdit && (
        <div className={cx('buttons')}>
          <div className={cx('section')}>
            <ButtonToolbar
              icon={<Edit size={12} style={{ marginRight: '8px' }} />}
              type="light"
              onClick={() => onAction('edit')}
              style={{
                color: 'var(--accent)',
              }}
            >
              Edit
            </ButtonToolbar>
          </div>
          <div className={cx('section')}>
            <ButtonToolbar
              type="light"
              onClick={() => onAction('delete')}
              className={cx('da-button')}
              icon={<Trash2 size={16} color="var(--accent)" />}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default NoteCard
